import React, { Component } from 'react';
import { connect } from 'react-redux';

// Bootstrap start
import Alert from 'react-bootstrap/Alert'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Row from 'react-bootstrap/Row'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import TabContainer from 'react-bootstrap/TabContainer'
import TabPane from 'react-bootstrap/TabPane'
import TabContent from 'react-bootstrap/TabPane'
import Table from 'react-bootstrap/Table'

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faHome, faSync, faChevronLeft, faUserCircle } from '@fortawesome/free-solid-svg-icons';
// Bootstrap end

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import LoginForm from './LoginForm.js';
import SignupForm from './SignupForm.js';
import VerifySignupCode from './VerifySignupCode.js';
import ApiSettings from './ApiSettings.js';
import EditableTable from './EditableTable.js';
import AddressForm from './AddressForm.js';
import UserInfoForm from './UserInfoForm.js';
import Sidebar from './SideNav.js';
import AddPackagingSelect from './AddPackagingSelect.js';
import { packagingColumns } from './TableColumns.js';

import { loginUser, createUser, updateApiKeys, testApiKeys, verifyCode, continueSignup, setTab, hideAlert } from 'components/authActions.js';

class SignupContainer extends Component{
  constructor() {
    super()

    this.state = {
      message: "",
      showCodeForm: false,
      showAlert: false
    }
  }

  componentDidMount() {
    let token = localStorage.getItem('token')

    if (token != "") {
      this.props.continueSignup();
    }
  }

  LoadingMsg = () => {
    if (this.props.requesting) {
      return "Logging In"
      } else {
        return ""
      }
  }

  VerifyCode = () => {
    if (this.state.showCode) {
      return(
        <div></div>
      )
    }
  }

  OnClickVerify = () => {
    this.setState({showCodeForm: true})
  }

  setDisabled = (e) => {
    if (e.target.eventKey == this.props.signup_tab) {
      return true
    } else {
      return false
    }
  }

  SignupForm = () => {
    if (this.props.user != "" && this.props.email_confirmed == false) {
      return (
        <VerifySignupCode verifyCode={this.props.verifyCode}/>
      )
    } else if (this.props.user != "" && this.props.email_confirmed == true) {
      return (
        this.setState({activeTab: "signup"})
      )
    } else {
      return (
        <SignupForm createUser={this.props.createUser}/>
      )
    }
  }

  Messages = () => {
    if (this.props.showAlert) {
      return(
        <Alert variant="info" className="mt-2 mb-2 text-uppercase" onClose={() => this.props.hideAlert()} dismissible>
          {this.props.messages.error || this.props.messages.success}
        </Alert>
      )
    } else {
      return ""
    }
  }

  // After user sets up integrations
  // 1. Download user carriers etc
  // 2. Download user orders
  // 3. Give user toturial on shipping settings

  render() {
    // if (this.props.loggedIn) {
    //   return <Redirect to="/users/orders" />
    // }
    return(
      <Container className="p-3 bg-light" style={{fontSize: 14}} fluid>
        <Row md={1} className="flex-grow-1">
          <Col md={12} className="pt-3">
            {/* onSelect={(k) => this.setState({activeTab: k})} */}
            <Tab.Container id="left-tabs-example" defaultActiveKey="signup" activeKey={this.props.signup_tab}>
              <Row>
                <Col sm={3} className="p-3 m-2 rounded bg-white text-dark" style={{'minHeight': 'calc(100vh - 50%)', 'maxHeight': 'calc(100vh - 10px)', 'overflowY': 'auto'}}>
                  <Nav variant="pills" className="flex-column">
                    <Card className="mb-3">
                      <Card.Body>
                        <Card.Title>Hello, There!</Card.Title>
                        <Card.Text>
                          We are really excited that your joining us.<br></br>
                          First will need some information before you can start shipping.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Nav.Item>
                      <Nav.Link eventKey="signup" disabled={e => this.setDisabled(e)}>1. Introduce Yourself</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="verify" disabled={e => this.setDisabled(e)}>2. Verify Email </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="setup" disabled={e => this.setDisabled(e)}>3. Setup & Connect</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="plan" disabled={e => this.setDisabled(e)}>4. Choose Plan</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="billing" disabled={e => this.setDisabled(e)}>5. Billing Info</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="intro" disabled={e => this.setDisabled(e)}>Introdution Video</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={8} className="p-3 m-2 rounded bg-white text-dark" style={{'minHeight': 'calc(100vh - 50%)', 'maxHeight': 'calc(100vh - 10px)', 'overflowY': 'auto'}}>
                  <Tab.Content>
                    {this.Messages()}
                    <Tab.Pane eventKey="signup">
                      <SignupForm createUser={this.props.createUser} user={this.props.user} requesting={this.props.requesting}/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="verify">
                      <VerifySignupCode verifyCode={this.props.verifyCode} requesting={this.props.requesting}/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="setup">
                      <Button name="continue" className="p-2 pr-3 pl-3 m-2" onClick={() => this.props.setTab("plan")} disabled={(!this.props.apiKeys)}>Continue To Plans</Button>
                      <ApiSettings
                        requesting={this.props.requesting}
                        keys={this.props.apiKeys ? this.props.apiKeys.keySecrets.shipstation : []}
                        updateApiKeys={this.props.updateApiKeys}
                        testApiKeys={this.props.testApiKeys}
                        keyName={['shipstation', 'Ship Station']}
                        key={'shipstation'}
                      />
                      <ApiSettings
                        requesting={this.props.requesting}
                        keys={this.props.apiKeys ? this.props.apiKeys.keySecrets.shipengine : []}
                        updateApiKeys={this.props.updateApiKeys}
                        testApiKeys={this.props.testApiKeys}
                        keyName={['shipengine', 'Ship Engine']}
                        key={'shipengine'}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="plan">

                    </Tab.Pane>
                    <Tab.Pane eventKey="billing" >
                      <AddressForm address={this.props.address} updateShipFromAddress={this.props.updateShipFromAddress} createToast={this.createToast}/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="intro">

                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.currentUser,
    email_confirmed: state.auth.email_confirmed,
    showAlert: state.auth.showAlert,
    signup_tab: state.auth.signup_tab,
    messages: state.auth,
    requesting: state.auth.requesting,
    loggedIn: state.auth.loggedIn,
    name: state.auth.currentUser.name,
    email: state.auth.currentUser.email,
    address: state.auth.currentUser.user_addresses,
    apiKeys: state.auth.currentUser.api_keys,
    packagingRows: state.user.allowedPackagings,
    allPackagings: [...state.user.availablePackagings, ...state.user.allowedPackagings],
    packagingList: state.user.availablePackagings,
    excludedSkus: state.user.excludedSkus,
    exclusiveSkus: state.user.exclusive_skus,
    testMode: state.auth.testMode
  }
}

const mapDispatch = dispatch => {
  return {
    loginUser: (email, password) => { dispatch(loginUser(email, password)) },
    createUser: (name, email, password) => { dispatch(createUser(name, email, password)) },
    updateApiKeys: (api_keys) => { dispatch(updateApiKeys(api_keys)) },
    testApiKeys: (source) => { dispatch(testApiKeys(source)) },
    continueSignup: () => { dispatch(continueSignup()) },
    setTab: (tab) => { dispatch(setTab(tab)) },
    hideAlert: () => { dispatch(hideAlert()) },
    loggedInUser: () => { dispatch(loggedInUser()) },
    logoutUser: () => { dispatch(logoutUser()) },
    toggleTestMode: () => { dispatch(toggleTestMode()) },
    updateShipFromAddress: (address) => { dispatch(updateShipFromAddress(address)) },
    updateUser: (name, email, password) => { dispatch(updateUser(name, email, password)) },
    verifyCode: (code) => { dispatch(verifyCode(code)) }
  }
}

export default connect(mapStateToProps, mapDispatch)(SignupContainer)
