import React, { PureComponent } from 'react';
import OrderInfoModal from './OrderInfoModal.js';

import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import CardGroup from 'react-bootstrap/CardGroup';
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Modal from 'react-bootstrap/Modal'
import Overlay from 'react-bootstrap/Overlay'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import Table from 'react-bootstrap/Table'
import Tooltip from 'react-bootstrap/Tooltip'
import Row from 'react-bootstrap/Row'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faHome, faSync, faChevronLeft, faShippingFast, faBoxOpen, faTruck } from '@fortawesome/free-solid-svg-icons';

export default class Model extends PureComponent{
  constructor(props) {
    super(props)
    this.myRef = React.createRef();

    this.state = {
      title: "",
      body: "",
      id: "",
      show: props.show,
      showOrderInfo: false,
      orderToShow: null
    }
    // this.props.handleInputChange = this.props.handleInputChange.bind(this);
  }

  onModalShow = () => {
    this.props.getOrdersByNumber(this.props.orderNumbers)
  }

  skipOrderButton(orderId) {
    return(
      <Button
        style={{'fontSize': '14px'}}
        className="p-1 m-0"
        variant="link"
        disabled={this.props.processing && this.props.processingType == 9}
        onClick={() => this.props.removeOrderFromBatch(orderId, this.props.id)}
      >
        {this.props.processing && this.props.processingType == 9 ? "Skipping..." : "Skip Order"}
      </Button>
    )
  }

  processOrderButton(orderId, batchId) {
    return(
      <Button
        style={{'fontSize': '14px'}}
        className="p-1 m-0"
        variant="link"
        disabled={this.props.processing && this.props.processingType == 2}
        onClick={() => this.props.generateLabels(orderId, batchId)}
      >
        {this.props.processing && this.props.processingType == 2 ? "Retrying..." : "Retry Order"}
      </Button>
    )
  }


  handlerOrderInfoModalShow(order_id){
   let order = this.props.failedOrders.find(order => order.id == order_id)

    this.setState(
      {
        showOrderInfo: !this.state.showOrderInfo,
        orderToShow: order.attributes
      }
    )
  }


  orderInfoModel = (order) => {
    return (
      <OrderInfoModal
        key={'infoModalPop-' + order.order_number}
        orderInfo={order}
        messages={() => this.props.messages()}
        visible={this.state.showOrderInfo}
        hideOrderInfoModal={this.hideOrderInfoModal}
        getSingleOrder={this.props.getSingleOrder}
        processing={this.props.processing}
        processingType={this.props.processingType}
        carrierIcon={this.carrierIcon}
        updateShipstationOrder={this.props.updateShipstationOrder}
        popover={this.popover}
      />
    )
  }

  hideOrderInfoModal = () => {
    this.setState(
      {
        showOrderInfo: false,
        orderToShow: null
      }
      )
  }

  carrierIcon = (carrier) => {
    let shippingIcons = this.props.shippingIcons;
    return (
      <FontAwesomeIcon icon={shippingIcons[carrier][0] === undefined  ? faTruck : shippingIcons[carrier][0] } style={{'fontSize': '30px', 'color': shippingIcons[carrier][1] ===  undefined ? 'lightgray' : shippingIcons[carrier][1] }}/>
    )
  }


  render() {
    // const orders = this.props.failedOrders;
    return (
      <Modal
        style={{'fontSize': '14px'}}
        key={this.props.id}
        onHide={() => this.props.toggleModal()}
        onShow={() => this.onModalShow()}
        show={this.props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Review Batch <strong>#{this.props.title}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={12}>
                <div className="ml-2">
                  {/* Add loader */}
                  <table>
                    <thead>
                      <tr>
                        <th>Order Number</th>
                        <th>Status</th>
                        <th>Error</th>
                        <th colSpan="2">Order Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.orderToShow && (
                      this.orderInfoModel(this.state.orderToShow)
                    )}
                      {this.props.failedOrders.map(o => {
                        return(
                          <tr>
                            <td>
                              {o.attributes.order_number}
                              <Button style={{'fontSize': '13px'}} variant="link" id={o.attributes.id} onClick={() => this.handlerOrderInfoModalShow(o.attributes.id)}>View</Button>
                            </td>
                            <td>{o.attributes.status}</td>
                            <td>{o.attributes.label_errors}</td>
                            <td>
                              { this.skipOrderButton(o.attributes.order_id) }
                            </td>
                            <td>
                              { this.processOrderButton([o.attributes.order_id], this.props.id) }
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col md={12}>

              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => this.props.toggleModal()}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
