import React, { PureComponent, useState } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';

// Bootstrap start
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Jumbotron from 'react-bootstrap/Jumbotron'
// Bootstrap end

class ProductPricing extends PureComponent{
  constructor(props) {
    super(props)

  }

  AddButton = (props) => {
    const onClick = () => {

    }

    return (
      <Button
        name="add-pricing"
        onClick={() => onClick()}
        className="m-2 p-2"
      >
        Add Pricing Rule
      </Button>
    )
  }

  RemoveButton = () => {
    const onClick = () => {

    }

    return (
      <Button
        name="remove-pricing"
        onClick={() => onClick()}
      >Remove Pricing Rule</Button>
    )
  }

  render() {
    return (
      <Jumbotron className="p-3">
        <h1>Item Pricing Setup</h1>

        <p>
          View And Manage Item Pricing.
          This tool allows you to set discounted pricing for bulk customer purchasing.
        </p>

        <div>
          <Form>
            <Form.Group >

              
              {this.AddButton(this.props)}
            </Form.Group>
          </Form>
        </div>
      </Jumbotron>
    )
  }
}

export default ProductPricing;
