import React, { Component } from 'react';

import CreatableSelect from 'react-select/creatable';
import { countries } from './Data';

class MultiSelect extends Component {

  handleChange = (newValue, actionMeta) => {
    let valueNames = newValue.map(v => v.value)
    this.props.setValues(valueNames);
  };

  getValueNames = (values) => {
    let names = []
    values.map(v => {
      names.push(
        {
          value: v,
          label: v
        }
      )
    })

    return names;
  };

  render() {
    return (
      <CreatableSelect
        className="basic-single p-1 m-1"
        placeholder="Add Or Remove Packagings..."
        isMulti
        onChange={this.handleChange}
        options={this.props.options}
        value={this.getValueNames(this.props.values)}
        isDisabled={this.props.activeInput}
      />
    );
  }
}

export default MultiSelect;
