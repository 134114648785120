import store from '../reducers/index.js';

export function getRates(order_numbers) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/shipping/get_rates`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ orders: { order_numbers: order_numbers } })
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'PROCESSING_REQUEST', jobType: 1 });
      })
  }
}

export function updatedSelectedRate(order_number, rate_id) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/shipping/update_selected_rate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ order_number: order_number, rate_id: rate_id })
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'PROCESSING_REQUEST', jobType: 5 });
      })
  }
}

export function generateLabels(order_numbers, batch_id = null) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/shipping/generate_labels`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ orders: { order_numbers: order_numbers, batch_id: batch_id } })
    })
      .then(resp => resp.json())
      .then(data => {
        if (batch_id != null) {
          dispatch({ type: 'SET_FAILED_ORDERS', orders: data.orders.data, print_batches: data.batches.data });
        }
        dispatch({ type: 'PROCESSING_REQUEST', jobType: 2 });
      })
  }
}

export function voidLabel(orderNumber) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });
    dispatch({ type: 'PROCESSING_REQUEST', jobType: 4 });

    return fetch(`/api/shipping/void_order_label`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ order_number: orderNumber })
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'END_REQUEST' });
      })
      .catch((error) => {
        console.log(error)
        dispatch({ type: 'END_REQUEST' });
      });
  }
}

export function bulkVoidLabel(batchId) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch('/api/shipping/bulk_void_labels', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ batch_id: batchId })
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'END_REQUEST' });
      })
  }
}


export function printPackingSlip(order_number) {
  const token = localStorage.getItem("token");
  // dispatch({ type: 'START_REQUEST' });

  return (dispatch) => {
    if (token) {
      return fetch(`/api/shipping/packing_slip/${order_number}`, {
        headers: {
          Accept: 'application/pdf',
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          if (response.ok) {
            return response.blob();
          }
        })
        .then(blob => {
          window.URL = window.URL || window.webkitURL;
          const data = window.URL.createObjectURL(blob);
          window.open(data, '_blank');
          // dispatch({ type: 'SET_PACKING_SLIP', orderNumber: order_number, packing_slip: data });
        })
    } else {
      dispatch({ type: 'LOGIN_FAILED', error: "You need to log in to continue." })
    }
  }
}

export function printOrderLabel(order_number) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    if (token) {
      return fetch(`/api/shipping/label/${order_number}`, {
        headers: {
          Accept: 'application/pdf',
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          if (response.ok) {
            return response.blob();
          }
        })
        .then(blob => {
          window.URL = window.URL || window.webkitURL;
          const data = window.URL.createObjectURL(blob);
          window.open(data, '_blank');
          // dispatch({ type: 'SET_LABEL', orderNumber: order_number, label: data });
        })
    } else {
      dispatch({ type: 'LOGIN_FAILED', error: "You need to log in to continue." })
    }
  }
}

export function exportCsv(order_ids, batch_csv = null) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });
    dispatch({ type: 'PROCESSING_REQUEST', jobType: 16 })
    if (token) {
      return fetch(`/api/shipping/export_csv?order_ids=${order_ids}&batch_csv=${batch_csv}`, {
        headers: {
          Accept: 'text/csv',
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          if (response.ok) {
            return response.blob();
          }
        })
        .then(blob => {
          window.URL = window.URL || window.webkitURL;
          const data = window.URL.createObjectURL(blob);
          let link = document.createElement("a");
          let currentDate = new Date()
          let day = currentDate.getDate()
          let month = currentDate.getMonth() + 1
          let year = currentDate.getFullYear()
          let fileName = month + "-" + day + "-" + year + " order export.csv"

          link.href = data
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          dispatch({ type: 'END_REQUEST' });
        })
    } else {
      dispatch({ type: 'LOGIN_FAILED', error: "You need to log in to continue." })
    }
  }
}

export function assignToBatch(batchId, orderNumbers) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });
    dispatch({ type: 'PROCESSING_REQUEST', jobType: 6 });

    return fetch(`/api/shipping/assign_to_batch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ batch_id: batchId, order_numbers: orderNumbers })
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'END_REQUEST' });
      })
  }
}

export function deleteBatch(batchId) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/print-batch/${batchId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'END_REQUEST' });
      })
  }
}

export function getNewOrders() {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    return fetch(`/api/shipping/download_orders`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch(
          { type: 'PROCESSING_REQUEST', jobType: 3 });
      })
  }
}

export function deleteRecords(type) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });
    dispatch({ type: 'PROCESSING_REQUEST', jobType: 8 });

    return fetch('/api/shipping/delete_records', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ type: type })
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'END_REQUEST' });
        dispatch({ type: 'SHOW_ALERT', success: data.success || "", failure: data.error || "" })
      })
  }
}

export function getOrdersByNumber(orderNumbers) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });
    dispatch({ type: 'PROCESSING_REQUEST', jobType: 9 });

    return fetch(`/api/shipping/get_orders_by_number?order_numbers=${orderNumbers}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'END_REQUEST' });
        dispatch({ type: 'SET_FAILED_ORDERS', orders: data.orders.data, print_batches: data.batches.data });
      })
  }
}

export function removeOrderFromBatch(orderNumber, batchId) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });
    dispatch({ type: 'PROCESSING_REQUEST', jobType: 9 });

    return fetch(`/api/shipping/remove_order_from_batch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ order_number: orderNumber, batch_id: batchId })
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'END_REQUEST' });
        dispatch({ type: 'SET_FAILED_ORDERS', orders: data.orders.data, print_batches: data.batches.data });
      })
  }
}

export function updateShipstationOrder(addressObject) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });
    dispatch({ type: 'PROCESSING_REQUEST', jobType: 10 });

    return fetch(`/api/shipping/update_shipstation_order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ order: addressObject })
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'END_REQUEST' });
        dispatch({ type: 'UPDATE_SINGLE_ORDER', orders: data.order.data });
        dispatch({ type: 'SHOW_ALERT', success: data.success || "", error: data.error || "" })
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ALERT', success: "", error: error || "" })
      });
  }
}

export function getSingleOrder(orderId) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });
    dispatch({ type: 'PROCESSING_REQUEST', jobType: 10 });

    return fetch(`/api/shipping/get_single_order/${orderId}`, {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({ type: 'END_REQUEST' });
        dispatch({ type: 'UPDATE_SINGLE_ORDER', orders: data.order.data });
        dispatch({ type: 'SHOW_ALERT', success: data.success || "", error: data.error || "" })
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ALERT', success: "", error: error || "" })
      });
  }
}
