import store from '../reducers/index.js';
import { getShippingRules } from './authActions.js'

export function filterCarrierTableRows(carrier_id) {
  return (dispatch) => {
    dispatch({ type: 'FILTER_SHIPPING_RULES', carrier_id: carrier_id });
  }
}

export function getPackagings() {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/users/packagings`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch(
          {
            type: 'GET_PACKAGINGS',
            allowedPackagings: data.packagings,
            availablePackagings: data.available_packagings,
            success: data.success,
            failure: data.error
          }
        )
      })
  }
}

export function getCustomPackagingsInitialOptionsData() {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/users/initial_custom_packagings`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch(
          {
            type: 'GET_INITIAL_CUSTOM_PACKAGINGS',
            initialCustomPackagings: data
          }
        )
      })
  }
}

export function createCustomPackaging(packageData) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/users/create_custom_packaging`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ packageData })
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch(
          {
            type: 'CREATE_CUSTOM_PACKAGING',
            message: data.message,
          }
        )
        dispatch(
          {
            type: 'GET_PACKAGINGS',
            allowedPackagings: data.packagings,
            availablePackagings: data.available_packagings
          }
        )
        dispatch({ type: 'END_REQUEST' })
      })
  }
}


export function findCustomUserPackaging(term) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/users/filter_custom_packagings?code_query=${term}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch(
          {
            type: 'FIND_CUSTOM_USER_PACKAGING',
            data: data,
          }
        )
        dispatch({ type: 'END_REQUEST' })
      })
  }
}

export function setPackagings(packageData) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/users/packagings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ packageData })
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch(
          {
            type: 'GET_PACKAGINGS',
            allowedPackagings: data.packagings,
            availablePackagings: data.available_packagings,
            success: data.success,
            failure: data.error
          }
        )
        dispatch({ type: 'END_REQUEST' })
      })
  }
}

export function refreshPackagings() {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/users/refresh_packagings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch(
          {
            type: 'GET_PACKAGINGS',
            allowedPackagings: data.packagings,
            availablePackagings: data.available_packagings,
            success: data.success,
            failure: data.error
          }
        )
        dispatch({ type: 'UPDATE_SHIPPING_RULES', carrierServices: data.carrierServices, success: data.success })
        dispatch({ type: 'SHOW_ALERT', success: data.success || "", failure: data.error || "" })
      })
  }
}

export function switchElectron() {
  return (dispatch) => {
    dispatch({ type: 'SWITCH_ELECTRON' });
  }
}

export function getBulkDiscountPricing() {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/users/bulk-pricing`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch(
          {
            type: 'GET_BULK_PRICING',
            bulkPricings: data.bulk_pricings,
            success: data.success,
            failure: data.error
          }
        )
      })
  }
}

export function setBulkDiscountPricing(pricingData) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/users/bulk-pricing/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ user_bulk_pricing: pricingData })
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch(
          {
            type: 'GET_BULK_PRICING',
            bulkPricings: data.bulk_pricings,
            success: data.success,
            failure: data.error
          }
        )
        dispatch({ type: 'END_REQUEST' })
      })
  }
}

export function destroyBulkDiscountPricing(id) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/users/bulk-pricing/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch(
          {
            type: 'GET_BULK_PRICING',
            bulkPricings: data.bulk_pricings,
            success: data.success,
            failure: data.error
          }
        )
        dispatch({ type: 'END_REQUEST' })
      })
  }
}

// ******************************* Packaging limits ********************************************

export function getSkuPackagingLimits(page) {
  const token = localStorage.getItem("token");
  let pageNumber = page || 1;

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/users/sku-packaging-limits?page=${pageNumber}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch(
          {
            type: 'GET_SKU_PACKAGING_LIMITS',
            skuPackagingLimits: data.sku_packaging_limits,
            success: data.success,
            failure: data.error,
            pagy: data.pagy
          }
        )
      })
  }
}

export function setSkuPackagingLimits(data) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/users/sku-packaging-limits/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ sku_packaging_limits: data })
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch(
          {
            type: 'GET_SKU_PACKAGING_LIMITS',
            skuPackagingLimits: data.sku_packaging_limits,
            success: data.success,
            failure: data.error,
            pagy: data.pagy
          }
        )
        dispatch({ type: 'END_REQUEST' })
      })
  }
}

export function importSkuPackagingLimitsCSV(uploaded_file) {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append(
    "csv_file",
    uploaded_file,
    uploaded_file.name
  );
  return (dispatch) => {
    dispatch({ type: 'START_CSV_IMPORT_REQUEST' });

    return fetch(`/api/users/sku-packaging-limits-csv`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: formData
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch(
          {
            type: 'IMPORT_SKU_PACKAGING_LIMITS_CSV',
            message: data.message,
            successfully_imported_records_count: data.sucessful_records_count,
            csv_import_errors: data.error
          }
        )
        dispatch({ type: 'FINISHED_CSV_IMPORT_REQUEST' })
      })
  }
}

export function exportSkuPackagingLimitsCSV() {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });
    if (token) {
      return fetch(`/api/users/sku-packaging-limits-csv`, {
        headers: {
          Accept: 'text/csv',
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          if (response.ok) {
            return response.blob();
          }
        })
        .then(blob => {
          window.URL = window.URL || window.webkitURL;
          const data = window.URL.createObjectURL(blob);
          let link = document.createElement("a");
          let currentDate = new Date()
          let day = currentDate.getDate()
          let month = currentDate.getMonth() + 1
          let year = currentDate.getFullYear()
          let fileName = month + "-" + day + "-" + year + " sku_packaging_limits.csv"

          link.href = data
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          dispatch({ type: 'END_REQUEST' });
        })
    } else {
      dispatch({ type: 'LOGIN_FAILED', error: "You need to log in to continue." })
    }
  }
}

export function destroySkuPackagingLimits(id) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/users/sku-packaging-limits/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch(
          {
            type: 'DELETE_SKU_PACKAGING_LIMIT',
            success: data.success,
            failure: data.error
          }
        )
        dispatch({ type: 'END_REQUEST' })
      })
  }
}

export function getSkuPackagingLimitsInitialOptionsData() {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/users/sku_packaging_select_initial_options`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch(
          {
            type: 'GET_SKU_PACKAGING_LIMIT_INITIAL_OPTIONS_DATA',
            data: data,
          }
        )
        dispatch({ type: 'END_REQUEST' })
      })
  }
}

export function findSkuPackagingLimitsOptionsData(term) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/users/sku_packaging_find_options_data?sku_query=${term}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch(
          {
            type: 'FIND_SKU_PACKAGING_LIMIT_OPTIONS_DATA',
            data: data,
          }
        )
        dispatch({ type: 'END_REQUEST' })
      })
  }
}
