import React, { PureComponent } from 'react';
import Toast from 'react-bootstrap/Toast'

export default class ToastAlert extends PureComponent{
  constructor(props) {
    super(props)

    this.state = {
      show: true,
      timeCreated: ""
    }
  }

  componentDidMount() {
    var d = new Date();
    var t = d.toLocaleTimeString();

    this.setState({
      timeCreated: t
    })

    this.myTimer()
  }

  myTimer = function() {
    //starts the interval function
    var self = this;
    this.timer = setInterval(function() {
          self.setShow();
    }, 5000)
  }

  setShow() {
    this.setState({
      show: false
    })
    clearInterval(this.timer);
  }

  render() {
    return(
      <Toast style={{'minWidth': '275px'}} animation={true} show={this.state.show} onClose={() => this.setShow()}>
        <Toast.Header>
          <img src={this.props.img} className="rounded mr-2" alt="" />
          <strong className="mr-auto">{this.props.title}</strong>
          <small>{this.state.timeCreated}</small>
        </Toast.Header>
        <Toast.Body>{this.props.body}</Toast.Body>
      </Toast>
    )
  }
}
