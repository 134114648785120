import React, {PureComponent} from 'react';
import UseAnimations from 'react-useanimations';

class Loader extends PureComponent {
    render() {
        return (
        <div width={'40%'} height={'100%'}>
          <UseAnimations animationKey="loading" size={28} padding={"200px"} margin={"20px"}/>
        </div>
        )
    }
}

export default Loader;
