import React, { PureComponent } from 'react';
import OrderInfoModal from './OrderInfoModal.js';

import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import CardGroup from 'react-bootstrap/CardGroup';
import Col from 'react-bootstrap/Col'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Modal from 'react-bootstrap/Modal'
import Overlay from 'react-bootstrap/Overlay'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import Table from 'react-bootstrap/Table'
import Tooltip from 'react-bootstrap/Tooltip'
import Row from 'react-bootstrap/Row'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAmazon, faEbay, faUsps, faUps, faFedex, faDhl } from '@fortawesome/free-brands-svg-icons';
import { faCog, faHome, faSync, faChevronLeft, faShippingFast, faBoxOpen, faTruck, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export default class Order extends PureComponent {
  constructor(props) {
    super(props)
    this.myRef = React.createRef();
    this.skuRef = React.createRef();
    this.errorRef = React.createRef();

    this.state = {
      orderInfo: this.props.orderInfo,
      checked: this.props.checked,
      inProcess: this.props.inProcess,
      label: this.props.label,
      show: false,
      showOrderInfo: false,
      showOrderInfoTooltip: false,
      showSkuInfo: false,
      showRateErrors: false,
      cheapestRate: this.props.orderInfo.cheapest_rate,
      orderRates: this.props.orderInfo.rates || [],
      showLabelPopover: false
    }
    // this.props.handleInputChange = this.props.handleInputChange.bind(this);
  }

  setChecked(state, event) {
    this.setState({
      checked: state
    })
    console.log("checked: " + state)
    console.log("event: " + event.target.id)
    console.log("event: " + event.shiftKey)
    this.props.handleInputChange(event)
  }

  closeAllPops = () => {

  }

  popover = (props) => {
    const cheapestRate = this.props.orderInfo.cheapestRate;
    const rates = this.props.orderInfo.rates || [];
    const order_number = this.props.orderInfo.order_number
    const order_id = this.props.orderInfo.order_id
    const refreshOrders = (event) => {
      this.props.updatedSelectedRate(event.target.attributes.orderid.value, event.target.id)
      this.setState({ show: !this.state.show })
      this.props.refreshJustOrders()
    }

    return (
      <Popover style={{ 'fontSize': '13px' }} id="popover-basic" {...props} onMouseLeave={() => this.setState({ show: false })}>
        <Popover.Title>
          <Row>
            <span className="p-2 m-1">Quoted Rates</span>
            <Button style={{ 'fontSize': '12px' }} variant="link" className="ml-auto" onClick={() => this.setState({ show: !this.state.show })}>close</Button>
          </Row>
        </Popover.Title>
        <Popover.Content style={{ 'fontSize': '14px' }}>
          {cheapestRate == "0.0" ? <p>Select this order and click 'Get Rates' to calculate rates for this order.</p> : ""}
          <Form onChange={(event) => refreshOrders(event)}>

            {rates.map((r, i) => {
              return (
                <div key={`key-${r[0]}`}>
                  <Form.Check
                    checked={r[8] == 1 ? true : false}
                    type={'radio'}
                    label={`${r[2]} - ${parseFloat(r[4]).toFixed(2)}`}
                    id={`${r[0]}`}
                    orderid={order_id}
                  />
                  <span className="ml-4 mb-1"><sup><strong>Packaging: </strong>{r[3]}</sup></span><br></br>
                  <span className="ml-4 mb-1"><sup><strong>Account: </strong>{r[1]}</sup></span>
                </div>
              )
            })}
          </Form>
        </Popover.Content>
      </Popover>
    )
  }

  skuList = (skus) => {
    let skuList = {}
    skus.map(s => {
      let count = skuList[s] || 0
      skuList[s] = count + 1
    })
  }

  skuPopover = (props) => {
    const skus = this.props.orderInfo.order_skus;
    this.skuList(skus)

    return (
      <Popover style={{ 'fontSize': '12px' }} className="m-3" id="popover-basic" {...props}>
        <Popover.Title>
          <Row>
            <span className="p-1 pl-3 m-0">Order Skus</span>
            {/* <Button style={{'fontSize': '12px'}} variant="link" className="ml-auto" onMouseOver={() => this.setState({showSkuInfo: !this.state.showSkuInfo})}>close</Button> */}
          </Row>
        </Popover.Title>
        <Popover.Content style={{ 'fontSize': '11px' }}>
          {skus.length == 0 ? <p>No Skus Found</p> : ""}
          <ul>
            {skus.map((r, i) => {
              return (
                <li key={`key-${r.item}`} style={{ 'fontSize': '12px' }} className="mb-0">
                  <strong>SKU: </strong>{r.item.length == 0 ? "Not Found" : r.item}  <strong>QTY: </strong>{r.qty}
                </li>
              )
            })}
          </ul>
        </Popover.Content>
      </Popover>
    )
  }

  rateErrorPopover = (props) => {
    return (
      <Popover style={{ 'fontSize': '12px' }} className="m-3" id="popover-basic" {...props}>
        <Popover.Title>
          <Row>
            <span className="p-1 pl-3 m-0">Rate Error</span>
            {/* <Button style={{'fontSize': '12px'}} variant="link" className="ml-auto" onMouseOver={() => this.setState({showSkuInfo: !this.state.showSkuInfo})}>close</Button> */}
          </Row>
        </Popover.Title>
        <Popover.Content style={{ 'fontSize': '11px' }}>
          {this.props.orderInfo.rate_errors == "" ? <p>No Errors Found</p> : <p>{this.props.orderInfo.rate_errors}</p>}
        </Popover.Content>
      </Popover>
    )
  }

  orderStatusPopover = (label_errors, status_message) => {
    if (status_message.length) return this.statusMessagePopover(status_message)
    if (label_errors != null) return this.labelErrorPopover(label_errors)
  }

  statusMessagePopover(messages) {
    return (
      <Popover style={{ 'fontSize': '12px' }} className="m-3" id="popover-basic">
        <Popover.Title>
          <Row>
            <span className="p-1 pl-3 m-0 font-weight-bold">Information</span>
          </Row>
        </Popover.Title>
        <Popover.Content style={{ 'fontSize': '11px' }}>
          <ul>
            {messages.map((message, index) => {
              return (
                <li className='font-weight-normal' key={"li-" + index}>{message}</li>
              )
            })}
          </ul>
        </Popover.Content>
      </Popover>
    )
  }

  labelErrorPopover(label_errors) {
    return (
      <Popover style={{ 'fontSize': '12px' }} className="m-3" id="popover-basic">
        <Popover.Title>
          <Row>
            <span className="p-1 pl-3 m-0 font-weight-bold">Error Detail</span>
          </Row>
        </Popover.Title>
        <Popover.Content style={{ 'fontSize': '11px' }}>
          <p className='font-weight-normal'>{label_errors}</p>
        </Popover.Content>
      </Popover>
    )
  }



  togglePopover(order, label_errors) {
    if (order.order_status === "5" && label_errors != null) {
      return true
    } else if (order.order_status === "1" && order.status_message.length) {
      return true
    } else {
      return false
    }
  }

  toggleDisplay(status_message) {
    if (!status_message.length) return 'none'
  }

  batchIdButton = () => {
    let batchId = this.props.orderInfo.print_batch_id;
    let orderId = this.props.orderInfo.order_id;

    if (batchId == null) {
      return null;
    } else if (["2", "3", "6"].includes(this.props.orderInfo.order_status)) {
      return (
        <Button
          style={{ 'fontSize': '13px' }}
          key={orderId + "_order_batch_button"}
          variant="link"
        >
          {batchId}
        </Button>
      )
    } else {
      return (
        <Dropdown>
          <Dropdown.Toggle
            variant="link"
            size="sm"
            id={batchId + "remove-btn"}
          >
            {batchId}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              size="sm"
              style={{ 'fontSize': '13px' }}
              disabled={this.props.processing && this.props.processingType == 9}
              onClick={() => this.props.removeOrderFromBatch(orderId, batchId)}
            >
              Remove From Batch
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )
    }
  }

  voidLabelButton = (orderId) => {
    return (
      <Dropdown.Item style={{ 'fontSize': '13px' }} onClick={() => this.props.voidLabel(orderId)} disabled={this.props.orderInfo.shipment_id == "" || this.props.processing && this.props.processingType == 4}>
        {this.props.requesting ? 'Voiding Label…' : 'Void Label'}
      </Dropdown.Item>
    )
  }

  printPackingSlip = (orderId) => {
    return (
      <Dropdown.Item style={{ 'fontSize': '13px' }} onClick={() => this.props.printPackingSlip(orderId)} disabled={this.props.orderInfo.shipment_id == "" || this.props.processing && this.props.processingType == 4}>
        {this.props.requesting ? 'Downloading…' : 'Print Packing Slip'}
      </Dropdown.Item>

    )
  }

  printOrderLabel = (orderId) => {
    return (
      <Dropdown.Item style={{ 'fontSize': '13px' }} onClick={() => this.props.printOrderLabel(orderId)} disabled={this.props.orderInfo.shipment_id == "" || this.props.processing && this.props.processingType == 4}>
        {this.props.requesting ? 'Downloading…' : 'Print Label'}
      </Dropdown.Item>
    )
  }

  carrierIcon = (carrier) => {
    let shippingIcons = this.props.shippingIcons;
    return (
      <FontAwesomeIcon icon={shippingIcons[carrier][0] === undefined ? faTruck : shippingIcons[carrier][0]} style={{ 'fontSize': '30px', 'color': shippingIcons[carrier][1] === undefined ? 'lightgray' : shippingIcons[carrier][1] }} />
    )
  }

  renderTooltip = (text) => (
    <Tooltip
      id="button-tooltip"
      style={{
        'fontSize': '11px'
      }}
    >
      {text}
    </Tooltip>
  )



  orderTag = (tagId) => {
    let orderTags = this.props.orderTagsComplete;
    let orderTag = orderTags.find(ot => String(ot.value) == tagId)

    if (orderTag != null) {
      return (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={this.renderTooltip(orderTag.label)}
        >
          <div
            style={{
              'fontSize': '10px',
              'backgroundColor': orderTag.color,
              'width': '10px',
              'height': '20px',
              'margin': '1.5px',
              'borderRadius': '10% / 50%'
            }}>
          </div>
        </OverlayTrigger>
      )
    }
  }

  trackingNumber = (trackingNumber, order) => {
    let trackingUrl = ""

    switch (order.ship_with_carrier) {
      case "ups":
        trackingUrl = "https://www.ups.com/track?loc=null&tracknum=" + trackingNumber + "&requester=ST/trackdetails"
        break;
      case "usps":
      case "endicia":
      case "stamps_com":
        trackingUrl = "https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=" + trackingNumber
        break;
      case "fedex":
        trackingUrl = "https://www.fedex.com/apps/fedextrack/?tracknumbers=" + trackingNumber
        break;
      case "dhl_global_mail":
        trackingUrl = "" + trackingNumber
        break;
      case "rr_donnelley":
        trackingUrl = "" + trackingNumber
        break;
      case "globegistics":
      default:
        trackingUrl = "https://www.google.com/search?q=" + trackingNumber + "+" + "track"
    }

    return (
      <Button variant="link" style={{ 'fontSize': '13px' }} download={order.id} href={trackingUrl} target="_blank" disabled={trackingNumber == null}>
        {trackingNumber}
      </Button>
    )
  }

  orderInfoModel = () => {
    return (
      <OrderInfoModal
        key={'infoModalPop-' + this.props.orderInfo.order_number}
        orderInfo={this.props.orderInfo}
        messages={() => this.props.messages()}
        visible={this.state.showOrderInfo}
        hideOrderInfoModal={this.hideOrderInfoModal}
        getSingleOrder={this.props.getSingleOrder}
        processing={this.props.processing}
        processingType={this.props.processingType}
        carrierIcon={this.carrierIcon}
        updateShipstationOrder={this.props.updateShipstationOrder}
        popover={this.popover}
      />
    )
  }

  hideOrderInfoModal = () => {
    this.setState({ showOrderInfo: false })
  }


  render() {
    if (typeof this.props.orderInfo === "undefined") {
      return null;
    } else {
      const order = this.props.orderInfo;
      const label_errors = this.props.orderInfo.label_errors
      const status_message = this.props.orderInfo.status_message
      const checked = this.props.selectedOrderNumbers.find(o => o == order.order_id)
      const inProcess = this.props.ordersInProcess.find(o => o == order.order_id)
      const label = this.state.label;
      const labelData = (order.shipping_label != null ? "data:application/pdf;base64," + order.shipping_label : "");
      const status = { 0: "Unprocessed", 1: "Processed Rate", 2: "Generated Label", 3: "Printed", 4: "Rate Error", 5: "Label Error", 6: "Print Error", 7: "Other", 8: "Unknown" }
      const varientClasses = { 0: "warning", 1: "primary", 2: "success", 3: "info", 4: "danger", 5: "danger", 6: "danger", 7: "light", 8: "light" }
      const shippingIcons = this.props.shippingIcons;
      const stores = this.props.stores
      const cheapestRate = this.props.orderInfo.cheapest_rate;
      const orderAddressStatus = { "Address validated successfully": "🟩", "Address validation warning": "🟥", "Address not yet validated": "🕑" }

      return (
        <tr
          key={order.order_number + "_order_row"}
          style={inProcess == true ? {
            'color': 'lightGrey',
            'fontSize': '13px',
            'height': '50px',
            'maxHeight': '50px'
          } : {
            'fontSize': '13px',
            'lineHeight': '14px',
            'height': '50px',
            'maxHeight': '50px'
          }}
        >
          <td>
            <Form.Check
              label={order.order_number}
              disabled={inProcess}
              type="checkbox"
              checked={checked || false}
              id={order.order_id}
              onClick={(event) => this.setChecked(event.target.checked, event)}
            />
            {orderAddressStatus[order.shipping_address.addressVerified]}
            <Button disabled={inProcess} style={{ 'fontSize': '13px' }} variant="link" onClick={() => this.setState({ showOrderInfo: !this.state.showOrderInfo })}>View</Button>
            {this.orderInfoModel()}
          </td>
          <td>
            {this.batchIdButton()}

          </td>
          <td onMouseLeave={() => this.setState({ showSkuInfo: false })}>
            <Button
              disabled={inProcess}
              style={{ 'fontSize': '13px' }}
              key={order.order_number + "_order_sku_button"}
              variant="link" ref={this.skuRef}
              onMouseLeave={() => this.setState({ showSkuInfo: !this.state.showSkuInfo })}
              onMouseEnter={() => this.setState({ showSkuInfo: !this.state.showSkuInfo })}
              onClick={() => this.props.copyToClipboard(order.order_skus.map(i => i.item))}
            >
              {order.order_skus.length > 0 ? `${order.order_skus[0].item}...` : "No Skus"}
            </Button>
            <Overlay key={"overlay-" + order.id} target={this.skuRef.current} show={this.state.showSkuInfo} placement="right">
              {(props) => (
                this.skuPopover(props)
              )}
            </Overlay>
          </td>
          <td>
            <Row className="pl-3">
              {order.tags.map(t => this.orderTag(t))}
            </Row>
          </td>

          {
            ["0", "1", "4", "5"].includes(order.order_status) ?
              <>
                <td style={{ 'textAlign': 'center' }}>
                  {order.order_qty}<br></br>
                </td>
                <td>
                  {order.weight} {order.weight_type}
                </td>
              </>
              :
              <>
                <td>
                  {this.trackingNumber(order.tracking_number, order)}
                </td>
                <td>
                  <DropdownButton
                    id="dropdown-basic-button"
                    variant="link"
                    title={this.props.processing && this.props.processingType == 4 ? "Loading..." : "Actions"}
                    size="sm"
                    style={{ 'fontSize': '13px' }}
                    disabled={this.props.processing && this.props.processingType == 4}
                  >
                    {this.printPackingSlip(order.order_id)}
                    {this.voidLabelButton(order.order_id)}
                    {this.printOrderLabel(order.order_id)}
                  </DropdownButton>
                </td>
              </>
          }
          {
            order.rate_errors == "" ?
              <td onMouseEnter={() => this.setState({ showLabelPopover: this.togglePopover(order, label_errors) })} onMouseLeave={() => this.setState({ showLabelPopover: false })}>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  show={this.state.showLabelPopover}
                  trigger={['hover', 'focus']}
                  overlay={(this.orderStatusPopover(label_errors, status_message))}

                >
                  <Badge variant={inProcess == true ? varientClasses[8] : varientClasses[order.order_status]}>
                    {status[order.order_status]} <FontAwesomeIcon icon={faInfoCircle} style={{ 'display': this.toggleDisplay(order.status_message) }} />
                  </Badge>
                </OverlayTrigger>
              </td>
              :
              <td onMouseLeave={() => this.setState({ showSkuInfo: false })}>
                <Button
                  disabled={inProcess}
                  style={{ 'fontSize': '13px' }}
                  key={order.order_number + "_order_error_button"}
                  variant="link" ref={this.errorRef}
                  onMouseLeave={() => this.setState({ showRateErrors: !this.state.showRateErrors })}
                  onMouseEnter={() => this.setState({ showRateErrors: !this.state.showRateErrors })}
                >
                  <Badge variant={inProcess == true ? varientClasses[8] : varientClasses[order.order_status]}>{status[order.order_status]}</Badge>
                </Button>
                <Overlay key={"overlay-" + order.id} target={this.errorRef.current} show={this.state.showRateErrors} placement="right">
                  {(props) => (
                    this.rateErrorPopover(props)
                  )}
                </Overlay>
              </td>
          }
          <td>
            {order.order_date}
          </td>
          <td style={{ 'textAlign': 'center' }}>
            {this.carrierIcon(order.ship_with_carrier)}
          </td>
          <td>
            {order.ship_with_carrier_code}
          </td>
          <td>
            {order.requestedShippingService}
          </td>
          <td key={cheapestRate + "_order_rate"} onMouseLeave={() => this.setState({ show: false })}>
            <Button disabled={inProcess} style={{ 'fontSize': '13px' }} key={order.order_number + "_order_rate_button"} variant="link" ref={this.myRef} onClick={() => this.setState({ show: true })}>${parseFloat(cheapestRate).toFixed(2)}</Button>
            <Overlay key={"overlay-" + order.id} target={this.myRef.current} show={this.state.show} placement="left">
              {(props) => (
                this.popover(props)
              )}
            </Overlay>
          </td>
          <td>
            {order.country == "US" ? "Domestic" : "International"}
          </td>
          <td>
            {order.country}
          </td>
        </tr>
      );
    }
  }
}
// { labelData ? <a download={order.order_number} href={labelData}>Download Order Label</a> : '' }
