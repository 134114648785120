import React, { Component } from 'react';

// Bootstrap start
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Jumbotron from 'react-bootstrap/Jumbotron'
// Bootstrap end

class LoginForm extends Component{
  constructor() {
    super()

    this.state = {
      login_email: "",
      login_password: "",
      message: ""
    }
  }

  OnTextChange = e => {
    this.setState({
      [e.target.name]: e.target.value
      })
  }

  OnSubmit = e => {
    const { email, password } = this.state
    e.preventDefault()
    this.props.loginUser(this.state.login_email, this.state.login_password)
  }

  render() {
    return(
      <Jumbotron>
        <h1>Login</h1>
        <p>
          Login with your email and password.
        </p>
        <div>
          <Form onSubmit={this.OnSubmit} >
            <Form.Group>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                id="login_email"
                name="login_email"
                value={this.state.login_email}
                onChange={e => this.OnTextChange(e)}
                required={true} />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={e => this.OnTextChange(e)}
                value={this.state.login_password}
                id="login_password"
              name="login_password"/>
            </Form.Group>
            <Button variant="primary" type="submit" disabled={this.props.requesting}>
              {this.props.requesting ? 'Logging In…' : 'Login'}
            </Button>
          </Form>
        </div>
      </Jumbotron>
      )
  }
}

export default LoginForm;
