import React, { Component } from 'react';
import { connect } from 'react-redux';

// Bootstrap start
import Alert from 'react-bootstrap/Alert'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import CardGroup from 'react-bootstrap/CardGroup';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import TabContainer from 'react-bootstrap/TabContainer'
import TabPane from 'react-bootstrap/TabPane'
import TabContent from 'react-bootstrap/TabPane'
// Bootstrap end

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import LoginForm from './LoginForm.js';
import SignupForm from './SignupForm.js';
import { loginUser, createUser } from 'components/authActions.js';

class LoginContainer extends Component{
  constructor() {
    super()

    this.state = {
      message: ""
    }
  }

  LoadingMsg = () => {
    if (this.props.requesting) {
      return "Logging In"
      } else {
        return ""
      }
  }

  render() {
    if (this.props.loggedIn) {
      return <Redirect to="/users/orders" />
    }

    return(
      <Container className="p-3 bg-light" style={{fontSize: 14}} fluid>
        <Row md={1} className="flex-grow-1">
          <Col md={3} className="pt-3">
            {/* <SignupForm createUser={this.props.createUser}/> */}
          </Col>
          <Col md={6} className="pt-3">
            <p>
              {this.props.messages.error || this.props.messages.success}
            </p>
            <LoginForm loginUser={this.props.loginUser} requesting={this.props.requesting}/>
          </Col>
          <Col md={3} className="pt-3">
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.currentUser,
    messages: state.auth,
    requesting: state.auth.requesting,
    loggedIn: state.auth.loggedIn
  }
}

const mapDispatch = dispatch => {
  return {
    loginUser: (email, password) => { dispatch(loginUser(email, password)) },
    createUser: (name, email, password) => { dispatch(createUser(name, email, password)) }
  }
}

export default connect(mapStateToProps, mapDispatch)(LoginContainer)
