import React, { Component } from 'react';

// Bootstrap start
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Form from 'react-bootstrap/Form'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'
import Table from 'react-bootstrap/Table'

import Nav from 'react-bootstrap/Nav';
// Bootstrap end

import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  loggedInUser, logoutUser, updateApiKeys,
  updateUser, toggleTestMode, toggleStore,
  updateShipFromAddress, testApiKeys, hideAlert,
  updateTarrifs, getTarrifs, destroyTarrifs,
  getUserRateMarkups, updateUserRateMarkups,
  destroyUserRateMarkup, filterUserRateMarkups,
  getAvailableTimeZones, updateCutoffTime,
} from 'components/authActions.js';
import {
  getPackagings, getCustomPackagingsInitialOptionsData, setPackagings, refreshPackagings,
  getBulkDiscountPricing, setBulkDiscountPricing, destroyBulkDiscountPricing,
  getSkuPackagingLimits, setSkuPackagingLimits, destroySkuPackagingLimits,
  exportSkuPackagingLimitsCSV, importSkuPackagingLimitsCSV, getSkuPackagingLimitsInitialOptionsData,
  findSkuPackagingLimitsOptionsData, findCustomUserPackaging, createCustomPackaging
} from 'components/userActions.js';
import { deleteRecords } from 'components/shippingActions.js';
import AddPackagingSelect from './AddPackagingSelect.js';
import UserInfo from './UserInfo.js';
import RulesTable from './RulesTable.js';
import EditableTable from './EditableTable.js';
import ApiSettings from './ApiSettings.js';
import AddressForm from './AddressForm.js';
import TarrifForm from './TarrifForm.js';
import BulkProductPricing from './BulkProductPricing.js';
import ClientsForm from './ClientsForm.js';
import UserInfoForm from './UserInfoForm.js';
import Sidebar from './SideNav.js';
import SkuPackagingLimitsTable from './SkuPackagingLimitsTable.js';
import ProductPricing from './ProductPricing.js';
import { packagingColumns, tarrifColumns, bulkPricingColumns, markupColumns, skuPackagingLimitsColumns } from './TableColumns.js';
import ToastAlert from '../Toasts/ToastAlert.js';
import ToastsContainer from '../Toasts/ToastsContainer.js';

class UserContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      alerts: [],
      skus: [],
      exclusiveSkus: [],
      value: '',
      max_qty: 0,
      max_weight: 0.0,
      height: 0.0,
      width: 0.0,
      length: 0.0,
      skuInputSwitch: 0,
      selectedAddressStoreId: null,
      selectedAddress: (!props.addresses) ? "" : props.addresses[0]
    }

    this.setSkus = this.setSkus.bind(this);
    this.setValue = this.setValue.bind(this);
    this.SetSelectedStoreId = this.SetSelectedStoreId.bind(this);
    this.editRow = this.editRow.bind(this);
  }

  componentDidMount() {
    this.props.loggedInUser();
    this.props.getUserRateMarkups();
    this.props.getBulkDiscountPricing();
    this.props.getSkuPackagingLimits();
    this.props.getAvailableTimeZones();
    this.props.getCustomPackagingsInitialOptionsData();
  }

  createToast = (img, title, body) => {
    let alerts = this.state.alerts
    this.setState({
      alerts: [...alerts, { img: img, title: title, body: body }]
    })
  }

  setActiveInput = (input) => {
    if (input == 0) {
      this.setState({
        skuInputSwitch: 0,
        exclusiveSkus: []
      })
    } else {
      this.setState({
        skuInputSwitch: 1,
        skus: []
      })
    }
  }

  setSkus = (values) => {
    let excludedSkus = values.hasOwnProperty("excludedSkus") ? values["excludedSkus"] : []
    let exclusiveSkus = values.hasOwnProperty("exclusiveSkus") ? values["exclusiveSkus"] : []

    this.setActiveInput(excludedSkus?.length == 0 ? 1 : 0)

    this.setState({
      skus: excludedSkus,
      exclusiveSkus: exclusiveSkus
    })
  }

  setValue = (newValue, max_qty = 0) => {
    this.setState({
      value: newValue,
      max_qty: max_qty
    })
  }

  editRow = (row) => {
    this.setState({
      max_weight: row.max_weight,
      height: row.height,
      width: row.width,
      length: row.length,
      packagings: row.packagings,
      package_type: row.package_type == "shipstation" ? 0 : 1,
    })
  }

  resetRow = () => {
    this.setState({
      max_weight: 0.0,
      height: 0.0,
      width: 0.0,
      length: 0.0
    })
  }

  OnTextChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  Messages = () => {
    if (this.props.showAlert) {
      return (
        <Alert variant="info" className="mt-2 mb-2 text-uppercase" onClose={() => this.props.hideAlert()} dismissible>
          {this.props.error_message || this.props.success_message}
        </Alert>
      )
    } else {
      return ""
    }
  }

  SetSelectedStoreId = (id) => {
    let existingAddress = this.props.addresses.filter(a => a.store_id == id)
    let blankAddress = {
      address_type: null,
      city: "",
      company: "",
      country: "",
      created_at: "",
      id: "",
      name: "",
      phone: "",
      postal_code: "",
      residential: "",
      state: "",
      store_id: id,
      street1: "",
      street2: "",
      street3: "",
      user_id: "",
      notes: ""
    }

    if (existingAddress.length > 0) {
      this.setState({
        selectedAddressStoreId: id,
        selectedAddress: existingAddress[0]
      })
    } else {
      this.setState({
        selectedAddressStoreId: id,
        selectedAddress: blankAddress,
      })
    }
  }

  GetAddress = (id) => {
    if (storeId == null) {
      return null;
    } else {
      return this.props.addresses.filter(a => a.store_id == id)[0]
    }
  }

  render() {
    if (!this.props.loggedIn) {
      return <Redirect to="/users/login" />;
    }
    const deleteRecordsDropdownValues = [
      { label: "All Orders", value: 0 },
      { label: "All Batches", value: 1 },
      { label: "All Shipments", value: 2 },
      { label: "Everything", value: 3 }
    ]

    return (
      <Container className="p-3 bg-light" style={{ fontSize: 14 }} fluid>
        <ToastsContainer alerts={this.state.alerts} destroyToast={this.destroyToast} />
        <Row md={1} className="flex-grow-1">
          <Col md={12} className="pt-3">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col md={3} className="p-3 m-1 border border-primary rounded bg-white text-dark" style={{ 'minHeight': 'calc(100vh - 130px)', 'maxHeight': 'calc(100vh - 130px)', 'overflowY': 'auto' }}>
                  <Nav variant="pills" className="flex-column">
                    <Card className="p-3 m-3">
                      <Card.Img variant="top" src="avatar.png" style={{ 'height': '150px', width: '100%', objectFit: 'contain' }} />
                      <Card.Body>
                        <Card.Title>Hello, {this.props.name}</Card.Title>
                        <Card.Text>
                          Here you will find all the settings you need to get your shipping just right.
                        </Card.Text>
                        <Card.Text>
                          If you need help, please contact us at <a href="mailto: hello@shipmatix.com">hello@shipmatix.com</a>
                        </Card.Text>
                        <Card.Text>
                          <strong>Lifetime Orders Processed: </strong>{this.props.total_orders_processed}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Nav.Item>
                      <Nav.Link eventKey="first">Ship Carrier Settings</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="items_and_packaging">Item & Packaging Rules</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="sku_dimensions_setup">Sku Dimensions Setup and Rules</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="bulk_pricing_setup">Bulk Pricing Setup</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="locations">Warehouse Item Locations</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Integration Setup</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fourth">User Settings</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fifth">Automation</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="sixth">Product Tarrif Setup</Nav.Link>
                    </Nav.Item>
                    {this.props.user.admin ?
                      <Nav.Item>
                        <Nav.Link eventKey="seventh">Manage Clients</Nav.Link>
                      </Nav.Item>
                      : ""
                    }
                  </Nav>
                </Col>
                <Col className="col p-3 m-1 border border-primary rounded bg-white text-dark" style={{ 'minHeight': 'calc(100vh - 130px)', 'maxHeight': 'calc(100vh - 130px)', 'overflowY': 'auto' }}>
                  <Tab.Content>
                    {this.Messages()}
                    <Tab.Pane eventKey="first">
                      <Row>
                        <Button variant="primary" onClick={this.props.refreshPackagings} disabled={this.props.requesting} className="mb-2 ml-auto mr-2">
                          {this.props.requesting ? 'Loading…' : 'Refresh Carrier Services From Shipstation/Shipengine'}
                        </Button>
                      </Row>
                      <RulesTable />
                    </Tab.Pane>
                    <Tab.Pane eventKey="items_and_packaging">
                      <AddPackagingSelect
                        requesting={this.props.requesting}
                        refreshPackagings={this.props.refreshPackagings}
                        selectOptions={this.props.packagingList}
                        allPackagings={this.props.allPackagings}
                        initialCustomPackagingsList={this.props.initialCustomPackagings}
                        excludedSkus={this.props.excludedSkus}
                        exclusiveSkus={this.state.exclusiveSkus}
                        setPackagings={this.props.setPackagings}
                        setSkus={this.setSkus}
                        setValue={this.setValue}
                        editRow={this.editRow}
                        skus={this.state.skus}
                        value={this.state.value}
                        max_qty={this.state.max_qty}
                        max_weight={this.state.max_weight}
                        length={this.state.length}
                        width={this.state.width}
                        height={this.state.height}
                        activeInput={this.state.skuInputSwitch}
                        setActiveInput={this.setActiveInput}
                        OnTextChange={this.OnTextChange}
                        user={this.props.user}
                        findCustomUserPackaging={this.props.findCustomUserPackaging}
                        CustomPackagingsData={this.props.CustomPackagingsData}
                        createCustomPackaging={this.props.createCustomPackaging}
                        createCustomPackagingMessage={this.props.createCustomPackagingMessage}
                      />
                      <EditableTable
                        columns={packagingColumns}
                        rows={this.props.packagingRows}
                        editableFields={[]}
                        textFields={['excludedSkus', 'exclusive_skus']}
                        tableTitle={'Select And Add Allowed Packagings'}
                        getTableData={this.props.getTableData}
                        setPackagings={this.props.setPackagings}
                        setSkus={this.setSkus}
                        setValue={this.setValue}
                        editRow={this.editRow}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="sku_dimensions_setup">
                      <SkuPackagingLimitsTable
                        columns={skuPackagingLimitsColumns}
                        rows={this.props.skuPackagingLimits}
                        options={this.props.allPackagings}
                        getTableData={this.props.getSkuPackagingLimits}
                        updateTableData={this.props.setSkuPackagingLimits}
                        importCSVData={this.props.importSkuPackagingLimitsCSV}
                        getCSVData={this.props.exportSkuPackagingLimitsCSV}
                        destroyTableData={this.props.destroySkuPackagingLimits}
                        createToast={this.createToast}
                        catalog={this.props.catalog}
                        sku_packaging_limit_delete_messages={this.props.sku_packaging_limit_delete_messages}
                        successfully_imported_records_count={this.props.successfully_imported_records_count}
                        csv_import_errors={this.props.csv_import_errors}
                        inProgress={this.props.inProgress}
                        skuPackagingLimits={this.props.skuPackagingLimits}
                        getSkuPackagingLimitsInitialOptionsData={this.props.getSkuPackagingLimitsInitialOptionsData}
                        skuPackagingLimitsInitialOptionsData={this.props.skuPackagingLimitsInitialOptionsData}
                        findSkuPackagingLimitsOptionsData={this.props.findSkuPackagingLimitsOptionsData}
                        skuPackagingLimitsOptionsData={this.props.skuPackagingLimitsOptionsData}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="bulk_pricing_setup">
                      <BulkProductPricing
                        columns={bulkPricingColumns}
                        rows={this.props.bulkPricings}
                        getTableData={this.props.getBulkDiscountPricing}
                        updateTableData={this.props.setBulkDiscountPricing}
                        destroyTableData={this.props.destroyBulkDiscountPricing}
                        createToast={this.createToast}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="locations">
                      <h2>Item Locations</h2>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <ApiSettings
                        requesting={this.props.requesting}
                        keys={this.props.apiKeys.keySecrets.shipstation}
                        updateApiKeys={this.props.updateApiKeys}
                        testApiKeys={this.props.testApiKeys}
                        keyName={['shipstation', 'Ship Station']}
                        key={'shipstation'}
                      />
                      <ApiSettings
                        requesting={this.props.requesting}
                        keys={this.props.apiKeys.keySecrets.shipengine}
                        updateApiKeys={this.props.updateApiKeys}
                        testApiKeys={this.props.testApiKeys}
                        keyName={['shipengine', 'Ship Engine']}
                        key={'shipengine'}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth" >
                      <Jumbotron>
                        <Container>

                          <h1>Test Mode Settings</h1>
                          <p>
                            Test mode enables you to price shipping cost in real time without actually purchasing the label.
                            This will allow you to get to know the software using your real data without risk.
                          </p>
                          <div>
                            <Alert variant="light">
                              <Form.Check
                                type="switch"
                                label={this.props.testMode ? "Test Mode Enabled" : "Test Mode Disabled"}
                                id="mode-switch"
                                checked={this.props.testMode}
                                onChange={() => this.props.toggleTestMode()}
                              />
                            </Alert>
                          </div>
                          <h1>Order Actions</h1>
                          <p>
                            Delete all your orders. This will not effect any orders on shipstation.<br></br>
                            Your orders and shipments will be deleted, batches won't be effected.
                          </p>
                          <div>
                            <Alert variant="light">

                              <DropdownButton
                                disabled={this.props.processing && [8].some(i => i == this.props.processingType)}
                                as={ButtonGroup}
                                size={'md'}
                                key={'dropdown-delete-orders'}
                                id={'dropdown-delete-orders'}
                                variant={'danger'}
                                title={'Delete...'}
                              >
                                {deleteRecordsDropdownValues.map(i => {
                                  return (
                                    <Dropdown.Item
                                      size={'md'}
                                      eventKey={`${i.value}`}
                                      key={`drop-item-${i.value}`}
                                      name="delete_orders"
                                      onClick={() => this.props.deleteRecords(`${i.value}`)}>
                                      {this.props.processing && this.props.processingType == 8 ? 'Loading...' : `${i.label}`}
                                    </Dropdown.Item>
                                  )
                                }
                                )}
                              </DropdownButton>
                            </Alert>
                          </div>
                        </Container>
                      </Jumbotron>
                      <UserInfoForm user={this.props.user} updateUser={this.props.updateUser} timeZones={this.props.availableTimeZones} currentTimeZone={this.props.currentTimeZone} />
                      <AddressForm
                        address={this.state.selectedAddress}
                        setAddress={this.SetSelectedStoreId}
                        addresses={this.props.addresses}
                        stores={this.props.store_objects}
                        updateShipFromAddress={this.props.updateShipFromAddress}
                        createToast={this.createToast}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="fifth">
                      <Jumbotron>
                        <Container>
                          <h1>Enabled Stores</h1>
                          <p>
                            Select the stores you would like to import orders from on sync.
                          </p>
                          <div>
                            <Alert variant="light">
                              {
                                this.props.store_objects.map(s => {
                                  return (
                                    <Form.Check
                                      type="switch"
                                      label={s.enabled ? `Store ${s.label} ${s.value} Enabled` : `Store ${s.label} ${s.value} Disabled`}
                                      id={`store-switch-${s.id}`}
                                      checked={s.enabled}
                                      onChange={() => this.props.toggleStore(s.id)}
                                    />
                                  )

                                })
                              }
                            </Alert>
                          </div>
                        </Container>
                      </Jumbotron>
                      <Jumbotron>
                        <Container>
                          <h1>Label creation cutoff time</h1>
                          <p>
                            Help to ensure that all shipments for a given day are included on the USPS SCAN form by setting an automated cutoff time in ShipStation.
                            Setting a cutoff time tells ShipStation to automatically set the ship date to the next business day after a certain time (the shipping cutoff time) each day.
                            For example, you create the USPS SCAN form at 4:00 PM each day. All shipments created prior to 4:00 PM should be included on the current day's SCAN form.
                            Any shipments created after 4:00 pm should be included on the next day's SCAN form.
                            Setting the shipping cutoff time to 4:00 PM ensures that all shipments created after 4:00 PM will automatically be set to the next day's ship date.
                          </p>
                          <div>
                            <Alert variant="light">
                              {
                                // Text input for cutoff time
                                < Form.Control type="time" value={this.props.labelCutoffTime} onChange={(e) => this.props.updateCutoffTime(e.target.value)} />
                              }
                            </Alert>
                          </div>
                        </Container>
                      </Jumbotron>
                    </Tab.Pane>
                    <Tab.Pane eventKey="sixth">
                      <TarrifForm
                        columns={tarrifColumns}
                        rows={this.props.tarrifs}
                        getTableData={this.props.getTarrifs}
                        updateUserTarrifs={this.props.updateTarrifs}
                        destroyTarrifs={this.props.destroyTarrifs}
                        createToast={this.createToast}
                      />
                    </Tab.Pane>
                    {this.props.user.admin ?
                      <Tab.Pane eventKey="seventh">
                        <ClientsForm
                          columns={markupColumns}
                          rows={this.props.filteredMarkups}
                          userRateMarkupUsers={this.props.userRateMarkupUsers}
                          getTableData={this.props.getUserRateMarkups}
                          updateUserRateMarkups={this.props.updateUserRateMarkups}
                          destroyUserRateMarkup={this.props.destroyUserRateMarkup}
                          filterUserRateMarkups={this.props.filterUserRateMarkups}
                          createToast={this.createToast}
                        />
                      </Tab.Pane>
                      : ""
                    }
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    name: state.auth.currentUser.name,
    email: state.auth.currentUser.email,
    user: state.auth.currentUser,
    currentTimeZone: state.auth.currentUser.time_zone,
    labelCutoffTime: state.auth.labelCutoffTime,
    addresses: state.auth.currentUser.user_addresses,
    tarrifs: state.auth.userTarrifs,
    markups: state.auth.userRateMarkups,
    filteredMarkups: state.auth.filteredUserRateMarkups,
    userRateMarkupUsers: state.auth.userRateMarkupUsers,
    apiKeys: state.auth.currentUser.api_keys,
    showAlert: state.auth.showAlert,
    requesting: state.auth.requesting,
    processing: state.auth.processing,
    processingType: state.auth.processingType,
    availableTimeZones: state.auth.availableTimeZones,
    error_message: state.auth.error,
    success_message: state.auth.success,
    loggedIn: state.auth.loggedIn,
    packagingRows: state.user.allowedPackagings,
    allPackagings: [...state.user.availablePackagings, ...state.user.allowedPackagings],
    packagingList: state.user.availablePackagings,
    initialCustomPackagings: state.user.initialCustomPackagings,
    CustomPackagingsData: state.user.CustomPackagingsData,
    createCustomPackagingMessage: state.user.createCustomPackagingMessage,
    bulkPricings: state.user.bulkPricings,
    skuPackagingLimits: state.user.skuPackagingLimits,
    excludedSkus: state.user.excludedSkus,
    exclusiveSkus: state.user.exclusive_skus,
    testMode: state.auth.testMode,
    store_objects: state.auth.store_objects,
    catalog: state.user.catalog,
    sku_packaging_limit_delete_messages: state.user.sku_packaging_limit_delete_messages,
    successfully_imported_records_count: state.user.successfully_imported_records_count,
    csv_import_errors: state.user.csv_import_errors,
    inProgress: state.auth.inProgress,
    skuPackagingLimitsInitialOptionsData: state.user.skuPackagingLimitsInitialOptionsData,
    skuPackagingLimitsOptionsData: state.user.skuPackagingLimitsOptionsData,
    total_orders_processed: state.auth.currentUser.total_orders_processed
  };
};

const mapDispatch = dispatch => {
  return {
    updateApiKeys: (api_keys) => { dispatch(updateApiKeys(api_keys)) },
    loggedInUser: () => { dispatch(loggedInUser()) },
    logoutUser: () => { dispatch(logoutUser()) },

    getTableData: () => { dispatch(getPackagings()) },
    getCustomPackagingsInitialOptionsData: () => { dispatch(getCustomPackagingsInitialOptionsData()) },
    findCustomUserPackaging: (term) => { dispatch(findCustomUserPackaging(term)) },
    createCustomPackaging: (dataObject) => { dispatch(createCustomPackaging(dataObject)) },

    setPackagings: (packageData) => { dispatch(setPackagings(packageData)) },
    exportSkuPackagingLimitsCSV: () => { dispatch(exportSkuPackagingLimitsCSV()) },
    importSkuPackagingLimitsCSV: (csvFile) => { dispatch(importSkuPackagingLimitsCSV(csvFile)) },

    getSkuPackagingLimitsInitialOptionsData: () => { dispatch(getSkuPackagingLimitsInitialOptionsData()) },
    findSkuPackagingLimitsOptionsData: (term) => { dispatch(findSkuPackagingLimitsOptionsData(term)) },

    getBulkDiscountPricing: () => { dispatch(getBulkDiscountPricing()) },
    setBulkDiscountPricing: (pricingData) => { dispatch(setBulkDiscountPricing(pricingData)) },
    destroyBulkDiscountPricing: (id) => { dispatch(destroyBulkDiscountPricing(id)) },

    getSkuPackagingLimits: (pageNumber) => { dispatch(getSkuPackagingLimits(pageNumber)) },
    setSkuPackagingLimits: (data) => { dispatch(setSkuPackagingLimits(data)) },
    destroySkuPackagingLimits: (id) => { dispatch(destroySkuPackagingLimits(id)) },

    testApiKeys: (source) => { dispatch(testApiKeys(source)) },

    hideAlert: () => { dispatch(hideAlert()) },
    toggleTestMode: () => { dispatch(toggleTestMode()) },
    toggleStore: (id) => { dispatch(toggleStore(id)) },

    updateShipFromAddress: (address) => { dispatch(updateShipFromAddress(address)) },
    updateUser: (name, email, password, currentTimeZone) => { dispatch(updateUser(name, email, password, currentTimeZone)) },
    getAvailableTimeZones: () => { dispatch(getAvailableTimeZones()) },

    refreshPackagings: () => { dispatch(refreshPackagings()) },

    updateTarrifs: (tarrifs) => { dispatch(updateTarrifs(tarrifs)) },
    getTarrifs: () => { dispatch(getTarrifs()) },
    destroyTarrifs: (id) => { dispatch(destroyTarrifs(id)) },

    getUserRateMarkups: () => { dispatch(getUserRateMarkups()) },
    updateUserRateMarkups: (UserRateMarkup) => { dispatch(updateUserRateMarkups(UserRateMarkup)) },
    destroyUserRateMarkup: (id) => { dispatch(destroyUserRateMarkup(id)) },
    filterUserRateMarkups: (user_id) => { dispatch(filterUserRateMarkups(user_id)) },
    deleteRecords: (type) => { dispatch(deleteRecords(type)) },

    updateCutoffTime: (time) => { dispatch(updateCutoffTime(time)) },
  };
};

export default connect(mapStateToProps, mapDispatch)(UserContainer);
