import React, { PureComponent } from 'react';
import { Button } from 'grommet';
import { Edit } from 'grommet-icons';

class EditRow extends PureComponent {
  constructor(props) {
    super(props)
  }

  onClick = () => {
    let packageData = this.props.rowDetails
    let max_qty = packageData.max_qty
    packageData.allowed = false
    // this.props.setPackagings(packageData);
    let skus = {
      excludedSkus: packageData.excludedSkus.map(sku => ({ label: sku, value: sku }) ),
      exclusiveSkus: packageData.exclusive_skus.map(sku => ({ label: sku, value: sku }) )
    }
    this.props.setSkus(skus)
    this.props.setValue({ value: packageData.id, label: this.camelize(`${packageData.carrierCode} - ${packageData.name}`) }, max_qty)
    this.props.editRow(packageData)
  }

  camelize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  render () {
    return(
      <Button
        name="Edit Row"
        onClick={this.onClick}
        size="small"
        icon={<Edit size="small"/>}
      />
    )
  }
}

export default EditRow;
