import React, { Component } from 'react'
import { Avatar, Button, Box, Heading, Nav, Sidebar, Stack, Text } from "grommet";
import { Analytics, Chat, Clock, Configure, Help, Logout, Projects, Split, StatusInfoSmall, User } from "grommet-icons";

export default class SideNav extends Component {
  render() {
    const src = "//s.gravatar.com/avatar/b7fb138d53ba0f573212ccce38a7c43b?s=80";

    const SidebarHeader = () => (
      <Box align="center" gap="small" direction="row" margin={{ bottom: "large" }}>
        <Stack alignSelf="start" align="center" anchor="top-right">
          <Avatar background="light-5">
            <User color="light-1" />
          </Avatar>
        </Stack>
        <Text>Welcome {this.props.name}!</Text>
      </Box>
      );

    const MainNavigation = () => (
       <Nav gap="large" responsive={false}>
          <SidebarButton icon={<Configure />} label="Shipping Setup" />
          <SidebarButton icon={<Clock />} label="Glances" />
        </Nav>
      );

    const SidebarButton = ({ icon, label, ...rest }) => (
      <Box pad="small">
        <Button
          gap="medium"
          alignSelf="start"
          plain
          icon={icon}
          label={label}
          {...rest}
        />
      </Box>
    );

    const SidebarFooter = () => (
      <Nav>
        <SidebarButton icon={<Help />} label="Support" />
        <SidebarButton icon={<Logout />} label="Logout" onClick={this.props.logoutUser}/>
      </Nav>
    );

    return (
      <div>
        <Box direction="row" height={{ min: "100%" }}>
          <Sidebar
            responsive={false}
            background="#1d2334"
            header={<SidebarHeader />}
            footer={<SidebarFooter />}
            pad={{ left: "medium", right: "large", vertical: "medium" }}
          >
            <MainNavigation />
          </Sidebar>
        </Box>
      </div>
    )
  }
}
