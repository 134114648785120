import React, { Component } from 'react';

// Bootstrap start
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Jumbotron from 'react-bootstrap/Jumbotron'
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'

// Bootstrap end

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

class BulkProductPricing extends Component{
  constructor(props) {
    super(props)

    this.state = {
      id: "",
      name: "",
      qty_from: "",
      qty_to: "",
      discount_percent: ""
    }
    this.baseState = this.state
  }

  componentDidMount() {
      this.props.getTableData();
  }

  OnTextChange = e => {
    this.setState({
      [e.target.name]: e.target.value
      })
  }

  ResetForm = () => {
    this.setState(this.baseState)
  }

  OnSubmit = e => {
    e.preventDefault()

    const state = this.state
    const dataObject = {
      id: state.id,
      name: state.name,
      qty_from: state.qty_from,
      qty_to: state.qty_to,
      discount_percent: state.discount_percent
    }
    this.props.updateTableData(dataObject);

    // this.props.createToast("", "User Info", "Tarrif Successfully Updated.")
    this.ResetForm()
  }

  EditRule = e => {
    let id = e.target.id
    let row = this.props.rows.find(r => r.id == parseInt(id))

    this.setState({
      id: row.id,
      name: row.name,
      qty_from: row.qty_from,
      qty_to: row.qty_to,
      discount_percent: row.discount_percent
    })
  }

  DestroyRule = e => {
    let id = e.target.id

    this.props.destroyTableData(id)
    this.ResetForm()
  }

  render() {
    const { columns, rows } = this.props;
    const visibleColumns = columns.map(col => col.key);

    return(
      <div>
        <Jumbotron>
        <Container>
          <h1>Bulk Product Pricing Setup</h1>
          <p>
            View And Manage Item Pricing.
            This tool allows you to set discounted pricing for bulk customer purchasing.
          </p>
          <div>
            <Form>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" id="name" name="name" placeholder="Enter name" value={this.state.name} onChange={this.OnTextChange}/>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>Min(Including min) Quantity</Form.Label>
                  <Form.Control type="number" id="qty_from" name="qty_from" placeholder="Enter Min(from) QTY" value={this.state.qty_from} onChange={this.OnTextChange}/>
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Max(Excluding max) Quantity</Form.Label>
                  <Form.Control type="number" id="qty_to" name="qty_to" placeholder="Enter Max(to) QTY" value={this.state.qty_to} onChange={this.OnTextChange}/>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>Discount Percent</Form.Label>
                  <Form.Control type="number" id="discount_percent" name="discount_percent" placeholder="Enter Discount Percent" value={this.state.discount_percent} onChange={this.OnTextChange}/>
                </Form.Group>
              </Row>

              <Button
                name="Add Bulk Discount Rule"
                onClick={(e) => this.OnSubmit(e)}
                className="m-2 p-2"
              >
                Add Bulk Discount Rule
              </Button>

            </Form>
          </div>
          </Container>
        </Jumbotron>

        <Container>
        <Table>
          <thead>
            <tr>
              {columns.map(col => {
                return (
                  <th key={col.key} scope="col" border="bottom">
                    {col.name}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {rows.map((rowData, i) => {
              return (
                <tr key={i}>
                  {Object.entries(rowData).map(([objKey, cellValue]) => {
                    if (visibleColumns.includes(objKey)) {
                      return (
                        <td key={objKey + `-` + i}>
                          {cellValue}
                        </td>
                      )
                    }
                  })}
                  <td size="xsmall" key={'actions' + '-' + i}>
                    <Button
                      id={rowData.id}
                      name="Edit Tarrif"
                      onClick={(e) => this.EditRule(e)}
                      className="m-1 p-0"
                      variant="link"
                    >
                      Edit
                    </Button>
                    <Button
                      id={rowData.id}
                      name="Delete Tarrif"
                      onClick={(e) => this.DestroyRule(e)}
                      className="m-1 p-0"
                      variant="link"
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        </Container>
      </div>
      )
  }
}

export default BulkProductPricing;
