import React, { Component } from 'react';

// Bootstrap start
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Jumbotron from 'react-bootstrap/Jumbotron'
// Bootstrap end

class VerifySignupCode extends Component{
  constructor(props) {
    super(props)

    this.state = {
      code: ""
    }
  }

  OnTextChange = e => {
    this.setState({
      [e.target.name]: e.target.value
      })
  }

  OnSubmit = e => {
    e.preventDefault()

    this.props.verifyCode(this.state.code);
  }

  render() {
    return(
      <Jumbotron>
        <h1>Enter Verifcation Code</h1>
        <p>
          Check your email you used when signing up for a verification code.
        </p>
        <Form onSubmit={this.OnSubmit}>
          <Form.Group >
            <Form.Label>Code</Form.Label>
            <Form.Control type="text" id="code" name="code" placeholder="Enter code" value={this.state.code} onChange={this.OnTextChange}/>
          </Form.Group>

          <Button variant="primary" type="submit" disabled={this.props.requesting}>
            {this.props.requesting ? 'Loading…' : 'Verify Code'}
          </Button>
        </Form>
      </Jumbotron>
    )
  }
}

export default VerifySignupCode;
