const authReducer =
  (state = {
    currentUser: "",
    loggedIn: false,
    email_confirmed: false,
    signup_tab: "signup",
    requesting: false,
    error: "",
    success: "",
    showAlert: false,
    toasts_title: "",
    toasts_body: "",
    processing: false,
    inProgress: false,
    testMode: true,
    // 1 = "rates", 2 = "pdf"
    processingType: 0,
    userTarrifs: [],
    userRateMarkups: [],
    filteredUserRateMarkups: [],
    userRateMarkupUsers: [],
    orders: [],
    openOrders: 0,
    catalog: {
      unprocessedOrders: { pageNumber: 1, pages: 1, activeFilters: [], count: 0 },
      processedOrders: { pageNumber: 1, pages: 1, activeFilters: [], count: 0 },
      printedOrders: { pageNumber: 1, pages: 1, activeFilters: [], count: 0 }
    },
    unprocessedOrders: [],
    processedOrders: [],
    allUserOrderIds: [],
    printedOrders: [],
    printBatches: [],
    activeFilters: [],
    stores: [],
    store_objects: [],
    selectedStores: [],
    orderTags: [],
    orderTagsComplete: [],
    selectedOrderTags: [],
    titleChanged: false,
    sortOrdersColumn: "Date",
    sortOrdersColumnDirection: "dsc",
    sortShipmentsColumn: "Date",
    sortShipmentsColumnDirection: "dsc",
    sortBatchesColumn: "Date",
    sortBatchesColumnDirection: "dsc",
    availableTimeZones: [],
    labelCutoffTime: "00:00",
    searchQuery: "",
    failedOrders: [],
    filterOptions: [
      { name: "Standard", value: "standard" },
      { name: "Expedited", value: "expedited" },
      { name: "Next Day", value: "next_day" },
      { name: "Second Day", value: "second_day" },
      { name: "Domestic", value: "domestic" },
      { name: "International", value: "international" },
      { name: "Orders With Rates", value: "ratedOrders" },
      { name: "Orders Without Rates", value: "unRatedOrders" },
      { name: "Printed Orders", value: "printedOrders" },
    ]
  }, action) => {
    switch (action.type) {

      case "START_LOGIN_REQUEST":
        return {
          ...state,
          currentUser: state.currentUser,
          requesting: true
        }

      case "LOGIN_FAILED":
        return {
          ...state,
          currentUser: "",
          requesting: false,
          loggedIn: false,
          error: action.error,
          showAlert: true
        }

      case "LOGIN_USER":
        return {
          ...state,
          currentUser: action.user,
          orders: action.user.orders,
          stores: action.user.stores,
          store_objects: action.user.store_objects,
          orderTags: action.user.order_tags,
          orderTagsComplete: action.user.order_tags_complete,
          labelCutoffTime: action.user.label_cutoff_time,
          requesting: false,
          loggedIn: true,
          signup_tab: "",
          testMode: action.user.test_mode,
          error: "",
          success: action.success
        }

      case "NEW_USER":
        return {
          ...state,
          currentUser: action.user,
          requesting: false,
          signup_tab: action.signup_tab,
          error: "",
          success: action.success,
          showAlert: true
        }

      case "CONTINUE_SIGNUP":
        return {
          ...state,
          currentUser: action.user || state.currentUser,
          requesting: false,
          signup_tab: action.signup_tab || state.signup_tab,
          error: action.error || "",
          success: action.success || "",
          showAlert: true
        }

      case "SET_TAB":
        return {
          ...state,
          signup_tab: action.signup_tab,
          requesting: false
        }

      case "HIDE_ALERT":
        return {
          ...state,
          showAlert: false,
          requesting: false
        }

      case "SHOW_ALERT":
        return {
          ...state,
          requesting: false,
          error: action.error || "",
          success: action.success || "",
          showAlert: true
        }

      case "VERIFY_NEW_USER":
        return {
          ...state,
          email_confirmed: action.email_confirmed,
          signup_tab: action.signup_tab,
          requesting: false,
          error: "",
          success: action.success,
          showAlert: true
        }

      case "AUTHED_USER":
        return {
          ...state,
          currentUser: action.user,
          labelCutoffTime: action.user.label_cutoff_time,
          orders: action.user.orders,
          stores: action.user.stores,
          store_objects: action.user.store_objects,
          orderTags: action.user.order_tags,
          orderTagsComplete: action.user.order_tags_complete,
          requesting: false,
          loggedIn: true,
          testMode: action.user.test_mode,
          error: "",
          success: action.success
        }

      case "LOGGED_IN_USER":
        return {
          ...state,
          currentUser: state.currentUser,
          labelCutoffTime: state.currentUser.label_cutoff_time,
          orders: state.orders,
          requesting: false,
          loggedIn: true,
          error: "",
          success: action.success
        }

      case "CREATE_USER":
        return {
          ...state,
          currentUser: action.user,
          shippingRules: {},
          requesting: false,
          loggedIn: true,
          testMode: action.user.test_mode,
          error: "",
          success: action.success,
          showAlert: true
        }

      case "UPDATE_USER_ADDRESS":
        return {
          ...state,
          currentUser: action.user,
          requesting: false,
          loggedIn: true,
          error: "",
          success: action.success
        }

      case "LOGOUT_USER":
        return {
          ...state,
          currentUser: "",
          requesting: false,
          loggedIn: false,
          error: "",
          success: action.success
        }

      //User settings reducers
      case "START_REQUEST":
        return {
          ...state,
          error: "",
          success: "",
          requesting: true
        }

      case "END_REQUEST":
        return {
          ...state,
          requesting: false,
          processing: false,
          inProgress: false
        }

      case "START_CSV_IMPORT_REQUEST":
        return {
          ...state,
          inProgress: true
        }

      case "FINISHED_CSV_IMPORT_REQUEST":
        return {
          ...state,
          inProgress: false
        }

      case "REQUEST_FAILED":
        return {
          ...state,
          requesting: false,
          loggedIn: state.loggedIn,
          error: action.error || "Could not save your settings, please check yout internet connection.",
          showAlert: true
        }

      case "PROCESSING_REQUEST":
        return {
          ...state,
          processing: true,
          processingType: action.jobType,
          requesting: false
        }

      case "GET_UNPROCESSED_ORDERS":
        return {
          ...state,
          processing: false,
          requesting: false,
          unprocessedOrders: action.orders,
          allUserOrderIds: action.all_order_ids,
          activeFilters: action.activeFilters,
          catalog: {
            ...state.catalog,
            unprocessedOrders: {
              pageNumber: action.pageNumber,
              pages: action.pages,
              count: action.count
            }
          }
        }

      case "GET_AVAILABLE_TIME_ZONES":
        return {
          ...state,
          availableTimeZones: action.available_time_zones
        }

      case "GET_PROCESSED_ORDERS":
        return {
          ...state,
          processing: false,
          requesting: false,
          processedOrders: action.orders,
          activeFilters: action.activeFilters,
          catalog: {
            ...state.catalog,
            processedOrders: {
              pageNumber: action.pageNumber,
              pages: action.pages,
              count: action.count
            }
          }
        }

      case "GET_PRINTED_ORDERS":
        return {
          ...state,
          processing: false,
          requesting: false,
          printedOrders: action.orders,
          activeFilters: action.activeFilters,
          catalog: {
            ...state.catalog,
            printedOrders: {
              pageNumber: action.pageNumber,
              pages: action.pages,
              count: action.count
            }
          }
        }

      case "GET_USER_TARRIFS":
        return {
          ...state,
          requesting: false,
          userTarrifs: action.user_tarrifs,
          success: action.success
        }

      case "GET_USER_RATE_MARKUPS":
        return {
          ...state,
          requesting: false,
          userRateMarkups: action.user_rate_markups,
          userRateMarkupUsers: action.user_rate_markup_users,
          success: action.success
        }

      case "FILTER_USER_RATE_MARKUPS":
        return {
          ...state,
          filteredUserRateMarkups: state.userRateMarkups.filter(markup => markup.user_id == action.user_id),
        }

      case "GET_PRINT_BATCHES":
        return {
          ...state,
          processing: false,
          requesting: false,
          printBatches: action.batches.reverse()
        }

      case "ORGANIZE_ORDERS":
        return {
          ...state,
          processing: false,
          requesting: false,
          unprocessedOrders: state.orders.filter(o => o.attributes.order_status == "0" || o.attributes.order_status == null),
          processedOrders: state.orders.filter(o => o.attributes.order_status == "2"),
          printedOrders: state.orders.filter(o => o.attributes.order_status == "3")
        }

      case "TOGGLE_TEST_MODE":
        return {
          ...state,
          testMode: action.test_mode,
          success: action.success
        }

      case "UPDATE_STORES":
        return {
          ...state,
          store_objects: action.store_objects,
          success: action.success
        }

      case "UPDATE_CUTOFF_TIME":
        return {
          ...state,
          requesting: false,
          labelCutoffTime: action.label_cutoff_time,
          success: action.success
        }

      case "UPDATE_ACTIVE_FILTERS":
        return {
          ...state,
          activeFilters: action.activeFilters,
          selectedStores: action.selectedStores,
          selectedOrderTags: action.selectedOrderTags
        }

      case "TITLE_CHANGED":
        return {
          ...state,
          titleChanged: action.changed
        }

      case "SET_SORT_COLUMN":
        return {
          ...state,
          [`sort${action.tab}Column`]: action.column,
          [`sort${action.tab}ColumnDirection`]: action.direction
        }

      case "SORT_TABLE_DATA_ASC":
        const decimalCols = []

        return {
          ...state,
          unprocessedOrders: state.unprocessedOrders.sort((a, b) => {
            if (/\d/.test(a.attributes[action.columnKey]) && !a.attributes[action.columnKey].toString().includes("-")) {
              return parseFloat(a.attributes[action.columnKey]) - parseFloat(b.attributes[action.columnKey])
            } else if (a.attributes[action.columnKey] != null) {
              return a.attributes[action.columnKey].localeCompare(b.attributes[action.columnKey])
            } else {
              return 0;
            }
          }),
          processedOrders: state.processedOrders.sort((a, b) => {
            if (/\d/.test(a.attributes[action.columnKey]) && !a.attributes[action.columnKey].toString().includes("-")) {
              return parseFloat(a.attributes[action.columnKey]) - parseFloat(b.attributes[action.columnKey])
            } else if (a.attributes[action.columnKey] != null) {
              return a.attributes[action.columnKey].localeCompare(b.attributes[action.columnKey])
            } else {
              return 0;
            }
          })
        }

      case "SORT_TABLE_DATA_DSC":
        return {
          ...state,
          unprocessedOrders: state.unprocessedOrders.sort((a, b) => {
            if (/\d/.test(a.attributes[action.columnKey]) && !a.attributes[action.columnKey].toString().includes("-")) {
              return parseFloat(b.attributes[action.columnKey]) - parseFloat(a.attributes[action.columnKey])
            } else if (b.attributes[action.columnKey] != null) {
              return b.attributes[action.columnKey].localeCompare(a.attributes[action.columnKey])
            } else {
              return 0;
            }
          }),
          processedOrders: state.processedOrders.sort((a, b) => {
            if (/\d/.test(a.attributes[action.columnKey]) && !a.attributes[action.columnKey].toString().includes("-")) {
              return parseFloat(b.attributes[action.columnKey]) - parseFloat(a.attributes[action.columnKey])
            } else if (b.attributes[action.columnKey] != null) {
              return b.attributes[action.columnKey].localeCompare(a.attributes[action.columnKey])
            } else {
              return 0;
            }
          })
        }

      case "SET_QUERY":
        return {
          ...state,
          searchQuery: action.query
        }

      case "SET_FAILED_ORDERS":
        return {
          ...state,
          failedOrders: action.orders,
          printBatches: action.print_batches.reverse()
        }

      case "UPDATE_SINGLE_ORDER":
        if (Array.isArray(action.orders) || action.orders.length) {
          let orders = state.unprocessedOrders;
          let orderIndex = state.unprocessedOrders.findIndex(o => o.id == action.orders[0]?.id)
          orders[orderIndex] = action.orders[0]

          return {
            ...state,
            unprocessedOrders: orders
          }
        }

      default:
        return state;
    }
  }

export default authReducer;
