import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';

// Bootstrap start
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
// Bootstrap end

import CellCheckBox from './CellCheckBox.js';
import Loader from './Loader.js';
import ResetRow from './ResetRow.js';
import EditRow from './EditRow.js';


// Props:
// - getTableData() function that loads table data into state
// - updateTableData() function, takes dataKey and new data
// - columns, rows, editableFields, tableTitle, textFields

class EditableTable extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      tableRendered: false,
      showOrderInfo: false,
      modelSkus: []
    }
  }

  componentDidMount() {
    this.props.getTableData();
  }

  getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  }

  orderSkusModel = () => {
    const varientClasses = { 0: "warning", 1: "primary", 2: "success", 3: "info", 4: "danger", 5: "danger", 6: "danger", 7: "light", 8: "light" }
    return (
      <Modal
        onHide={() => this.setState({ showOrderInfo: false })}
        show={this.state.showOrderInfo}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <strong>Skus</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={12}>
                <p>
                  <strong>Skus In Order:</strong>
                </p>
                <p>
                  {this.state.modelSkus.map(sku => {
                    return (
                      <>
                        <Badge pill variant={varientClasses[this.getRandomInt(8)]}>{sku}</Badge>
                        <span> </span>
                      </>
                    )
                  }
                  )}
                </p>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => this.setState({ showOrderInfo: !this.state.showOrderInfo })}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  OnSaveTableCell = (dataKey, newCellData) => {
    // Get cell position from dataKey
    const key = dataKey
    const rowId = parseInt(key.split("-")[1])
    const rowField = key.split("-")[0]

    // Get a copy of the props
    let stateCopy = [...this.props.rows];

    // Update row field in state copy
    stateCopy[rowId][rowField] = newCellData;

    // post state row to api
    const updated_row = stateCopy[rowId]
    this.props.updateTableData(updated_row);
  }

  RenderTable = () => {
    const { columns, rows, editableFields, textFields } = this.props;
    const visibleColumns = columns.map(col => col.key);

    if (!rows) {
      return (
        "No Data Available"
      )
    } else {
      return (
        <div>
          {this.orderSkusModel()}
          <Table hover size="sm">
            <thead>
              <tr>
                {columns.map(col => {
                  return (
                    <th key={col.key} scope="col" border="bottom">
                      {col.name}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {rows.map((rowData, i) => {
                return (
                  <tr key={i}>
                    {Object.entries(rowData).map(([objKey, cellValue]) => {
                      if (visibleColumns.includes(objKey)) {

                        // Add method field to db to indicate editable fields
                        // Fields are currently protected by strong params
                        if ((editableFields.includes(objKey))) {
                          return (
                            <td key={objKey + `-` + i}>
                              <CellCheckBox
                                OnSave={this.OnSaveTableCell}
                                value={cellValue}
                                dataKey={objKey + `-` + i} />
                            </td>
                          )
                        } else if ((textFields.includes(objKey))) {
                          return (
                            <td key={objKey + `-` + i}>
                              <Button variant="link" onClick={() => this.setState({ showOrderInfo: !this.state.showOrderInfo, modelSkus: cellValue })}>{cellValue.toString().slice(0, 10)}{cellValue.toString().length > 0 ? '...' : ''}</Button>
                            </td>
                          )
                        } else {
                          return (
                            <td key={objKey + `-` + i}>
                              {cellValue?.toString() == "0" ? "" : cellValue?.toString()}
                            </td>
                          )
                        }
                      }
                    })}
                    {
                      <td size="xsmall" key={'actions' + '-' + i}>
                        <EditRow rowDetails={rowData} key={'edit' + '-' + i} setPackagings={this.props.setPackagings} setSkus={this.props.setSkus} setValue={this.props.setValue} editRow={this.props.editRow} />
                        <ResetRow rowDetails={rowData} key={'reset' + '-' + i} setPackagings={this.props.setPackagings} />
                      </td>
                    }
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      )
      this.setState({
        tableRendered: true
      })
    }
  }

  render() {
    return (
      <div>
        {this.RenderTable()}
      </div>
    )
  }
}

export default (EditableTable);
