import React, { PureComponent } from 'react';
import Form from 'react-bootstrap/Form'

export default class Filters extends PureComponent{
  constructor(props) {
    super(props)
    this.state = {
      filterOptions: this.props.filterOptions.filter(o => !props.excludeFilter.includes(o.value)),
      activeFilters: this.props.activeFilters,
      orderType: this.props.orderType
    }
  }

  setValue(option) {
    const { pageNumber, orderType } = this.props;
    let filters = [];
    if (option.checked === false) {
      filters = this.props.activeFilters.filter(f => f !== option.name)
    } else {
     filters = [...this.props.activeFilters, option.name]
    }

    this.setState({
      activeFilters: filters
    }, () => {
      const activeFilters = this.state.activeFilters;
      this.props.updateActiveFilters(activeFilters, this.props.selectedStores, this.props.selectedOrderTags);
      // const updatedFilters = this.state.activeFilters.push(this.props.selectedStores.map(store => store.value ));
      this.props.getOrders(pageNumber, orderType, activeFilters);
    });
  }

  render() {
    return(
      <div>
        <Form>
          {this.state.filterOptions.map((option) => (
            <div key={`default-${option.name}`} className="mb-2">
              <Form.Check
                type={'checkbox'}
                id={`default-${option.name}`}
                label={option.name}
                value={option.value}
                checked={this.props.activeFilters.find(f => f === option.name)}
                onChange={(event) => this.setValue({name: option.value, label: option.name, checked: event.target.checked})}
              />
            </div>
          ))}
        </Form>
      </div>
    )
  }
}
