import React, { PureComponent } from 'react';
import { CheckBox } from 'grommet';

class CellCheckBox extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      checked: props.value
    }
  }

  setChecked() {
    this.setState({
      checked: !this.state.checked
    },
    () => this.props.OnSave(this.props.dataKey, !this.props.value)
    )
  }

  render () {
    return(
      <CheckBox
        checked={this.state.checked}
        id={this.props.dataKey}
        onChange={(event) => this.setChecked(event.target.checked)}
        key={this.props.dataKey}
      />
    )
  }
}

export default CellCheckBox;
