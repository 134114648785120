import React, { PureComponent } from 'react';
import BatchModal from './BatchModal.js';

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import CardGroup from 'react-bootstrap/CardGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

export default class Batch extends PureComponent{
  constructor(props) {
    super(props)
    this.myRef = React.createRef();

    this.state = {
      info: this.props.batchInfo,
      checked: this.props.checked,
      label: this.props.label,
      showModal: false,
      showOrderErrorPopover: false
    }
    // this.props.handleInputChange = this.props.handleInputChange.bind(this);
  }

  generateLabelsByBatchBtn = (batchId, enabled) => {
    return(
      <Dropdown.Item style={{'fontSize': '13px'}}
        onClick={() => this.props.generateLabelsByBatch(batchId)}
        disabled={enabled || this.props.processing && this.props.processingType == 4}
      >
        {this.props.requesting ? 'Downloading…' : 'Generate Labels'}
      </Dropdown.Item>
    )
  }

  bulkVoidBatchLabelsBtn = (batchId) => {
    return(
      <Dropdown.Item style={{'fontSize': '13px'}}
        onClick={() => this.props.bulkVoidBatchLabels(batchId)}
      >
        {this.props.requesting ? 'Voiding Labels' : 'Void All Batch Lables'}
      </Dropdown.Item>
    )
  }

  createPicklistByBatchBtn = (batchId) => {
    let orderIds = this.props.orderIds;
    return(
      <Dropdown.Item
        style={{'fontSize': '13px'}}
        onClick={() => this.props.generatePicklist(orderIds)}
        disabled={this.props.processing}
      >
        {this.props.requesting ? 'Creating…' : 'Create Picklist'}
      </Dropdown.Item>
    )
  }

  exportCsvByBatchBtn = (batchId) => {
    return(
      <Dropdown.Item
        style={{'fontSize': '13px'}}
        onClick={() => this.props.exportCsv(this.state.info.order_ids, true)}
        disabled={this.props.processing}
      >

        {this.props.requesting ? 'Exporting…' : 'Export CSV'}
      </Dropdown.Item>
    )
  }

  deleteBatchByBatchBtn = (batchId, enabled) => {
    return(
      <Dropdown.Item
        style={{'fontSize': '13px'}}
        onClick={() => this.props.deleteBatch(batchId)}
        disabled={enabled || this.props.processing}
      >
        {this.props.requesting ? 'Deleting…' : 'Delete Batch'}
      </Dropdown.Item>
    )
  }

  batchActionsBtn = () => {
    let id = this.props.batchId;
    let batch = this.props.batchInfo;
    let picklist_url = (batch.picklist_url != null ? batch.picklist_url : "");

    return (
      <DropdownButton
        id="dropdown-basic-button"
        variant="link"
        title={this.props.processing && this.props.processingType == 4 ? "Loading..." : "Actions"}
        size="sm"
        style={{'fontSize': '13px'}}
        disabled={this.props.processing && this.props.processingType == 4}
      >
        { this.generateLabelsByBatchBtn(id, picklist_url != "") }
        { this.createPicklistByBatchBtn(id) }
        { this.exportCsvByBatchBtn(id) }
        { this.bulkVoidBatchLabelsBtn(id)}
        { this.deleteBatchByBatchBtn(id, picklist_url != "") }
      </DropdownButton>
    )
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    })
  }


  buildModal = (title, body, id, orderNumbers, failedOrders, shippingIcons, updateShipstationOrder) => {

    return (
      <BatchModal
        key={"failed-orders-modal-" + id}
        failedOrders={this.props.failedOrders}
        title={title} body={body}
        id={id} show={this.state.showModal}
        orderNumbers={orderNumbers}
        getOrdersByNumber={this.props.getOrdersByNumber}
        removeOrderFromBatch={this.props.removeOrderFromBatch}
        toggleModal={this.toggleModal}
        generateLabels={this.props.generateLabels}
        orderInfo={this.props.orderInfo}
        messages={() => this.props.messages()}
        shippingIcons={this.props.shippingIcons}
        updateShipstationOrder={this.props.updateShipstationOrder}
        processing={this.props.processing}
        processingType={this.props.processingType}
        getSingleOrder={this.props.getSingleOrder}
      />
      )
  }

  setChecked(state, event) {
    this.setState({
      checked: state
    })
    this.props.handleInputChange(event)
  }

  popover = (props) => {
    const batch = this.props.batchInfo;
    return (
        <Popover style={{'fontSize': '14px'}} id="popover-basic" {...props}>
          <Popover.Title as="h3">Orders In Batch</Popover.Title>
          <Popover.Content style={{'fontSize': '14px'}}>
            <ol>
              {batch.order_numbers.map(r => {
                return (
                  <li key={"li-" + r}>{r}</li>
                )
              })}
            </ol>
          </Popover.Content>
        </Popover>
    )
  }

  failedOrderErrorsPopover = (failed_orders) => {
    const ordersWithErrors = failed_orders.filter(order => order.attributes.label_errors)
    return(
      <Popover style={{'fontSize': '14px'}} id="popover-basic">
      <Popover.Title as="h3">Error Detail</Popover.Title>
      <Popover.Content style={{'fontSize': '14px'}}>
        { ordersWithErrors.length != 0 ?
          <ol>
              {ordersWithErrors.map(o => {
                return (
                  <li key={"li-" + o.attributes.id}><strong>{o.attributes.order_number}</strong> - {o.attributes.label_errors}</li>
                )
              })}
            </ol>
            :
            <strong>No error details found for orders</strong>
          }

      </Popover.Content>
    </Popover>
    )
  }

  getFailedOrdersForPopover(failed_order_numbers){
    this.props.getOrdersByNumber(failed_order_numbers)
  }


  render() {
    if (typeof this.state.info === "undefined") {
      return null;
    } else {
      const batch = this.props.batchInfo;
      const id = this.props.batchId;
      const checked = this.state.checked;
      const label = this.state.label;
      const labelData = (batch.label != null ? batch.label : "");
      const picklist_url = (batch.picklist_url != null ? batch.picklist_url : "");
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const nDate = new Date(batch.created_at)
      const batchDate = nDate.toLocaleString("en-US")
      const failed_order_count = batch.failed_order_numbers ? batch.failed_order_numbers.length : 0

      return (
        <tr>
          <td>
            <Form.Check
              label={id}
              type="checkbox"
              checked={checked || this.props.checked}
              id={id.toString()}
              onChange={(event) => this.setChecked(event.target.checked, event)}
            />
          </td>
          <td>
            { batch.orders_count }
          </td>
          <td>
            <Button style={{'fontSize': '14px'}} variant="link" ref={this.myRef} onMouseOver={() => this.setState({show: !this.state.show})} onMouseOut={() => this.setState({show: !this.state.show})}>{ batch.order_numbers[0] }...</Button>
            <Overlay key={"overlay" + id} target={this.myRef.current} show={this.state.show} placement="left">
              {(props) => (
                this.popover(props)
              )}
            </Overlay>
          </td>
          { failed_order_count > 0 ?
            <>
              {this.buildModal(id, "Bla", id, batch.failed_order_numbers, this.props.failedOrders, this.props.shippingIcons, this.props.updateShipstationOrder)}
              <td>
                <Button style={{'fontSize': '14px'}} variant="link" onClick={() => this.toggleModal()} >{ failed_order_count }</Button>
              </td>
            </>
          :
          <td>
            { failed_order_count }
          </td>
          }
          <td>
            { this.batchActionsBtn() }
          </td>
          <td>
            { batchDate }
          </td>
          <td>
            { picklist_url ? <a download={id} href={picklist_url} target="_blank">Download Picklist</a> : '' }
          </td>
          <td>
            { labelData ? <a download={id} href={labelData} target="_blank">Download Order Labels</a> : '' }
          </td>
        </tr>
      );
    }
  }
}
// created_at: "2020-10-19T03:37:31.397Z"
// date_created: null
// id: 2
// label: "https://shipmatix.s3.amazonaws.com/labels/label_batch_10_18_20_11_37.pdf"
// order_ids: ["8994752", "8994848", "8995152", "8995248", "8995470", "8995557"]
// updated_at: "2020-10-19T03:37:31.397Z"
// user_id: 1



// { labelData ? <a download={order.order_number} href={labelData}>Download Order Label</a> : '' }
