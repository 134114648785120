import React, { Component } from 'react';

import AsyncCreatableSelect from 'react-select/async-creatable';

class SkuCreateOption extends Component{
  handleChange = (newValue, actionMeta) => {
    if (actionMeta.action == 'select-option'){
      this.selectActionCallback(newValue)
    } else if (actionMeta.action == 'create-option'){
      this.createActionCallback(newValue)
    } else {
      this.clearActionCallback()
    }
  };

  optionObject = (sku, id, attributes) => { return {value: id, label: sku, attributes: attributes} }

  initialOptionValues(){
    let options_array = this.props.defaultOptions.map(record =>  this.optionObject(record.sku, record.id, record))
    return options_array
  }

  selectActionCallback(newValue){
    let sku = newValue.label
    let id = newValue.value
    let attributes = newValue.attributes
    let object = this.props.defaultOptions.find(record => record.sku == sku)
    this.props.setUpdateAttributes(sku, id, attributes)
    this.props.toggleSkuLimitDelete('select-option')
    this.props.setDeleterecordId(id)
  }

  createActionCallback(newValue){
    let sku = newValue.label
    this.props.setUpdateAttributes(sku, '', {})
    this.props.toggleSkuLimitDelete('create-option')
  }

  clearActionCallback = () => {
    this.props.setUpdateAttributes('', '', {})
    this.props.toggleSkuLimitDelete('clear')
  }

  filterPackagingLimit = (inputValue) => {
    this.props.findSkuPackagingLimit(inputValue)

    return this.props.options.map(record =>
      this.optionObject(record.sku, record.id, record)
    );
  };

  asyncOptions = inputValue =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(this.filterPackagingLimit(inputValue));
      }, 1000);
    });


  render() {
    return(
      <AsyncCreatableSelect
       isClearable
       placeholder="Update or Create..."
       defaultOptions={this.initialOptionValues()}
       onChange={this.handleChange}
       loadOptions={this.asyncOptions}
      >

      </AsyncCreatableSelect>
    );
  }
}

export default SkuCreateOption;
