import { combineReducers } from 'redux'
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import authReducer from './authReducer';
import userReducer from './userReducer';
import { composeWithDevTools } from 'redux-devtools-extension';

const reducers = combineReducers({
  auth: authReducer,
  user: userReducer
})

const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunkMiddleware)));

export default store;
