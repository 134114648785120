import React, { PureComponent } from 'react';
import Pagination from 'react-bootstrap/Pagination'
import PageItem from 'react-bootstrap/PageItem'
import Row from 'react-bootstrap/Row'

export default class Paginator extends PureComponent{
  constructor(props) {
    super(props)
  }

  handleClick = () => {
    let pageNumber = event.target.id
    this.props.getData(pageNumber)
  }

  render() {
    let active = this.props.active
    let items = [];

    let start_page = ((active - 5) <= 1 ? 1 : active - 5)
    let end_page = (active + 5) >= this.props.pages ? this.props.pages : active + 5

    for (let number = start_page; number <= end_page; number++) {
      items.push(
        <Pagination.Item key={number} onClick={this.handleClick} active={number === active} id={number} >{number}</Pagination.Item>
      );
    }

    if (this.props.pages < 2) {
      return (
        <div></div>
      )
    }
      return (
        <Pagination size="sm">
          <Pagination.First onClick={this.handleClick} disabled={active <= 1} id={1} />
          <Pagination.Prev onClick={this.handleClick} disabled={active <= 1} id={active - 1} />
          {items}
          <Pagination.Next onClick={this.handleClick} disabled={active >= this.props.pages} id={active + 1} />
          <Pagination.Last onClick={this.handleClick} disabled={active >= this.props.pages} id={this.props.pages} />
        </Pagination>
      );
    }
  }
