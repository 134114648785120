import React, { PureComponent } from 'react';
import Select from 'react-select';

import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import Loader from 'components/user/Loader.js';
import {
  authedUser, organizeOrders, getOrders,
  getPrintBatches, updateActiveFilters, titleChanged,
  setSortColumn, setQuery, hideAlert
} from 'components/authActions.js';

import { switchElectron } from 'components/userActions.js'
import {
  getRates, generateLabels, getNewOrders,
  voidLabel, bulkVoidLabel, printPackingSlip, printOrderLabel,
  exportCsv, updatedSelectedRate, assignToBatch,
  deleteBatch, getOrdersByNumber, removeOrderFromBatch,
  updateShipstationOrder, getSingleOrder
} from 'components/shippingActions.js';

import { markForPrint, generatePicklist } from 'components/printingActions.js';
import Order from './Order.js';
import Batch from './Batch.js';
import Filters from './Filters.js';
import { Search } from './Search.js';
import consumer from 'channels/consumer.js';
import Paginator from './Paginator.js'
import BatchButton from './BatchButton.js'
import ToastsContainer from '../Toasts/ToastsContainer.js'
import NotificationContainer from '../Notifications/NotificationContainer.js';
// Bootstrap start
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';
import Row from 'react-bootstrap/Row';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TabContainer from 'react-bootstrap/TabContainer';
import TabPane from 'react-bootstrap/TabPane';
import TabContent from 'react-bootstrap/TabPane';
import Table from 'react-bootstrap/Table';
// Bootstrap end

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAmazon, faEbay, faUsps, faUps, faFedex, faDhl } from '@fortawesome/free-brands-svg-icons';
import { faCog, faHome, faSync, faChevronLeft, faShippingFast, faBoxOpen, faTruck } from '@fortawesome/free-solid-svg-icons';


class OrderContainer extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedOrderNumbers: [],
      selectedOrderIds: [],
      allUnprocessedSelected: false,
      allOrderSelected: false,
      allProcessedSelected: false,
      allPrintedSelected: false,
      inProcess: [],
      activeTab: "orders",
      disableControls: false,
      selectedStores: [],
      selectedOrderTags: [],
      alerts: [],
      notification_alerts: [],
      titleChanged: false,
      showModel: false,
      batchId: 0, // Temp implementation for notification system
      modelTitle: "",
      modelBody: ""
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.messages = this.messages.bind(this);
  }

  componentDidMount() {
    this.props.authedUser()
    this.props.getOrders(this.props.catalog.unprocessedOrders.pageNumber, "unprocessedOrders")
    this.props.getOrders(this.props.catalog.processedOrders.pageNumber, "processedOrders")
    // this.props.getOrders(this.props.catalog.printedOrders.pageNumber, "printedOrders")
    this.props.getPrintBatches()

    this.onElectron()
    this.orderUpdateListener()
    this.notifcationListener()
  }

  createToast = (img, title, body) => {
    let alerts = this.state.alerts
    this.setState({
      alerts: [...alerts, { img: img, title: title, body: body, key: Math.floor(Math.random() * Math.floor(1000)) }]
    })
  }

  createNotificationToast = (title, body) => {
    let alerts = this.state.notification_alerts
    this.setState({
      notification_alerts: [...alerts, { title: title, body: body, key: Math.floor(Math.random() * Math.floor(1000)) }]
    })
  }

  destroyToast = (key, timer = "") => {
    let alerts = this.state.alerts
    let newAlerts = alerts.filter(obj => {
      return obj.key !== key;
    });

    this.setState({
      alerts: newAlerts
    })
  }

  messages = () => {
    if (this.props.showAlert) {
      return (
        <Alert variant="info" className="mt-2 mb-2 text-uppercase" onClose={() => this.props.hideAlert()} dismissible>
          {this.props.error_message || this.props.success_message}
        </Alert>
      )
    } else {
      return ""
    }
  }

  orderUpdateListener = () => {
    let token = localStorage.getItem("token");
    let getOrders = this.props.getOrders;
    let page_number = this.props.pageNumber;
    let catalog = this.props.catalog;
    const resetFilters = this.resetFilters;
    const refreshOrders = this.refreshOrders;
    const buildModel = this.buildModel;

    consumer.subscriptions.create({
      channel: "OrdersToPrintChannel",
      user_id: this.props.user_id,
      token: token
    }, {
      connected() {
        console.log("Connected to orders channel")
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        console.log("Disconnected from orders channel")
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        if (data.status == 1) {
          // resetFilters()
          // console.log("Order rates processed ", data)
          refreshOrders(data.errors)
        } else if (data.status == 2) {
          // console.log("New Orders downloaded", data)
          // resetFilters()
          refreshOrders(data.errors)
        } else if (data.status == 3) {
          // console.log("Labels Generated", data)
          refreshOrders(data.errors)
        } else if (data.status == 4) {
          // console.log("Print Batch Generated", data)
          refreshOrders(data.errors)
        } else {
          // console.log("unknown data: " + data)
        }
        // if (onElectron) {
        //   data.orders.map(o => {
        //     printLabel(o.shipping_label)
        //   })}
      }
    });
  }

  notifcationListener = () => {
    let token = localStorage.getItem("token");
    const postNotification = this.postNotificationOnDashboard;
    const refreshOrders = this.refreshOrders;

    consumer.subscriptions.create({
      channel: "NotificationsChannel",
      user_id: this.props.user_id,
      token: token
    }, {
      connected() {
        console.log("Connected to notifications channel")
      },

      disconnected() {
        console.log("Disconnected from notifications channel")
      },

      received(data) {
        postNotification(data.title, data.message)
        refreshOrders()
      }
    });
  }

  postNotificationOnDashboard = (title = "New Notification", message) => {
    this.createNotificationToast(title, message)
  }


  refreshOrders = (more_info = "") => {
    const catalog = this.props.catalog;
    this.props.authedUser()
    this.props.getOrders(catalog.unprocessedOrders.pageNumber, "unprocessedOrders", this.props.activeFilters)
    this.props.getOrders(catalog.processedOrders.pageNumber, "processedOrders", this.props.activeFilters)
    // this.props.getOrders(catalog.printedOrders.pageNumber, "printedOrders", this.props.activeFilters)
    this.props.getPrintBatches()
    this.clearInProcessOrders()
    this.createToast("", "Order Info", "Orders Refreshed. " + more_info)
  }


  refreshJustOrders = () => {
    const catalog = this.props.catalog;

    this.props.getOrders(catalog.unprocessedOrders.pageNumber, "unprocessedOrders", this.props.activeFilters)
    this.props.getOrders(catalog.processedOrders.pageNumber, "processedOrders", this.props.activeFilters)
  }

  onSearch(query, tab) {
    const catalog = this.props.catalog;

    this.props.setQuery(query)
    this.props.getOrders(catalog.unprocessedOrders.pageNumber, tab, this.props.activeFilters)
  }

  copyToClipboard = (text) => {
    let textArea = document.createElement("textarea");

    // Place in the top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of the white box if rendered for any reason.
    textArea.style.background = 'transparent';

    textArea.value = text;

    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    try {
      let successful = document.execCommand('copy');
      let msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copying text command was ' + msg);
    } catch (err) {
      console.log('Oops, unable to copy');
    }

    document.body.removeChild(textArea);
  }

  setSortColumn(tab, column) {
    const catalog = this.props.catalog;
    const tabs = { "Orders": "unprocessedOrders", "Shipments": "processedOrders" }
    const tabName = tabs[tab]

    this.props.setSortColumn(tab, column)
    this.props.getOrders(catalog[tabName].pageNumber, tabName, this.props.activeFilters)
  }

  onElectron() {
    var userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.indexOf(' electron/') > -1) {
      this.props.switchElectron()
      localStorage.setItem("electron", true)
      console.log("On Electron")
    }
  }

  resetFilters() {
    this.setState({
      allUnprocessedSelected: false,
      allProcessedSelected: false,
      allPrintedSelected: false,
      selectedOrderNumbers: [],
      selectedOrderIds: []
    })
    this.props.updateActiveFilters([])
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.id;

    // Select all orders in between the two selected orders if shift key is pressed while clicking the checkbox
    // it should not be index based, it should be order number based on the current order list
    if (event.shiftKey && this.state.selectedOrderNumbers.length == 1) {
      let orderStrings = []
      switch (this.state.activeTab) {
        case "orders":
          orderStrings = this.props.unprocessedOrders.map(o => o.attributes.order_id.toString());
          break;
        case "shipments":
          orderStrings = this.props.processedOrders.map(o => o.attributes.order_id.toString());
          break;
      }

      // Get the index of the first selected order
      const firstSelectedOrderIndex = orderStrings.indexOf(this.state.selectedOrderNumbers[0]);
      // Get the index of the last selected order
      const lastSelectedOrderIndex = orderStrings.indexOf(value);
      // Get the order numbers between the first and last selected orders
      const orderNumbers = orderStrings.slice(Math.min(firstSelectedOrderIndex, lastSelectedOrderIndex), Math.max(firstSelectedOrderIndex, lastSelectedOrderIndex) + 1);

      // Set the state to the order numbers between the first and last selected orders
      this.setState({
        selectedOrderNumbers: orderNumbers.map(String)
      })
      return;
    }

    if (target.checked) {
      const selectedOrderNumbers = this.state.selectedOrderNumbers.concat(value);
      this.setState({
        selectedOrderNumbers: selectedOrderNumbers
      })
    } else {
      const selectedOrderNumbers = this.state.selectedOrderNumbers.filter(item => item !== value);
      this.setState({
        selectedOrderNumbers: selectedOrderNumbers
      })
    }
  }

  selectAll(e) {
    this.setState({
      selectedOrderNumbers: []
    })

    switch (e.target.id) {
      case "allorders":
        this.setState({
          allOrderSelected: !this.state.allOrderSelected,
          selectedOrderNumbers: !this.state.allOrderSelected ? this.props.allUserOrderIds.map(String) : []
        })
        break;
      case "unprocessed":
        this.setState({
          allUnprocessedSelected: !this.state.allUnprocessedSelected,
          selectedOrderNumbers: !this.state.allUnprocessedSelected ? this.props.unprocessedOrders.map(o => o.attributes.order_id.toString()) : []
        })
        break;
      case "processed":
        this.setState({
          allProcessedSelected: !this.state.allProcessedSelected,
          selectedOrderNumbers: !this.state.allProcessedSelected ? this.props.processedOrders.map(o => o.attributes.order_id.toString()) : []
        })
        break;
      case "printed":
        this.setState({
          allPrintedSelected: !this.state.allPrintedSelected,
          selectedOrderNumbers: !this.state.allPrintedSelected ? this.props.printedOrders.map(o => o.attributes.order_id.toString()) : []
        })
        break;
    }
  }

  selectAllBtn(checked, t) {
    return (
      <div className="mt-1">
        <Form.Check
          label={'Select All On This Page'}
          type="checkbox"
          checked={checked}
          id={t}
          onChange={(e) => this.selectAll(e)}
        />
      </div>
    )
  }

  handleSelectAllOrdersInputChanges(is_checked, context) {
    let current_label = 'Select All Orders'
    let label_style = {}
    let selectedOrdersCount = this.state.selectedOrderNumbers.length
    if (is_checked === true && context === false && selectedOrdersCount > 0) {
      current_label = `${selectedOrdersCount} Orders Selected`
      label_style = { 'fontWeight': 'bold' }
      return [current_label, label_style]
    } else {

      this.setState({
        allOrderSelected: false
      })

      return [current_label, label_style]
    }
  }

  selectAllOrdersBtn(checked, t, context) {
    let [current_label, label_style] = this.handleSelectAllOrdersInputChanges(checked, context)
    return (
      <div className="mt-1">
        <Form.Check
          label={current_label}
          type="checkbox"
          style={label_style}
          checked={checked}
          id={t}
          disabled={context}
          onChange={(e) => this.selectAll(e)}
        />
      </div>
    )
  }

  setInProcessOrders = (orderNumbers) => {
    this.setState({
      inProcess: orderNumbers
    })
  }

  clearInProcessOrders = () => {
    this.setState({
      inProcess: []
    })
  }

  getOrderRates = () => {
    let orderNumbers = this.state.selectedOrderNumbers;
    this.clearCheckboxes()
    this.setInProcessOrders(orderNumbers)
    this.props.getRates(orderNumbers);
    this.createToast("", "Order Info", "Order Rates Requested. You will be notified once rates have been fetched.")
  }

  clearCheckboxes = () => {
    this.setState({
      allUnprocessedSelected: false,
      allOrderSelected: false,
      selectedOrderNumbers: []
    })
  }

  generateOrderLabels = () => {
    let orderNumbers = this.state.selectedOrderNumbers;

    this.clearCheckboxes()
    this.setInProcessOrders(orderNumbers)
    this.props.generateLabels(orderNumbers);
    // this.setState({ activeTab: "shipments" })
    this.createToast("", "Order Info", "Orders Labels Requested. You will be notified once labels have been generated.")
  }

  generateLabelsByBatch = (batchId) => {
    this.props.markForPrint(batchId);
    this.createToast("", "Order Info", "Orders Labels Have Been Batched For Print.")
  }

  bulkVoidBatchLabels = (batchId) => {
    this.props.bulkVoidLabel(batchId);
    this.createToast("", "Bulk Void Labels Operation", "Bulk void labels operation started, we'll notify you once it's completed")
  }

  generatePicklist = () => {
    let orderNumbers = this.state.selectedOrderNumbers;
    this.props.generatePicklist(orderNumbers);
  }

  generateLabelsButton = () => {
    return (
      <Button
        style={{ 'fontSize': '12px' }}
        variant="success"
        disabled={this.state.selectedOrderNumbers.length == 0 || (this.props.processing && this.props.processingType == 2)}
        onClick={() => this.generateOrderLabels()}
      >
        {this.props.processing && this.props.processingType == 2 ? 'Batching & Labeling...' : 'Create Batch & Generate Labels'}
      </Button>
    )
  }

  batchAndLabelBtnGroup = () => {
    const batch_ids = this.props.printBatches.map(b => b.id)
    let batchingDisabled = this.state.selectedOrderNumbers.length == 0 || (this.props.processing && this.props.processingType == 6)

    return (
      <Dropdown as={ButtonGroup} size={'md'} style={{ 'fontSize': '12px' }}>
        {this.generateLabelsButton()}

        <Button
          style={{ 'fontSize': '12px' }}
          variant="primary"
          disabled={batchingDisabled}
          onClick={() => this.props.assignToBatch(null, this.state.selectedOrderNumbers)}
        >
          {this.props.processing && this.props.processingType == 2 ? 'Batching...' : 'Create Batch'}
        </Button>

        <Dropdown.Toggle split variant="primary" size={'sm'} disabled={batchingDisabled} id="dropdown-split-basic" />
        <Dropdown.Menu>
          <Dropdown.Item style={{ 'fontSize': '12px' }} eventKey="1" disabled={true}>Assign To Batch:</Dropdown.Item>
          <Dropdown.Divider />
          {batch_ids.map(id => {
            return (
              <Dropdown.Item onClick={() => this.props.assignToBatch(id, this.state.selectedOrderNumbers)} style={{ 'fontSize': '12px' }} id={id} eventKey={id}>{id}</Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  downloadNewOrdersButton() {
    if (this.props.processing && this.props.processingType == 3) {
      return (
        <Loader />
      )
    } else {
      return (
        <Button
          style={{ 'fontSize': '12px' }}
          className="p-2 m-2"
          variant="primary"
          onClick={() => this.props.getNewOrders()}
        >
          Refresh Orders
        </Button>
      )
    }
  }

  getRatesButton() {
    return (
      <Button
        style={{ 'fontSize': '12px' }}
        className="m-2"
        disabled={this.state.selectedOrderNumbers.length == 0 || (this.props.processing && this.props.processingType == 1)}
        onClick={() => this.getOrderRates()}
      >
        {this.props.processing && this.props.processingType == 1 ? 'Getting Rates...' : 'Get Rates'}
      </Button>
    )
  }

  generatePicklistButton() {
    return (
      <Button
        style={{ 'fontSize': '12px' }}
        disabled={this.state.selectedOrderNumbers.length == 0 || (this.props.processing && this.props.processingType == 7)}
        onClick={() => this.generatePicklist()}
      >
        {this.props.processing && this.props.processingType == 7 ? 'Generating...' : 'Generate Picklist'}
      </Button>
    )
  }

  exportCsvButton() {
    return (
      <Button
        style={{ 'fontSize': '12px' }}
        onClick={() => this.props.exportCsv(this.state.selectedOrderNumbers)}
        disabled={this.state.selectedOrderNumbers.length == 0 || (this.props.processing && this.props.processingType == 16)}
      >
        {this.props.processing && this.props.processingType == 16 ? 'Exporting...' : 'Export CSV'}
      </Button>
    )
  }

  // {this.props.requesting ? 'Voiding Label…' : 'Void Label'}

  processPdfButton() {
    return (
      <Button
        style={{ 'fontSize': '12px' }}
        className="p-2 m-2"
        onClick={() => this.handleOnClickProcessed()}
      >
        {this.props.processing && this.props.processingType == 2 ? 'Printing…' : 'Print Selected Orders'}
      </Button>
    )
  }

  showLoader() {
    if (this.props.requesting) {
      return (
        <Loader />
      )
    }
  }

  paginatorElm(type) {
    if (this.props.requesting) {
      return <div></div>
    } else {
      return <Paginator active={this.props.catalog[`${type}`].pageNumber} pages={this.props.catalog[`${type}`].pages} getOrders={this.props.getOrders} orderType={`${type}`} />
    }
  }

  storeSelected(newValue, actionMeta, pageNumber, tab) {
    this.setState({
      selectedStores: newValue
    }, () => {
      this.props.updateActiveFilters(this.props.activeFilters, this.state.selectedStores, this.state.selectedOrderTags);
      this.props.getOrders(pageNumber, tab, this.props.activeFilters);
    });
  }

  tagSelected(newValue, actionMeta, pageNumber, tab) {
    this.setState({
      selectedOrderTags: newValue
    }, () => {
      this.props.updateActiveFilters(this.props.activeFilters, this.state.selectedStores, this.state.selectedOrderTags);
      this.props.getOrders(pageNumber, tab, this.props.activeFilters);
    });
  }

  tabTitle = (status, title, count, changed = true) => {
    const hasNew = localStorage.getItem(status + "New")

    if (hasNew == "true") {
      return (
        <div>
          {title + " - " + count}
          <span className="ml-2"></span>
          <Badge variant="info">New</Badge>
        </div>
      )
    } else {
      return (
        <div>
          {title + " - " + count}
        </div>
      )
    }
  }

  onSelectTab = (k) => {
    const storageKeys = { "orders": "unprocessedOrdersNew", "shipments": "processedOrdersNew", "batches": "printBatchesNew" }
    localStorage.setItem(storageKeys[k], false)
    this.props.titleChanged(true)
    this.setState({ activeTab: k, selectedOrderNumbers: [] })
  }

  sortDirectionIcon = (direction, column, tab) => {
    const sortIcons = { "asc": '⬆️', "dsc": '⬇️' }

    if ((this.props.sortOrdersColumn == column || this.props.sortShipmentsColumn == column) && (tab == "orders" || tab == "shipments")) {
      return sortIcons[direction]
    } else {
      return undefined;
    }
  }

  render() {
    if (!this.props.loggedIn) {
      return <Redirect to="/users/login" />
    }
    const orderLabels = this.props.orderLabels;
    const shippingIcons = {
      "stamps_com": [faUsps, 'darkblue'],
      "ups": [faUps, '#301504'],
      "ups_walleted": [faUps, '301504'],
      "fedex": [faFedex, 'darkblue'],
      "fedex_intl_mailservice": [faFedex, 'darkblue'],
      "dhl_global_mail": [faDhl, 'red'],
      "rr_donnelley": [faTruck, 'darkcyan'],
      "globegistics": [faTruck, 'darkcyan'],
      "amazon_shipping": [faAmazon, 'orange'],
      null: [faBoxOpen, 'lightgray']
    }


    return (
      <Container fluid className="p-1 bg-light" style={{ fontSize: 13 }}>
        <ToastsContainer alerts={this.state.alerts} destroyToast={this.destroyToast} />
        <NotificationContainer notification_alerts={this.state.notification_alerts} destroyToast={this.destroyToast} />

        <Row md={1}>
          <Col md={12} className="pt-3">
            <Tabs defaultActiveKey="orders" onSelect={(k) => this.onSelectTab(k)} activeKey={this.state.activeTab} id="order-dashboard">
              <Tab eventKey="orders" title={this.tabTitle("unprocessedOrders", "Orders", this.props.catalog.unprocessedOrders.count, this.props.titleChanged)}>
                <TabPane className="p-3">
                  <Row>
                    <Col md={2} className="p-3 m-1 rounded bg-white text-dark" style={{ 'minHeight': 'calc(100vh - 190px)', 'maxHeight': 'calc(100vh - 190px)', 'overflowY': 'hidden' }}>
                      <Search onSearch={this.onSearch} tab={"unprocessedOrders"} />

                      Stores
                      <Select
                        isMulti
                        name="stores"
                        options={this.props.stores}
                        value={this.state.selectedStores}
                        onChange={(newValue, actionMeta) => this.storeSelected(newValue, actionMeta, this.props.catalog.unprocessedOrders.pageNumber, "unprocessedOrders")}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        size="sm"
                      />
                      <div className="mt-3">Order Tags</div>
                      <Select
                        isMulti
                        name="order_tags"
                        options={this.props.orderTags}
                        value={this.state.selectedOrderTags}
                        onChange={(newValue, actionMeta) => this.tagSelected(newValue, actionMeta, this.props.catalog.unprocessedOrders.pageNumber, "unprocessedOrders")}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        size="sm"
                      />
                      <hr></hr>
                      <Filters
                        selectedStores={this.state.selectedStores}
                        selectedOrderTags={this.state.selectedOrderTags}
                        updateActiveFilters={this.props.updateActiveFilters}
                        filterOptions={this.props.filterOptions}
                        activeFilters={this.props.activeFilters}
                        orderType={"unprocessedOrders"}
                        getOrders={this.props.getOrders}
                        pageNumber={this.props.catalog.unprocessedOrders.pageNumber}
                        excludeFilter={["printedOrders"]}
                      />
                      <hr></hr>
                      <div className="mt-2">Orders Selected:</div>
                      {(this.state.selectedOrderNumbers.length > 0 ? this.state.selectedOrderNumbers.length : 0)}
                    </Col>
                    <Col className="col p-3 m-1 bg-white rounded" style={{ 'minHeight': 'calc(100vh - 190px)', 'maxHeight': 'calc(100vh - 190px)', 'overflowY': 'hidden' }}>
                      <Row className="pt-1 pr-1 pl-1 pb-0 m-1 bg-light text-dark">
                        <Col sm="auto" className="my-auto mx-0 pl-0">
                          {this.selectAllBtn(this.state.allUnprocessedSelected, "unprocessed")}
                          {this.selectAllOrdersBtn(this.state.allOrderSelected, "allorders", this.state.allUnprocessedSelected)}
                        </Col>
                        <Col sm="auto" className="my-auto mx-0 px-0">
                          {this.getRatesButton()}
                        </Col>
                        <Col sm="auto" className="my-auto mx-0 px-0">
                          {this.batchAndLabelBtnGroup()}
                        </Col>
                        <Col sm="auto" className="my-auto mx-0 px-0">
                          <ButtonGroup size="md" className="m-2">
                            {this.generatePicklistButton()}
                            {this.exportCsvButton()}
                          </ButtonGroup>

                          {(this.props.labels ? <a href={this.props.labels} target="_blank">Print Picklist</a> : "")}
                        </Col>
                        <Col sm="auto" className="mt-3 ml-auto">
                          {this.paginatorElm("unprocessedOrders")}
                        </Col>
                      </Row>
                      <Row style={{ 'maxHeight': 'calc(100vh - 240px)', 'overflowY': 'auto' }} className="p-3">
                        <Table hover size="sm">
                          <thead >
                            <tr>
                              <th onClick={(e) => this.setSortColumn("Orders", "Number")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px' }}>
                                {this.props.sortOrdersColumn == "Number" ? <u>Number</u> : "Number"} {this.sortDirectionIcon(this.props.sortOrdersColumnDirection, "Number", "orders")}
                              </th>
                              <th style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px' }}>
                                Batch
                              </th>
                              <th
                                onClick={(e) => this.setSortColumn("Orders", "Skus")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px', 'width': '95px' }}>
                                {this.props.sortOrdersColumn == "Skus" ? <u>Skus</u> : "Skus"} {this.sortDirectionIcon(this.props.sortOrdersColumnDirection, "Skus", "orders")}
                              </th>
                              <th onClick={(e) => this.setSortColumn("Orders", "Tags")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px' }}>
                                {this.props.sortOrdersColumn == "Tags" ? <u>Tags</u> : "Tags"} {this.sortDirectionIcon(this.props.sortOrdersColumnDirection, "Tags", "orders")}
                              </th>
                              <th onClick={(e) => this.setSortColumn("Orders", "QTY")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px' }}>
                                {this.props.sortOrdersColumn == "QTY" ? <u>QTY</u> : "QTY"} {this.sortDirectionIcon(this.props.sortOrdersColumnDirection, "QTY", "orders")}
                              </th>
                              <th onClick={(e) => this.setSortColumn("Orders", "Weight")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px' }}>
                                {this.props.sortOrdersColumn == "Weight" ? <u>Weight</u> : "Weight"} {this.sortDirectionIcon(this.props.sortOrdersColumnDirection, "Weight", "orders")}
                              </th>
                              <th onClick={(e) => this.setSortColumn("Orders", "Status")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px' }}>
                                {this.props.sortOrdersColumn == "Status" ? <u>Status</u> : "Status"} {this.sortDirectionIcon(this.props.sortOrdersColumnDirection, "Status", "orders")}
                              </th>
                              <th onClick={(e) => this.setSortColumn("Orders", "Date")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px' }}>
                                {this.props.sortOrdersColumn == "Date" ? <u>Date</u> : "Date"} {this.sortDirectionIcon(this.props.sortOrdersColumnDirection, "Date", "orders")}
                              </th>
                              <th onClick={(e) => this.setSortColumn("Orders", "Carrier")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px' }}>
                                {this.props.sortOrdersColumn == "Carrier" ? <u>Carrier</u> : "Carrier"} {this.sortDirectionIcon(this.props.sortOrdersColumnDirection, "Carrier", "orders")}
                              </th>
                              <th
                                onClick={(e) => this.setSortColumn("Orders", "Service")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px', 'width': '165px' }}>
                                {this.props.sortOrdersColumn == "Service" ? <u>Service</u> : "Service"} {this.sortDirectionIcon(this.props.sortOrdersColumnDirection, "Service", "orders")}
                              </th>
                              <th onClick={(e) => this.setSortColumn("Orders", "Shipping")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px' }}>
                                {this.props.sortOrdersColumn == "Shipping" ? <u>Shipping</u> : "Shipping"} {this.sortDirectionIcon(this.props.sortOrdersColumnDirection, "Shipping", "orders")}
                              </th>
                              <th onClick={(e) => this.setSortColumn("Orders", "Rate")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px' }}>
                                {this.props.sortOrdersColumn == "Rate" ? <u>Rate</u> : "Rate"} {this.sortDirectionIcon(this.props.sortOrdersColumnDirection, "Rate", "orders")}
                              </th>
                              <th onClick={(e) => this.setSortColumn("Orders", "Domestic")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px' }}>
                                {this.props.sortOrdersColumn == "Domestic" ? <u>Domestic</u> : "Domestic"} {this.sortDirectionIcon(this.props.sortOrdersColumnDirection, "Domestic", "orders")}
                              </th>
                              <th onClick={(e) => this.setSortColumn("Orders", "Country")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px' }}>
                                {this.props.sortOrdersColumn == "Country" ? <u>Country</u> : "Country"} {this.sortDirectionIcon(this.props.sortOrdersColumnDirection, "Country", "orders")}
                              </th>
                            </tr>
                          </thead>
                          <tbody key="orders_table">
                            {this.props.unprocessedOrders.map(order => {
                              return (
                                <Order
                                  shippingIcons={shippingIcons}
                                  key={order.attributes.order_id}
                                  refreshJustOrders={this.refreshJustOrders}
                                  updatedSelectedRate={this.props.updatedSelectedRate}
                                  processing={this.props.processing}
                                  processingType={this.props.processingType}
                                  orderInfo={order.attributes}
                                  handleInputChange={this.handleInputChange}
                                  checked={this.state.allUnprocessedSelected}
                                  ordersInProcess={this.state.inProcess}
                                  inProcess={this.state.inProcess}
                                  stores={this.props.stores}
                                  orderTagsComplete={this.props.orderTagsComplete}
                                  selectedOrderNumbers={this.state.selectedOrderNumbers}
                                  updateShipstationOrder={this.props.updateShipstationOrder}
                                  getSingleOrder={this.props.getSingleOrder}
                                  messages={this.messages}
                                  copyToClipboard={this.copyToClipboard}
                                  removeOrderFromBatch={this.props.removeOrderFromBatch}
                                />
                              )
                            })}
                          </tbody>
                        </Table>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
              </Tab>
              <Tab eventKey="batches" title={this.tabTitle("printBatches", "Batches", this.props.printBatches.length, this.props.titleChanged)}>
                <TabPane className="p-3">
                  <Row>
                    <Col md={2} className="p-3 m-1 bg-white rounded" style={{ 'minHeight': 'calc(100vh - 190px)', 'maxHeight': 'calc(100vh - 190px)', 'overflowY': 'hidden' }}>
                      Filters
                      <hr></hr>

                    </Col>
                    <Col className="col p-3 m-1 bg-white rounded" style={{ 'minHeight': 'calc(100vh - 190px)', 'maxHeight': 'calc(100vh - 190px)', 'overflowY': 'hidden' }}>
                      <Row className="p-1 m-1 bg-light text-dark">
                        <Col sm="auto" className="mt-3">
                          {this.selectAllBtn(this.state.allPrintedSelected, "printed")}
                        </Col>
                        <Col sm="auto" className="mt-3 ml-auto">
                          <p className="">Print Batches Are Automatically Deleted After 7 Days.</p>
                        </Col>

                      </Row>
                      <Row style={{ 'maxHeight': 'calc(100vh - 210px)' }} className="p-3">
                        <Table hover size="sm">
                          <thead >
                            <tr>
                              <th>Batch Number</th>
                              <th>Orders</th>
                              <th>Order Id's</th>
                              <th>Order Issues</th>
                              <th>Actions</th>
                              <th>Date</th>
                              <th>Pick List</th>
                              <th>Shipping Lables</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.printBatches.map(batch => {
                              return (
                                <Batch
                                  key={batch.id}
                                  batchId={batch.id}
                                  batchInfo={batch.attributes}
                                  handleInputChange={this.handleInputChange}
                                  showModel={this.showModel}
                                  checked={this.state.allPrintedSelected}
                                  getOrdersByNumber={this.props.getOrdersByNumber}
                                  removeOrderFromBatch={this.props.removeOrderFromBatch}
                                  failedOrders={this.props.failedOrders}
                                  generateLabels={this.props.generateLabels}
                                  updateShipstationOrder={this.props.updateShipstationOrder}
                                  getSingleOrder={this.props.getSingleOrder}
                                  messages={this.messages}
                                  shippingIcons={shippingIcons}
                                  processing={this.props.processing}
                                  processingType={this.props.processingType}
                                  generateLabelsByBatch={this.generateLabelsByBatch}
                                  bulkVoidBatchLabels={this.bulkVoidBatchLabels}
                                  exportCsv={this.props.exportCsv}
                                  deleteBatch={this.props.deleteBatch}
                                  generatePicklist={this.props.generatePicklist}
                                  orderIds={this.props.processedOrders.map(o => {
                                    if (o.attributes.print_batch_id == batch.id) {
                                      return o.attributes.order_id
                                    }
                                  })}
                                />
                              )
                            })}
                          </tbody>
                        </Table>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
              </Tab>
              <Tab eventKey="shipments" title={this.tabTitle("processedOrders", "Shipments", this.props.catalog.processedOrders.count, this.props.titleChanged)} >
                <TabPane className="p-3">
                  <Row>
                    <Col md={2} className="p-3 m-1 bg-white rounded" style={{ 'minHeight': 'calc(100vh - 190px)', 'maxHeight': 'calc(100vh - 190px)', 'overflowY': 'hidden' }}>
                      <Search onSearch={this.onSearch} tab={"processedOrders"} />
                      Stores
                      <Select
                        isMulti
                        name="stores"
                        options={this.props.stores}
                        value={this.state.selectedStores}
                        onChange={(newValue, actionMeta) => this.storeSelected(newValue, actionMeta, this.props.catalog.processedOrders.pageNumber, "processedOrders")}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        size="sm"
                      />
                      <div className="mt-2">Order Tags</div>
                      <Select
                        isMulti
                        name="order_tags"
                        options={this.props.orderTags}
                        value={this.state.selectedOrderTags}
                        onChange={(newValue, actionMeta) => this.tagSelected(newValue, actionMeta, this.props.catalog.processedOrders.pageNumber, "processedOrders")}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        size="sm"
                      />
                      <hr></hr>
                      <Filters
                        selectedStores={this.state.selectedStores}
                        updateActiveFilters={this.props.updateActiveFilters}
                        filterOptions={this.props.filterOptions}
                        activeFilters={this.props.activeFilters}
                        orderType={"processedOrders"}
                        getOrders={this.props.getOrders}
                        pageNumber={this.props.catalog.processedOrders.pageNumber}
                        excludeFilter={["unRatedOrders", "ratedOrders"]}
                      />
                      <hr></hr>
                      <div className="mt-2">Orders Selected:</div>
                      {(this.state.selectedOrderNumbers.length > 0 ? this.state.selectedOrderNumbers.length : 0)}
                    </Col>
                    <Col className="col p-3 m-1 bg-white rounded" style={{ 'minHeight': 'calc(100vh - 190px)', 'maxHeight': 'calc(100vh - 190px)', 'overflowY': 'hidden' }}>
                      <Row className="p-1 m-1 bg-light text-dark">
                        <Col sm="auto" className="my-auto mx-0 pl-0">
                          {this.selectAllBtn(this.state.allProcessedSelected, "processed")}
                        </Col>
                        <Col sm="auto" className="my-auto mx-0 px-0">
                          <ButtonGroup size="md" className="m-2">
                            {this.generatePicklistButton()}
                            {this.exportCsvButton()}
                          </ButtonGroup>
                          {(this.props.labels ? <a href={this.props.labels} target="_blank">Print Picklist</a> : "")}
                        </Col>
                        <Col sm="auto" className="mt-3 ml-auto">
                          {this.paginatorElm("processedOrders")}
                        </Col>
                      </Row>
                      <Row style={{ 'maxHeight': 'calc(100vh - 210px)', 'overflowY': 'auto' }} className="p-3" >
                        <Table hover size="sm" key={'shipments_table'}>
                          <thead>
                            <tr key={'shipments_head_row'}>
                              <th onClick={(e) => this.setSortColumn("Shipments", "Number")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px' }}>
                                {this.props.sortShipmentsColumn == "Number" ? <u>Number</u> : "Number"} {this.sortDirectionIcon(this.props.sortShipmentsColumnDirection, "Number", "shipments")}
                              </th>
                              <th style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px' }}>
                                Batch
                              </th>
                              <th
                                onClick={(e) => this.setSortColumn("Shipments", "Skus")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px', 'width': '50px' }}>
                                {this.props.sortShipmentsColumn == "Skus" ? <u>Skus</u> : "Skus"} {this.sortDirectionIcon(this.props.sortShipmentsColumnDirection, "Skus", "shipments")}
                              </th>
                              <th onClick={(e) => this.setSortColumn("Shipments", "Tags")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px' }}>
                                {this.props.sortShipmentsColumn == "Tags" ? <u>Tags</u> : "Tags"} {this.sortDirectionIcon(this.props.sortShipmentsColumnDirection, "Tags", "shipments")}
                              </th>
                              <th style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px' }}>
                                Tracking Number
                              </th>
                              <th style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px', 'width': '150px' }}>
                                Label Actions
                              </th>

                              <th onClick={(e) => this.setSortColumn("Shipments", "Status")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px' }}>
                                {this.props.sortShipmentsColumn == "Status" ? <u>Status</u> : "Status"} {this.sortDirectionIcon(this.props.sortShipmentsColumnDirection, "Status", "shipments")}
                              </th>
                              <th
                                onClick={(e) => this.setSortColumn("Shipments", "Date")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px', 'width': '80px' }}>
                                {this.props.sortShipmentsColumn == "Date" ? <u>Date</u> : "Date"} {this.sortDirectionIcon(this.props.sortShipmentsColumnDirection, "Date", "shipments")}
                              </th>
                              <th onClick={(e) => this.setSortColumn("Shipments", "Carrier")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px' }}>
                                {this.props.sortShipmentsColumn == "Carrier" ? <u>Carrier</u> : "Carrier"} {this.sortDirectionIcon(this.props.sortShipmentsColumnDirection, "Carrier", "shipments")}
                              </th>
                              <th
                                onClick={(e) => this.setSortColumn("Shipments", "Service")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px', 'width': '130px' }}>
                                {this.props.sortShipmentsColumn == "Service" ? <u>Service</u> : "Service"} {this.sortDirectionIcon(this.props.sortShipmentsColumnDirection, "Service", "shipments")}
                              </th>
                              <th onClick={(e) => this.setSortColumn("Shipments", "Shipping")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px' }}>
                                {this.props.sortShipmentsColumn == "Shipping" ? <u>Shipping</u> : "Shipping"} {this.sortDirectionIcon(this.props.sortShipmentsColumnDirection, "Shipping", "shipments")}
                              </th>
                              <th onClick={(e) => this.setSortColumn("Shipments", "Rate")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px' }}>
                                {this.props.sortShipmentsColumn == "Rate" ? <u>Rate</u> : "Rate"} {this.sortDirectionIcon(this.props.sortShipmentsColumnDirection, "Rate", "shipments")}
                              </th>
                              <th onClick={(e) => this.setSortColumn("Shipments", "Domestic")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px' }}>
                                {this.props.sortShipmentsColumn == "Domestic" ? <u>Domestic</u> : "Domestic"} {this.sortDirectionIcon(this.props.sortShipmentsColumnDirection, "Domestic", "shipments")}
                              </th>
                              <th onClick={(e) => this.setSortColumn("Shipments", "Country")} style={{ 'position': 'sticky', 'boxShadow': '0 1px 2px 0px rgba(0, 0, 0, 0.4)', 'top': '0', 'backgroundColor': 'white', 'zIndex': '10', 'paddingLeft': '5px' }}>
                                {this.props.sortShipmentsColumn == "Country" ? <u>Country</u> : "Country"} {this.sortDirectionIcon(this.props.sortShipmentsColumnDirection, "Country", "shipments")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.processedOrders.map(order => {
                              const orderLabel = orderLabels.find(label => label.id == order.order_number);

                              return (
                                <Order
                                  shippingIcons={shippingIcons}
                                  key={order.attributes.order_id}
                                  refreshJustOrders={this.refreshJustOrders}
                                  updatedSelectedRate={this.props.updatedSelectedRate}
                                  processing={this.props.processing}
                                  processingType={this.props.processingType}
                                  orderInfo={order.attributes}
                                  handleInputChange={this.handleInputChange}
                                  label={orderLabel ? orderLabel.label : ""}
                                  voidLabel={this.props.voidLabel}
                                  printPackingSlip={this.props.printPackingSlip}
                                  printOrderLabel={this.props.printOrderLabel}
                                  checked={this.state.allProcessedSelected}
                                  ordersInProcess={this.state.inProcess}
                                  inProcess={false} stores={this.props.stores}
                                  selectedOrderNumbers={this.state.selectedOrderNumbers}
                                  orderTagsComplete={this.props.orderTagsComplete}
                                  messages={this.messages}
                                  copyToClipboard={this.copyToClipboard}
                                  removeOrderFromBatch={this.props.removeOrderFromBatch}
                                />
                              )
                            })}
                          </tbody>
                        </Table>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    user_id: state.auth.currentUser.id,
    loggedIn: state.auth.loggedIn,
    processing: state.auth.processing,
    processingType: state.auth.processingType,
    requesting: state.auth.requesting,
    orders: state.auth.orders || [],
    stores: state.auth.stores || [],
    orderTags: state.auth.orderTags || [],
    orderTagsComplete: state.auth.orderTagsComplete || [],
    pageNumber: state.auth.pageNumber,
    pages: state.auth.pages,
    catalog: state.auth.catalog,
    activeFilters: state.auth.activeFilters,
    filterOptions: state.auth.filterOptions,
    unprocessedOrders: state.auth.unprocessedOrders,
    allUserOrderIds: state.auth.allUserOrderIds,
    processedOrders: state.auth.processedOrders,
    printedOrders: state.auth.printedOrders,
    printBatches: state.auth.printBatches,
    labels: state.user.combinedLabels,
    orderLabels: state.user.orderLabels,
    onElectron: state.user.onElectron,
    titleChanged: state.auth.titleChanged,
    sortOrdersColumn: state.auth.sortOrdersColumn,
    sortOrdersColumnDirection: state.auth.sortOrdersColumnDirection,
    sortShipmentsColumn: state.auth.sortShipmentsColumn,
    sortShipmentsColumnDirection: state.auth.sortShipmentsColumnDirection,
    failedOrders: state.auth.failedOrders,
    showAlert: state.auth.showAlert,
    error_message: state.auth.error,
    success_message: state.auth.success
  }
}

const mapDispatch = dispatch => {
  return {
    authedUser: () => { dispatch(authedUser()) },
    getOrders: (pageNumber, type, activeFilters) => { dispatch(getOrders(pageNumber, type, activeFilters)) },
    getPrintBatches: () => { dispatch(getPrintBatches()) },
    organizeOrders: () => { dispatch(organizeOrders()) },
    getRates: (orderNumbers) => { dispatch(getRates(orderNumbers)) },
    generateLabels: (orderNumbers, batchId) => { dispatch(generateLabels(orderNumbers, batchId)) },
    markForPrint: (batchId) => { dispatch(markForPrint(batchId)) },
    generatePicklist: (orderNumbers) => { dispatch(generatePicklist(orderNumbers)) },
    printOrderLabel: (orderNumber) => { dispatch(printOrderLabel(orderNumber)) },
    getNewOrders: () => { dispatch(getNewOrders()) },
    switchElectron: () => { dispatch(switchElectron()) },
    updateActiveFilters: (activeFilters, selectedStores, selectedOrderTags) => { dispatch(updateActiveFilters(activeFilters, selectedStores, selectedOrderTags)) },
    titleChanged: (changed) => { dispatch(titleChanged(changed)) },
    setSortColumn: (tab, column) => { dispatch(setSortColumn(tab, column)) },
    voidLabel: (orderNumber) => { dispatch(voidLabel(orderNumber)) },
    bulkVoidLabel: (batchId) => { dispatch(bulkVoidLabel(batchId)) },
    printPackingSlip: (orderNumber) => { dispatch(printPackingSlip(orderNumber)) },
    exportCsv: (orderIds, batch_csv) => { dispatch(exportCsv(orderIds, batch_csv)) },
    updatedSelectedRate: (order_number, rate_id) => { dispatch(updatedSelectedRate(order_number, rate_id)) },
    assignToBatch: (batchId, orderNumbers) => { dispatch(assignToBatch(batchId, orderNumbers)) },
    deleteBatch: (batchId) => { dispatch(deleteBatch(batchId)) },
    setQuery: (query) => { dispatch(setQuery(query)) },
    getOrdersByNumber: (orderNumbers) => { dispatch(getOrdersByNumber(orderNumbers)) },
    removeOrderFromBatch: (orderNumber, batchId) => { dispatch(removeOrderFromBatch(orderNumber, batchId)) },
    updateShipstationOrder: (addressObject) => { dispatch(updateShipstationOrder(addressObject)) },
    getSingleOrder: (orderId) => { dispatch(getSingleOrder(orderId)) },
    hideAlert: () => { dispatch(hideAlert()) },
  }
}

export default connect(mapStateToProps, mapDispatch)(OrderContainer);
