import React, { Component } from 'react';

import CreatableSelect from 'react-select/creatable';
import { countries } from './Data';

class CreateOption extends Component {

  handleChange = (newValue, actionMeta) => {
    let countriesObject = {
      carrier_code: this.props.selectedCarrier,
      countries: newValue || []
    }

    this.props.setValues(countriesObject);
  };

  getValueNames = (values) => {
    let countryNames = []
    values.map(v => {
      countryNames.push(
        {
          value: v,
          label: countries[v]
        }
      )
    })
    return countryNames;
  };

  render() {
    return (
      <CreatableSelect
        className="basic-single p-1 m-1"
        placeholder="Add Or Remove Countries..."
        isMulti
        onChange={this.handleChange}
        options={this.props.options}
        value={this.getValueNames(this.props.values)}
        isDisabled={this.props.activeInput}
      />
    );
  }
}

export default CreateOption;
