import React, { PureComponent } from 'react';

// Bootstrap start
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
// Bootstrap end

class ApiSettings extends PureComponent{
  constructor(props) {
    super(props)

    this.state = {
      key: (!props.keys) ? "" : props.keys.key,
      secret: (!props.keys) ? "" : props.keys.secret
    }
  }

  OnTextChange = e => {
    this.setState({
      [e.target.name]: e.target.value
      })
  }

  OnSubmit = e => {
    e.preventDefault()
    const api_keys = {
      keys: { [this.props.keyName[0]] : this.state }
    }
    this.props.updateApiKeys(api_keys);
  }

  SetHelpText = () => {
    if (this.props.keyName[0] == "shipstation") {
      return (
        <p>
          Follow the instructions at this <a target="_blank" href="https://help.shipstation.com/hc/en-us/articles/360025856212-ShipStation-API">link </a>
          to retrieve your Shipstation API keys. Scroll down to the section labled <strong>"Access the ShipStation API"</strong>.
        </p>
      )
    } else if (this.props.keyName[0] == "shipengine") {
      return(
        <>
          <p>
            Shipengine has only an api key, not secret.
            <br/>
            You can get your API keys from the <a target="_blank" href="https://app.shipengine.com/">ShipEngine </a>dashboard.
            On the <a target="_blank" href="https://app.shipengine.com/#/portal/apimanagement">"API Management" page </a>of your ShipEngine dashboard, you'll see your production API keys.
            Anything you do with a production API key could incur costs, so we don't recommend using these keys for development or testing.
          </p>
        </>
      )
    }
  }

  HandleTestClick = () => {
    this.props.testApiKeys(this.props.keyName[0])
  }

  render() {
    return(
      <Jumbotron className="pt-1 pb-1">
        <Container>
        <h1>{this.props.keyName[1]} API Keys:</h1>
        {this.SetHelpText()}
        <div>
          <Form onSubmit={this.OnSubmit} className="p-3">
            <Form.Group as={Row}>
              {/* this.state.key || this.props.keys.key */}
              <Form.Label htmlFor="key" column sm="3" >API Key</Form.Label>
              <Form.Control id="key" name="key" aria-describedby="key" onChange={this.OnTextChange} value={this.state.key} className="col col-sm-9"/>

              <Col className="col col-sm-3"></Col>
              <Form.Text id="key" muted className="col col-sm-9">
              </Form.Text>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label htmlFor="secret" column sm="3" >Api Secret</Form.Label>
              <Form.Control label="Api Secret" id="secret" name="secret" onChange={this.OnTextChange} value={this.state.secret} className="col col-sm-9"/>

              <Col className="col col-sm-3"></Col>
              <Form.Text id="secret" muted className="col col-sm-9">
              </Form.Text>

              <Button type="submit" name="save_api_keys" className="p-2 pr-3 pl-3 m-2">{!!this.props.keys ? "Update" : "Save"} API Keys</Button>
              <Button name="test_api_keys" onClick={this.HandleTestClick} className="p-2 pr-3 pl-3 m-2" disabled={!this.props.keys || this.props.requesting}> {this.props.requesting ? 'Loading...' : 'Test Keys'}</Button>
            </Form.Group>
          </Form>
        </div>
        </Container>
      </Jumbotron>
      )
  }
}

export default ApiSettings;
