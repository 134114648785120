import React from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export function Search(props) {
  const [value, setValue] = React.useState('');

  const onChange = (event) => {
    const query = event.target.value
    setValue(query)
    props.onSearch(query, props.tab)
  }

  return (
    <div>
      <Form>
        <Form.Label><i>Search: </i> <u>{value}</u></Form.Label>
        <Form.Group>
          <Form.Control size={"sm"} placeholder="Search Orders..." id="search" name="search" onChange={onChange} value={value}/>
          <Row>
            <Col sm="auto" className="mt-0 ml-auto">
              <Button style={{'fontSize': '12px'}} variant="link" value={""} onClick={onChange}>Clear Search</Button>
            </Col>
          </Row>
        </Form.Group>
      </Form>
    </div>
  );
}
