import React, { Component } from 'react';

// Bootstrap start
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Jumbotron from 'react-bootstrap/Jumbotron'
import Select from 'react-select';
import Container from 'react-bootstrap/Container'

// Bootstrap end
import { usStates } from './Data.js';

class AddressForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      id: (!props.address) ? "" : props.address.id,
      firstName: (!props.address) ? "" : props.address.name.split(" ")[0],
      lastName: (!props.address) ? "" : props.address.name.split(" ")[1],
      company: (!props.address) ? "" : props.address.company,
      street1: (!props.address) ? "" : props.address.street1,
      street2: (!props.address) ? "" : props.address.street2,
      city: (!props.address) ? "" : props.address.city,
      state: (!props.address) ? "" : props.address.state,
      postalCode: (!props.address) ? "" : props.address.postal_code,
      country: (!props.address) ? "" : props.address.country,
      phone: (!props.address) ? "" : props.address.phone,
      residential: (!props.address) ? "" : props.address.residential,
      store_id: (!props.address) ? "" : props.address.store_id,
      notes: (!props.address) ? "" : props.address.notes,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.address && prevProps.address.store_id != this.props.address.store_id) {
      this.setState({
        id: this.props.address.id,
        firstName: this.props.address.name.split(" ")[0],
        lastName: this.props.address.name.split(" ")[1] || "",
        company: this.props.address.company || "",
        street1: this.props.address.street1 || "",
        street2: this.props.address.street2 || "",
        city: this.props.address.city || "",
        state: this.props.address.state || "",
        postalCode: this.props.address.postal_code || "",
        country: this.props.address.country || "",
        phone: this.props.address.phone || "",
        residential: this.props.address.residential || false,
        store_id: this.props.address.store_id,
        notes: this.props.address.notes || "",
      })
    }
  }

  fillForm = (id) => {
    this.props.setAddress(id)
  }

  AddressSelector = (props) => {
    const options = props.stores.map(o => (
      {
        value: o.id,
        label: o.label,
      }
    ));

    const handleChange = (newValue, actionMeta) => {
      this.fillForm(newValue?.value);
    };

    const addressSelectDefaultValue = (options) => {
      let address_id = (!props.address) ? options[0].value : props.address.id
      return options.filter(o => o.value == address_id)[0]
    }

    return (
      <Select
        className="p-1 m-1"
        classNamePrefix="select"
        defaultValue={addressSelectDefaultValue(options)}
        isClearable={true}
        isSearchable={true}
        name="addresses"
        options={options}
        value={addressSelectDefaultValue(options)}
        onChange={handleChange}
        placeholder="Select Address..."
      />
    )
  }

  OnTextChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  setChecked(state, event) {
    this.setState({
      ...state,
      residential: state
    })
  }

  OnSubmit = e => {
    e.preventDefault()

    const state = this.state
    const ship_address = {
      id: state.id,
      name: state.firstName + " " + state.lastName,
      company: state.company,
      street1: state.street1,
      street2: state.street2,
      city: state.city,
      state: state.state,
      postal_code: state.postalCode,
      country: "US", //state.country,
      phone: state.phone,
      residential: state.residential,
      store_id: state.store_id,
      notes: state.notes,
    }
    this.props.createToast("", "User Info", "Address Successfully Updated.")
    this.props.updateShipFromAddress(ship_address);
  }

  render() {
    let address = this.props.address;

    return (
      <Jumbotron>
        <Container>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Select address:</Form.Label>
              {this.AddressSelector(this.props)}
            </Form.Group>
          </Row>

          <h1>Address Information</h1>
          <p>
            Set your preferred 'Ship From' address.
          </p>
          <div>
            <Form onSubmit={this.OnSubmit} >
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control placeholder="Enter first name" id="firstName" name="firstName" onChange={this.OnTextChange} value={this.state.firstName} />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control placeholder="Enter last name" id="lastName" name="lastName" onChange={this.OnTextChange} value={this.state.lastName} />
                </Form.Group>
              </Form.Row>

              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>Company</Form.Label>
                  <Form.Control placeholder="Main St Inc." id="company" name="company" onChange={this.OnTextChange} value={this.state.company} />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Address</Form.Label>
                  <Form.Control placeholder="1234 Main St" id="street1" name="street1" onChange={this.OnTextChange} value={this.state.street1} />
                </Form.Group>
              </Row>

              <Form.Group>
                <Form.Label>Address 2</Form.Label>
                <Form.Control placeholder="Apartment, studio, or floor" id="street2" name="street2" onChange={this.OnTextChange} value={this.state.street2} />
              </Form.Group>

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>City</Form.Label>
                  <Form.Control id="city" name="city" onChange={this.OnTextChange} value={this.state.city} />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>State</Form.Label>
                  <Form.Control as="select" id="state" name="state" onChange={this.OnTextChange} value={this.state.state}>
                    {Object.keys(usStates).map(s => {
                      return <option key={s} value={s}>{usStates[s]}</option>
                    })}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Zip</Form.Label>
                  <Form.Control id="postalCode" name="postalCode" onChange={this.OnTextChange} value={this.state.postalCode} />
                </Form.Group>
              </Form.Row>

              <Form.Group id="formGridCheckResidential">
                <Form.Check type="checkbox" label="Residential Address" id="residential" name="residential" checked={this.state.residential} onChange={(event) => this.setChecked(event.target.checked, event)} />
              </Form.Group>

              <Form.Group>
                <Form.Label>Packing Slip Notes</Form.Label>
                <Form.Control as="textarea" style={{ height: '100px' }} placeholder="Packing slip message to customer goes here...." id="notes" name="notes" onChange={this.OnTextChange} value={this.state.notes} />
              </Form.Group>

              <Button variant="primary" type="submit">
                Save
              </Button>
            </Form>
          </div>
        </Container>
      </Jumbotron>
    )
  }
}

export default AddressForm;
