import store from '../reducers/index.js';

export function markForPrint(batchId) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/shipping/mark_for_print`, {
      method: "POST",
      headers: {
         "Content-Type": "application/json",
         "Accept": "application/json",
         Authorization: `Bearer ${token}`
       },
       body: JSON.stringify({orders: { batch_id: batchId }})
     })
     .then(resp => resp.json())
     .then(data => {
       if (batch_id != null) {
         dispatch({ type: 'SET_FAILED_ORDERS', orders: data.orders.data, print_batches: data.batches.data });
       }
       dispatch({ type: 'PROCESSING_REQUEST', jobType: 4 });
     })
  }
}

export function generatePicklist(order_numbers) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });
    dispatch({ type: 'PROCESSING_REQUEST', jobType: 7 });

    return fetch(`/api/shipping/generate_picklist`, {
      method: "POST",
      headers: {
         "Content-Type": "application/json",
         "Accept": "application/json",
         Authorization: `Bearer ${token}`
       },
       body: JSON.stringify({orders: { order_numbers: order_numbers }})
     })
     .then(response => {
        if (response.ok) {
          return response.blob();
        }
      })
     .then(blob => {
       window.URL = window.URL || window.webkitURL;
       const data = window.URL.createObjectURL(blob);
       let link = document.createElement("a");
       let currentDate = new Date()
       let day = currentDate.getDate()
       let month = currentDate.getMonth() + 1
       let year = currentDate.getFullYear()
       let fileName = month + "-" + day + "-" + year + " picklist.pdf"

       link.href = data
       link.download = fileName;
       document.body.appendChild(link);
       link.click();
       document.body.removeChild(link);

       dispatch({ type: 'END_REQUEST' });
    })
  }
}



function base64ToPdf(data) {
  const head = "data:application/pdf;base64,"
  return head + data
}
