import React, { PureComponent } from 'react';

import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import SplitButton from 'react-bootstrap/SplitButton';

export default class BatchButton extends PureComponent{
  constructor(props) {
    super(props)
  }

  handleClick = (id = null) => {
    let orderNumbers = this.props.orderNumbers

    this.props.assignToBatch(id, orderNumbers)
  }

  render() {
      const batch_ids = this.props.batches.map(b => b.id )

      return (
        <>
          <Button
            style={{'fontSize': '12px'}}
            variant="primary"
            disabled={this.props.disabled}
            onClick={() => this.handleClick()}
          >
            {this.props.processing && this.props.processingType == 2 ? 'Batching...' : 'Create Batch'}
          </Button>

          <Dropdown.Toggle split variant="primary" size={'sm'} id="dropdown-split-basic" />

          <Dropdown.Item style={{'fontSize': '12px'}} eventKey="1" disabled={true}>Assign To Batch:</Dropdown.Item>
          <Dropdown.Divider />
          {batch_ids.map(id => {
            return(
              <Dropdown.Item onClick={(id) => this.handleClick(id)} style={{'fontSize': '12px'}} id={id} eventKey={id}>{id}</Dropdown.Item>
            )
          })}
        </>
      );
    }
  }
