import React, { Component } from 'react';
import Select from 'react-select'

// Bootstrap start
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Jumbotron from 'react-bootstrap/Jumbotron'
// Bootstrap end

import { marketplace_options } from './Data';

class SignupForm extends Component{
  constructor() {
    super()

    this.state = {
      signup_name: "",
      signup_email: "",
      signup_password: "",
      signup_company: "",
      signup_marketplaces: [],
      signup_source: ""
    }
  }

  OnTextChange = e => {
    this.setState({
      [e.target.name]: e.target.value
      })
  }

  HandleSelect = e => {
    this.setState({
      signup_marketplaces: e.map(m => m.value)
    })
  }

  OnSubmit = e => {
    const { signup_name, signup_email, signup_password, signup_company, signup_marketplaces, signup_source } = this.state
    e.preventDefault()
    this.props.createUser(signup_name, signup_email, signup_password, signup_company, signup_marketplaces, signup_source)
  }

  render() {
    return(
      <Jumbotron>
        <h1>Basic Information</h1>
        <p>
          Signup with your email and password.
        </p>

        <div>
          <Form onSubmit={this.OnSubmit} >
            <Form.Group>
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Full Name"
                id="signup_name"
                name="signup_name"
                value={this.state.signup_name}
                onChange={e => this.OnTextChange(e)}
                required={true}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                id="signup_email"
                name="signup_email"
                value={this.state.signup_email}
                onChange={e => this.OnTextChange(e)}
                required={true} />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter Secure Password"
                id="signup_password"
                name="signup_password"
                value={this.state.signup_password}
                onChange={e => this.OnTextChange(e)}
                required={true}
              />
            </Form.Group>

            <h1>More Information</h1>

            <Form.Group>
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Company Name"
                id="signup_company"
                name="signup_company"
                value={this.state.signup_company}
                onChange={e => this.OnTextChange(e)}
                required={true}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Marketplaces You Currently Sell On</Form.Label>
              <Select
                isMulti
                placeholder="Enter Marketplaces You Sell On"
                id="signup_marketplaces"
                name="signup_marketplaces"
                className="basic-multi-select"
                classNamePrefix="select"
                options={marketplace_options}
                onChange={e => this.HandleSelect(e)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Where Did Your Hear About Us?</Form.Label>
              <Form.Control
                type="text"
                placeholder="Heard about us"
                id="signup_source"
                name="signup_source"
                value={this.state.signup_source}
                onChange={e => this.OnTextChange(e)}
                required={true}
              />
            </Form.Group>

            <Button variant="primary" type="submit" disabled={this.props.requesting}>
              {this.props.requesting ? 'Loading…' : 'Verify Email To Continue Signup'}
            </Button>
          </Form>
        </div>
      </Jumbotron>
    )
  }
}

export default SignupForm;
