import store from '../reducers/index.js';

export function createUser(name, email, password, company, marketplaces, source) {

  return (dispatch) => {
    dispatch({ type: 'START_LOGIN_REQUEST' });
    fetch('/api/users', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify({ user: { name, email, password, company, marketplaces, source } })
    })
      .then(response => response.json())
      .then(data => {
        if (!data.failure) {
          localStorage.setItem("token", data.jwt);
          localStorage.setItem("user_id", data.user.id);
          return dispatch({ type: 'NEW_USER', user: data.user, signup_tab: data.signup_tab, success: data.success })
        }
        else {
          return dispatch({ type: 'LOGIN_FAILED', error: data.failure })
        }
      })
  }
}

export function continueSignup() {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    if (token) {
      return fetch(`/api/users/continue_signup`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(resp => resp.json())
        .then(data => dispatch(
          { type: 'CONTINUE_SIGNUP', user: data.user, signup_tab: data.signup_tab, success: data.success })
        )
    }
    else {
      dispatch({ type: 'LOGIN_FAILED', error: "You need to log in to continue." })
    }
  }
}

export function loginUser(email, password) {
  return (dispatch) => {
    dispatch({ type: 'START_LOGIN_REQUEST' });
    fetch('/api/login', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify({ email, password })
    })
      .then(response => response.json())
      .then(data => {
        if (!data.failure) {
          localStorage.setItem("token", data.jwt);
          localStorage.setItem("user_id", data.user.id);
          return dispatch({ type: 'LOGIN_USER', user: data.user, success: data.success })
        }
        else {
          return dispatch({ type: 'LOGIN_FAILED', error: data.failure })
        }
      })
  }
}

export function updateUser(name, email, password, time_zone = 'UTC') {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_LOGIN_REQUEST' });
    fetch('/api/users/update', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ user: { name, email, password, time_zone } })
    })
      .then(response => response.json())
      .then(data => {
        if (!data.failure) {
          return dispatch({ type: 'AUTHED_USER', user: data.user, success: data.success })
        }
        else {
          return dispatch({ type: 'LOGIN_FAILED', error: data.failure })
        }
      })
  }
}

export function getAvailableTimeZones() {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    const timeZoneEndpoint = `/api/users/available_time_zones`;
    return fetch(timeZoneEndpoint, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch(
          {
            type: 'GET_AVAILABLE_TIME_ZONES',
            available_time_zones: data.time_zones
          }
        )
      })
  }
}


export function verifyCode(code) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    if (token) {
      return fetch(`/api/users/verify_code?code=` + code, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(resp => resp.json())
        .then(data => dispatch(
          { type: 'VERIFY_NEW_USER', email_confirmed: data.email_confirmed, signup_tab: data.signup_tab, success: data.success })
        )
    }
    else {
      dispatch({ type: 'VERIFY_NEW_USER', email_confirmed: data.email_confirmed, signup_tab: data.signup_tab, failure: data.failure })
    }
  }
}

export function authedUser() {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    if (token) {
      return fetch(`/api/auto_login`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(resp => resp.json())
        .then(data => dispatch(
          { type: 'AUTHED_USER', user: data.user, success: data.success })
        )
    }
    else {
      dispatch({ type: 'LOGIN_FAILED', error: "You need to log in to continue." })
    }
  }
}

export function loggedInUser() {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    if (token) {
      return fetch(`/api/auto_login`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(resp => resp.json())
        .then(data => {
          dispatch(
            { type: 'LOGGED_IN_USER', success: data.success })
        }
        )
    }
    else {
      dispatch({ type: 'LOGIN_FAILED', error: "You need to log in to continue." })
    }
  }

}

export function logoutUser() {
  localStorage.removeItem("token")

  return (dispatch) => {
    return dispatch({ type: 'LOGOUT_USER', success: "You are now logged out." })
  }
}

export function getShippingRules(selectedCarrier) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/users/shipping-rules`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({
          type: 'GET_SHIPPING_RULES',
          carrierServices: data.carrierServices,
          carrierCountries: data.carrierCountries,
          success: data.success
        })
        dispatch(
          { type: 'FILTER_SHIPPING_RULES', carrier_id: selectedCarrier }
        )
      })
      .then(() => dispatch({ type: 'END_REQUEST' }))
  }
}

export function updateRulesTable(shipping_settings) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/users/shipping-rules`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ shipping_settings })
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({
          type: 'UPDATE_SHIPPING_RULES',
          carrierServices: data.carrierServices,
          carrierCountries: data.carrierCountries,
          success: data.success
        })

      }
      )
      .then(() => dispatch({ type: 'END_REQUEST' }))
    // .catch(() => dispatch({ type: 'REQUEST_FAILED' }))
  }
}

export function setExcludedCountries(countriesObject) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/users/update-carrier-countries`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ countriesObject })
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({
          type: 'UPDATE_SHIPPING_RULES',
          carrierServices: data.carrierServices,
          carrierCountries: data.carrierCountries,
          success: data.success
        })
        dispatch({ type: 'FILTER_SHIPPING_RULES', carrier_id: countriesObject["carrier_code"] });
      }
      )
      .then(() => dispatch({ type: 'END_REQUEST' }))
    // .catch(() => dispatch({ type: 'REQUEST_FAILED' }))
  }
}

export function updateApiKeys(api_keys) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });
    fetch('/api/users/keys', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ api_keys })
    })
      .then(resp => resp.json())
      .then(data => dispatch({ type: 'UPDATE_API_KEYS', user: data.user, success: data.success }))
      .then(data => dispatch({ type: 'CONTINUE_SIGNUP', success: data.success }))
      .catch(() => dispatch({ type: 'UPDATE_API_KEYS' }))
  }
}

export function testApiKeys(source) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });
    fetch('/api/users/test_api_keys', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ source: source })
    })
      .then(resp => resp.json())
      .then(data => dispatch({ type: 'CONTINUE_SIGNUP', success: data.success }))
      .catch(() => dispatch({ type: 'CONTINUE_SIGNUP', error: data.error }))
  }
}

export function setTab(tab) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    fetch('/api/users/set_tab', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ tab: tab })
    })
      .then(resp => resp.json())
      .then(data => dispatch({ type: 'SET_TAB', signup_tab: data.signup_tab }))
  }
}

export function organizeOrders() {
  return (dispatch) => {
    dispatch({ type: 'ORGANIZE_ORDERS' }
    )
  }
}

export function getOrders(page_number, status, activeFilters = [""]) {
  const token = localStorage.getItem("token");

  const selectedStores = store.getState().auth.selectedStores
  const selectedOrderTags = store.getState().auth.selectedOrderTags
  const searchQuery = store.getState().auth.searchQuery
  const columns = { "processedOrders": "Shipments", "unprocessedOrders": "Orders" }
  const sortColumn = store.getState().auth[`sort${columns[status]}Column`]
  const sortDirection = store.getState().auth[`sort${columns[status]}ColumnDirection`]

  setNewNotification(status)

  return (dispatch) => {
    return fetch(`/api/shipping/orders?pageNumber=${page_number}&orderStatus=${status}&filters=${activeFilters}&stores=${selectedStores}&order_tags=${selectedOrderTags}&sort_column=${sortColumn}&sort_direction=${sortDirection}&search_query=${searchQuery}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        switch (data.meta.type) {
          case 'unprocessedOrders':
            checkNewNotification('unprocessedOrders', data.meta.count)
            dispatch(
              { type: 'GET_UNPROCESSED_ORDERS', orders: (data.data ? data.data : []), pages: data.meta.pages, pageNumber: data.meta.page_number, count: data.meta.count, activeFilters: data.meta.active_filters, all_order_ids: data.meta.all_order_ids }
            )
            break;
          case 'processedOrders':
            checkNewNotification('processedOrders', data.meta.count)
            dispatch(
              { type: 'GET_PROCESSED_ORDERS', orders: data.data, pages: data.meta.pages, pageNumber: data.meta.page_number, count: data.meta.count, activeFilters: data.meta.active_filters }
            )
            break;
          case 'printedOrders':
            checkNewNotification('printedOrders', data.meta.count)
            dispatch(
              { type: 'GET_PRINTED_ORDERS', orders: data.data, pages: data.meta.pages, pageNumber: data.meta.page_number, count: data.meta.count, activeFilters: data.meta.active_filters }
            )
            break;
          default:
            console.log(`:)`);
        }
      })
  }
}

export function setQuery(query) {
  return (dispatch) => {
    dispatch({ type: 'SET_QUERY', query: query })
  }
}

function setNewNotification(status) {
  const catalog = store.getState().auth.catalog[status] || store.getState().auth.printBatches
  localStorage.setItem(status + 'Count', catalog.count || catalog.length)
}

function checkNewNotification(status, newCount) {

  let count = localStorage.getItem(status + 'Count')
  if (count < newCount) {
    localStorage.setItem(status + "New", true)
  }
}

export function titleChanged(changed) {
  return (dispatch) => {
    dispatch({ type: 'TITLE_CHANGED', changed: changed });
  }
}

export function toggleTestMode() {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    fetch('/api/users/toggle_test_mode', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => dispatch({ type: 'TOGGLE_TEST_MODE', test_mode: data.test_mode, success: data.success }))
  }
}

export function getPrintBatches() {
  const token = localStorage.getItem("token");
  setNewNotification("printBatches")

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    return fetch(`/api/shipping/print_batches`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        checkNewNotification("printBatches", data.batches.length)
        dispatch(
          { type: 'GET_PRINT_BATCHES', batches: data.batches.data, success: data.success }
        )
      })
  }
}

export function updateShipFromAddress(address) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });
    fetch('/api/users/update_address', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ address: address })
    })
      .then(resp => resp.json())
      .then(data => dispatch({ type: 'UPDATE_USER_ADDRESS', user: data.user, success: data.success }))
  }
}

export function hideAlert() {
  return (dispatch) => {
    dispatch({ type: 'HIDE_ALERT' });
  }
}

export function updateActiveFilters(activeFilters, selectedStores, selectedOrderTags) {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_ACTIVE_FILTERS',
      activeFilters: activeFilters,
      selectedStores: selectedStores?.map(store => store.value) || [],
      selectedOrderTags: selectedOrderTags?.map(tag => tag.value) || []
    });
  }
}

export function setSortColumn(tab, column) {
  return (dispatch) => {
    dispatch({ type: 'SET_SORT_COLUMN', tab: tab, column: column, direction: store.getState().auth[`sort${tab}ColumnDirection`] == "asc" ? "dsc" : "asc" });
    // dispatch(sortTableData(column, tab))
  }
}

function sortTableData(column, tab) {
  const columnKey = {
    "Number": "order_number",
    "QTY": "order_qty",
    "Weight": "weight",
    "Status": "order_status",
    "Date": "order_date",
    "Carrier": "ship_with_carrier",
    "Service": "ship_with_carrier_code",
    "Shipping": "requestedShippingService",
    "Rate": "cheapest_rate",
    "Domestic": "country",
    "Country": "country",
    "Skus": "skus",
    "Tags": "tags"
  }

  return (dispatch) => {
    if (store.getState().auth[`sort${tab}ColumnDirection`] == "asc") {
      dispatch({ type: 'SORT_TABLE_DATA_ASC', columnKey: columnKey[column] });
    } else {
      dispatch({ type: 'SORT_TABLE_DATA_DSC', columnKey: columnKey[column] });
    }
  }
}

export function getTarrifs(tarrifs) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });
    fetch('/api/users/tarrifs', {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => dispatch({ type: 'GET_USER_TARRIFS', user_tarrifs: data.user_tarrifs, success: data.success }))
  }
}

export function updateTarrifs(tarrifObject) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });
    fetch('/api/users/tarrifs', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ tarrif: tarrifObject })
    })
      .then(resp => resp.json())
      .then(data => dispatch({ type: 'GET_USER_TARRIFS', user_tarrifs: data.user_tarrifs, success: data.success }))
  }
}

export function destroyTarrifs(id) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });
    fetch('/api/users/destroy_tarrifs', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ id: id })
    })
      .then(resp => resp.json())
      .then(data => dispatch({ type: 'GET_USER_TARRIFS', user_tarrifs: data.user_tarrifs, success: data.success }))
  }
}

export function filterUserRateMarkups(user_id) {
  return (dispatch) => {
    dispatch({ type: 'FILTER_USER_RATE_MARKUPS', user_id: user_id });
  }
}

export function getUserRateMarkups() {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });
    fetch('/api/users/user_rate_markups', {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => resp.json())
      .then(data => dispatch({ type: 'GET_USER_RATE_MARKUPS', user_rate_markups: data.user_rate_markups, user_rate_markup_users: data.user_rate_markup_users, success: data.success }))
  }
}

export function updateUserRateMarkups(markupObject) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });
    fetch('/api/users/user_rate_markups', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ user_rate_markup: markupObject })
    })
      .then(resp => resp.json())
      .then(data => dispatch({ type: 'GET_USER_RATE_MARKUPS', user_rate_markups: data.user_rate_markups, user_rate_markup_users: data.user_rate_markup_users, success: data.success }))
      .then(() => dispatch({ type: 'FILTER_USER_RATE_MARKUPS', user_id: markupObject.user_id }))
  }
}

export function destroyUserRateMarkup(id) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });
    fetch('/api/users/destroy_user_rate_markups', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ id: id })
    })
      .then(resp => resp.json())
      .then(data => dispatch({ type: 'GET_USER_RATE_MARKUPS', user_rate_markups: data.user_rate_markups, user_rate_markup_users: data.user_rate_markup_users, success: data.success }))
  }
}

export function toggleStore(id) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    fetch('/api/users/toggle_store', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ id: id })
    })
      .then(resp => resp.json())
      .then(data => dispatch({ type: 'UPDATE_STORES', store_objects: data.store_objects, success: data.success }))
  }
}

export function updateCutoffTime(cutoff_time) {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch({ type: 'START_REQUEST' });

    fetch('/api/users/update_cutoff_time', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ cutoff_time: cutoff_time })
    })
      .then(resp => resp.json())
      .then(data => dispatch({ type: 'UPDATE_CUTOFF_TIME', label_cutoff_time: data.label_cutoff_time, success: data.success }))
  }
}
