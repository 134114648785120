export const shippingColumns = [
  { key: 'carrier_code', name: 'Carrier Code'},
  { key: 'service_name', name: 'Service Name'},
  { key: 'max_weight', name: 'Max Weight', editable: true},
  { key: 'domestic', name: 'Domestic'},
  { key: 'international', name: 'International'},
  { key: 'standard', name: 'Standard'},
  { key: 'expedited', name: 'Expedited'},
  { key: 'next_day', name: 'Next Day'},
  { key: 'second_day', name: 'Second Day'},
  { key: 'use_est_delivery_date', name: 'Use EST delivery date'}
]

export const packagingColumns = [
  { key: 'carrierCode', name: 'Carrier Code'},
  { key: 'code', name: 'Packaging Code'},
  { key: 'name', name: 'Packaging Name'},
  { key: 'domestic', name: 'Domestic'},
  { key: 'international', name: 'International'},
  { key: 'excludedSkus', name: 'Excluded Skus'},
  { key: 'exclusive_skus', name: 'Exclusive Skus'},
  { key: 'max_qty', name: 'Max Qty'},
  { key: 'width', name: 'Width'},
  { key: 'height', name: 'Height'},
  { key: 'length', name: 'Length'},
  { key: 'max_weight', name: 'Max Weight'},
  { key: 'package_type', name: 'Package Type'},
  { key: 'actions', name: 'Actions'}
]

export const tarrifColumns = [
  { key: 'name', name: 'Description'},
  { key: 'harmonization_code', name: 'Harmonization Code'},
  { key: 'origin_country_code', name: 'Origin Country Code'},
  { key: 'match_with_name', name: 'Title Match'},
  { key: 'value', name: 'Declared Value'},
  { key: 'actions', name: 'Actions'}
]

export const bulkPricingColumns = [
  { key: 'name', name: 'Description'},
  { key: 'qty_from', name: 'Min(Including min)'},
  { key: 'qty_to', name: 'Max(Excluding max) Quantity'},
  { key: 'discount_percent', name: 'Discount Percent'},
  { key: 'actions', name: 'Actions'}
]

export const skuPackagingLimitsColumns = [
  { key: 'sku', name: 'SKU'},
  // { key: 'packagings', name: 'Packagings'},
  // { key: 'max_qty', name: 'Max(Excluding max) Quantity'},
  { key: 'exact_match', name: 'Exact Match?'},
  { key: 'width', name: 'Width'},
  { key: 'height', name: 'Height'},
  { key: 'length', name: 'Length'},
  { key: 'weight', name: 'Weight'},
  { key: 'actions', name: 'Actions'}
]

export const markupColumns = [
  { key: 'user_id', name: 'User'},
  { key: 'nickname', name: 'Carrier'},
  { key: 'markup_percent', name: 'Markup Percent'},
  { key: 'actions', name: 'Actions'}
]
