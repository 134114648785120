import React from 'react';
import { connect } from 'react-redux';
import consumer from 'channels/consumer.js';

// bootstrap
import Alert from 'react-bootstrap/Alert'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Overlay from 'react-bootstrap/Overlay'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faHome, faSync, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
// Bootstrap end

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import UserContainer from './user/UserContainer';
import LoginContainer from './user/LoginContainer';
import SignupContainer from './user/SignupContainer';
import OrderContainer from './order/OrderContainer';
import Loader from 'components/user/Loader.js';

import { getNewOrders } from 'components/shippingActions.js';
import { loggedInUser, logoutUser } from 'components/authActions.js';

class App extends React.Component {
  downloadNewOrdersButton() {
      return(
        <Nav.Link
          disabled={(this.props.processing && this.props.processingType == 3 )}
          onClick={!(this.props.processing && this.props.processingType == 3 ) ? () => this.props.getNewOrders() : null}
        >
          {(this.props.processing && this.props.processingType == 3 ) ? 'Loading…' : <FontAwesomeIcon icon={faSync} />}
        </Nav.Link>
      )
  }

  testModeMessage() {
    if (this.props.testMode && this.props.loggedIn) {
      return(
        <Nav.Item>
          {/* <Alert variant="info" className="mt-2 mb-0 p- text-uppercase"> */}
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="button-tooltip">When in test mode you can view live rates and generate test labels.</Tooltip>}
          >
            <Badge variant="danger" className="p-2">Test Mode Is Enabled</Badge>
          </OverlayTrigger>
          {/* </Alert> */}
        </Nav.Item>
      )
    }
  }

  render () {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const nDate = new Date(this.props.orders_last_updated_at)
    const lastUpdatedDate = nDate.toLocaleString("en-US")

    return (
      <Container fluid className="p-0 m-0">
        <Navbar bg="primary" variant="dark">
          {/* <Navbar.Brand to="/" as={Link}><FontAwesomeIcon icon={faChevronLeft}/></Navbar.Brand> */}
          <Nav className="mb-0">
            {this.testModeMessage()}
          </Nav>
          <Nav className="mr-auto mb-0">
            {this.props.loggedIn ? <Nav.Link className="text-white"><strong>Last Sync: </strong>{lastUpdatedDate}</Nav.Link>  : ""}
          </Nav>
          <Nav className="ml-auto">
            {this.props.loggedIn ? this.downloadNewOrdersButton() : "" }
            {this.props.loggedIn ? <Nav.Link to="/users/orders" as={Link}><FontAwesomeIcon icon={faHome}/></Nav.Link> : "" }
            {this.props.loggedIn ? <Nav.Link to="/users" as={Link}><FontAwesomeIcon icon={faCog}/></Nav.Link> : "" }
            {this.props.loggedIn ? "" : <Nav.Link to="/users/login" as={Link} className="text-white">Login</Nav.Link> }
            {this.props.loggedIn ? <Nav.Link onClick={this.props.logoutUser} className="text-white">Logout</Nav.Link>  : ""}
          </Nav>
        </Navbar>

        <Switch>
          <Route exact path='/users' component={UserContainer} />
          <Route exact path='/users/login' component={LoginContainer} />
          <Route exact path='/users/signup' component={SignupContainer} />
          <Route exact path={["/", "/users/orders"]} component={OrderContainer} />
        </Switch>


        <Navbar fixed="bottom" bg="light" variant="light" style={{'maxHeight': '25px', 'height': '25px'}}>
          <Nav className="mr-auto">
            Copyright ShipMatix 2020
          </Nav>
          <Nav className="ml-auto">
          </Nav>
        </Navbar>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
      user_id: state.auth.currentUser.id,
      loggedIn: state.auth.loggedIn,
      requesting: state.auth.requesting,
      processing: state.auth.processing,
      processingType: state.auth.processingType,
      testMode: state.auth.testMode,
      orders_last_updated_at: state.auth.currentUser.orders_last_updated_at
  }
}

const mapDispatch = dispatch => {
  return {
    getNewOrders: () => { dispatch(getNewOrders()) },
    authedUser: () => { dispatch(authedUser()) },
    logoutUser: () => { dispatch(logoutUser()) }
  }
}

export default connect(mapStateToProps, mapDispatch)(App);
