const userReducer =
(state = {
  editableFields: ["standard",
                  "expedited",
                  "next_day",
                  "second_day",
                  "use_est_delivery_date"],
  filteredCarrierCountries: [],
  filteredCarrierServices: [],
  carrierServices: [],
  carrierCountries: [],
  allowedPackagings:  [],
  availablePackagings: [],
  initialCustomPackagings: [],
  CustomPackagingsData: [],
  createCustomPackagingMessage: null,
  bulkPricings: [],
  skuPackagingLimits: [],
  skuPackagingLimitsInitialOptionsData: [],
  skuPackagingLimitsOptionsData: [],
  catalog: { skuPackagingLimits: { pageNumber: 1, pages: 1, activeFilters: [], count: 0 } },
  orders: [],
  unprocessedOrders: [],
  processedOrders: [],
  printedOrders: [],
  orderLabels: [],
  orderPackingSlips: [],
  successfully_imported_records_count: null,
  sku_packaging_limit_delete_messages: { success: null, failure: null},
  csv_import_errors: null,
  combinedLabels: "",
  onElectron: false
}, action) => {
  switch (action.type) {
    case "GET_SHIPPING_RULES":
      return {
      ...state,
      carrierServices: action.carrierServices.sort((a, b) => a.id - b.id) || [],
      carrierCountries: action.carrierCountries || [],
      requesting: false,
      loggedIn: true,
      error: "",
      success: action.success
      }

    case "UPDATE_SHIPPING_RULES":
      return {
      ...state,
      carrierServices: action.carrierServices.sort((a, b) => a.id - b.id),
      carrierCountries: action.carrierCountries || [],
      requesting: false,
      loggedIn: true,
      error: "",
      success: action.success
      }

    case "FILTER_SHIPPING_RULES":
      return {
        ...state,
        filteredCarrierServices: state.carrierServices.filter(c => c.carrier_id == action.carrier_id),
        filteredCarrierCountries: state.carrierCountries.filter(c => c.code == action.carrier_id)[0]?.excludedCountries || []
      }

    case "UPDATE_API_KEYS":
      return {
      ...state,
      currentUser: action.user,
      requesting: false,
      error: "",
      success: action.success
      }

    case "GET_PACKAGINGS":
      return {
      ...state,
      allowedPackagings: action.allowedPackagings.sort((a, b) => a.id - b.id) || [],
      availablePackagings: action.availablePackagings.sort((a, b) => a.id - b.id) || [],
      requesting: false,
      loggedIn: true,
      error: action.failure || "",
      success: action.success || ""
      }

    case "GET_INITIAL_CUSTOM_PACKAGINGS":
      return {
      ...state,
      initialCustomPackagings: action.initialCustomPackagings,
      loggedIn: true,
      }

    case "FIND_CUSTOM_USER_PACKAGING":
      return {
      ...state,
      CustomPackagingsData: action.data,
      loggedIn: true
      }

    case "CREATE_CUSTOM_PACKAGING":
      return {
      ...state,
      createCustomPackagingMessage: action.message,
      loggedIn: true
      }

    case "UPDATE_PACKAGINGS":
      return {
      ...state,
      allowedPackagings: action.allowedPackagings.sort((a, b) => a.id - b.id),
      availablePackagings: action.availablePackagings.sort((a, b) => a.id - b.id),
      requesting: false,
      loggedIn: true,
      error: "",
      success: action.success
      }

    case "SET_LABEL":
      return {
      ...state,
      requesting: false,
      loggedIn: true,
      orderLabels: [...state.orderLabels, { id: action.orderNumber, label: action.label }]
      }

    case "SET_PACKING_SLIP":
        return {
        ...state,
        requesting: false,
        loggedIn: true,
        orderPackingSlips: [...state.orderPackingSlips, { id: action.orderNumber, label: action.packing_slip }]
        }

    case "SET_COMBINED_LABELS":
      return {
      ...state,
      requesting: false,
      loggedIn: true,
      combinedLabels: action.labels
      }

    case "SWITCH_ELECTRON":
      return {
        ...state,
        onElectron: !state.onElectron
      }

    case "GET_BULK_PRICING":
      return {
        ...state,
        bulkPricings: action.bulkPricings || [],
        success: action.success || [],
        error: action.failure || []
      }

    case "GET_SKU_PACKAGING_LIMITS":
      return {
        ...state,
        skuPackagingLimits: action.skuPackagingLimits || state.skuPackagingLimits,
        success: action.success || [],
        error: action.failure || [],
        catalog: {
          ...state.catalog,
          skuPackagingLimits: {
            pageNumber: action.pagy.page,
            pages: action.pagy.pages,
            count: action.pagy.count
          }
        }
      }

    case "DELETE_SKU_PACKAGING_LIMIT":
      return {
        ...state,
        sku_packaging_limit_delete_messages: {
          success: action.success,
          failure: action.failure
        }
      }

    case "GET_SKU_PACKAGING_LIMIT_INITIAL_OPTIONS_DATA":
      return {
        ...state,
        skuPackagingLimitsInitialOptionsData: action.data
      }

    case "FIND_SKU_PACKAGING_LIMIT_OPTIONS_DATA":
      return {
        ...state,
        skuPackagingLimitsOptionsData: action.data
      }

    case "IMPORT_SKU_PACKAGING_LIMITS_CSV":
      return {
        ...state,
        successfully_imported_records_count: action.successfully_imported_records_count,
        csv_import_errors: action.csv_import_errors
      }
    default:
      return state;
  }
}

export default userReducer;
