import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';

// Bootstrap start
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Select from 'react-select';
// Bootstrap end

import { connect } from 'react-redux';
import { updateRulesTable, getShippingRules, setExcludedCountries } from 'components/authActions.js';
import { filterCarrierTableRows } from 'components/userActions.js';
import { shippingColumns as columns } from './TableColumns.js';
import CellCheckBox from './CellCheckBox.js';
import Loader from './Loader.js';
import SelectCountries from './SelectCountries.js'
import { countriesJson } from './Data';


class RulesTable extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      tableRendered: false,
      selectedCarrier: props.carriers[0] != undefined ? this.props.carriers[0].id : ""
    }
  }

  componentDidMount() {
      this.props.getShippingRules(this.state.selectedCarrier);
  }

  EmptyRowsView = () => {
    return (
      <div>
      <UseAnimations animationKey="loading" size={56} padding={"200px"}/>
    </div>);
  };

  handleChange = (newValue, actionMeta) => {
    this.setState({
      ...this.state,
      selectedCarrier: newValue.value
    },
      () => this.props.filterCarrierTableRows(newValue?.value)
    )
  };

  FilterMenu = (props) => {
    const defaultValue = this.state.selectedCarrier;
    const options = props.carriers.map(o => (
      {
        value: o.id,
        label: o.carrier_code,
      }
    ));

    return (
      <Select
        className="p-1 m-1"
        classNamePrefix="select"
        defaultValue={options.filter(o => o.value == props.carriers[0].id)}
        isClearable={false}
        isSearchable={true}
        name="carriers"
        options={options}
        value={options.filter(o => o.value == this.state.selectedCarrier)}
        onChange={this.handleChange}
        placeholder="Select Carrier..."
      />
    )
  }

  // FilterMenu = () => {
  //   const value = this.state.selectedCarrier;
  //   // const options = this.props.carriers.map(c => c.carrier_code);
  //   const options = this.props.carriers.map(o => (
  //     {
  //       value: o.id,
  //       label: o.carrierCode,
  //     }
  //   ));

  //   const onChangeSelection = (event) => {
  //     let option = event.target.value
  //     this.setState({
  //       ...this.state,
  //       selectedCarrier: option
  //     },

  //       () => this.props.filterCarrierTableRows(this.state.selectedCarrier)
  //     )
  //   }

  //   return (
  //     <Form>
  //       <Form.Group>
  //         <Form.Control as="select" custom value={value} onChange={onChangeSelection}>
  //           {options.map(o => {
  //             return(
  //               <option key={o.value}>{o.label}</option>
  //             )
  //           })}
  //         </Form.Control>
  //       </Form.Group>
  //     </Form>
  //   )
  // }

  ExcludedCountriesSelect = () => {
    return(
      <>
        <Form.Label>Excludes Countries:</Form.Label>
        <SelectCountries
          setValues={this.props.setExcludedCountries}
          options={countriesJson}
          values={this.props.carrierCountries}
          activeInput={false}
          selectedCarrier={this.state.selectedCarrier}
        />
      </>
    )
  }

  carriersCount = () => {
    const {carriers} = this.state.carriers
    if (!carriers) {
      return 0
    } else {
      return carriers.length
    }
  };

  LoadingMsg = () => {
    if (this.props.requesting) {
      return "Saving Settings..."
      } else {
        return ""
      }
  }

  OnSaveTableCell = (dataKey, newCellData) => {
    // Get cell position from dataKey
    const key = dataKey
    const rowId = parseInt(key.split("-")[1])
    const rowField = key.split("-")[0]

    // Get a copy of the props
    let stateCopy = [...this.props.carrierServices];

    // Update row field in state copy
    stateCopy[rowId][rowField] = newCellData;

    // post state row to api
    const shipping_settings = stateCopy[rowId]
    this.props.updateRulesTable(shipping_settings);
  }

  RenderTable = () => {
    const { carriers, carrierServices, editableFields } = this.props;
    const visibleColumns = columns.map(col => col.key);

    if (!carriers) {
      return (
        "No Available Carriers Found."
      )
    } else {
      return (
        <div>
          <Table hover size="sm">
            <thead>
              <tr>
                {columns.map(col => {
                  return (
                    <th key={col.key} scope="col" border="bottom">
                      {col.name}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {carrierServices.map((row, i) => {
                return (
                  <tr key={i}>
                    {Object.entries(row).map(([objKey, cellValue]) => {
                      if (visibleColumns.includes(objKey))  {
                        // Add method field to db to indicate editable fields
                        // Fields are currently protected by strong params
                        console.log('Object Key', carrierServices)
                        if ((editableFields.includes(objKey))) {
                          return (
                            <td size="xsmall" key={row["carrier_id"] + `-` + objKey + `-` + i}>
                              <CellCheckBox OnSave={this.OnSaveTableCell} value={cellValue} dataKey={objKey + `-` + i}/>
                            </td>
                          )
                        } else {
                          return (
                            <td scope="row" size="xsmall" key={row["carrier_code"] + `-` + objKey + `-` + i}>
                              {cellValue.toString()}
                            </td>
                          )}
                      }
                    })}
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      )
      this.setState({
        tableRendered: true
      })
    }
  }


  render() {
    return (
      <div>
        <div>
          <div gap="xsmall" pad="xsmall" margin="small">
            Select Carrier To Set Allowed Shipping Services:
            {this.FilterMenu(this.props)}
            {this.ExcludedCountriesSelect()}
          </div>
        </div>
        {this.RenderTable()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    carriers: state.auth.currentUser.carriers,
    carrierServices: state.user.filteredCarrierServices,
    carrierCountries: state.user.filteredCarrierCountries,
    editableFields: state.user.editableFields
  }
}

const mapDispatch = dispatch => {
  return {
    updateRulesTable: (shipping_settings) => { dispatch(updateRulesTable(shipping_settings)) },
    getShippingRules: (selectedCarrier) => { dispatch(getShippingRules(selectedCarrier)) },
    filterCarrierTableRows: (carrierCode) => { dispatch(filterCarrierTableRows(carrierCode)) },
    setExcludedCountries: (countries) => { dispatch(setExcludedCountries(countries)) }
  }
}

export default connect(mapStateToProps, mapDispatch)(RulesTable);
