import React, { PureComponent } from 'react';
import Pagination from 'react-bootstrap/Pagination'
import PageItem from 'react-bootstrap/PageItem'
import Row from 'react-bootstrap/Row'

export default class Paginator extends PureComponent{
  constructor(props) {
    super(props)
  }

  handleClick = () => {
    let active = event.target.innerText.replace('(current)','')
    this.props.getOrders(active, this.props.orderType)
  }

  render() {
    let active = this.props.active
    let items = [];

    for (let number = 1; number <= this.props.pages; number++) {
      items.push(
        <PageItem key={number} onClick={this.handleClick} active={number === active}>{number}</PageItem>
      );
    }

    if (this.props.pages < 2) {
      return (
        <div></div>
      )
    }
      return (
        <Pagination size="sm">{items}</Pagination>
      );
    }
  }
