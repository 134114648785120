import React, { Component } from 'react';

import AsyncCreatableSelect from 'react-select/async-creatable';

class CustomPackagingsOption extends Component{
  handleChange = (newValue, actionMeta) => {
    if (actionMeta.action == 'select-option'){
      this.selectActionCallback(newValue, actionMeta.action)
    } else if (actionMeta.action == 'create-option'){
      this.createActionCallback(newValue)
    } else {
      // this.clearActionCallback()
      console.log("clear option")

    }
  };

  optionObject = (id, code, options_label) => { return {value: id, code: code, label: options_label} }

  initialOptionValues(){
    let options_array = this.props.defaultOptions.map(record =>  this.optionObject(record.id, record.code, record.options_label))
    return options_array
  }

  selectActionCallback(newValue, action){
    let label = newValue.label
    let id = newValue.value
    let code = newValue.code
    this.props.setCustomUserPackagingAttributes(code, action)
  }

  createActionCallback(newValue){
    let code = newValue.label
    this.props.setCustomUserPackagingAttributes(code)
  }

  clearActionCallback = () => {
    this.props.setUpdateAttributes('', '', {})
    this.props.toggleSkuLimitDelete('clear')
  }

  filterPackagingLimit = (inputValue) => {
    this.props.findCustomPackaging(inputValue)

    return this.props.options.map(record =>
      this.optionObject(record.id, record.code, record.options_label)
    );
  };

  asyncOptions = inputValue =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(this.filterPackagingLimit(inputValue));
      }, 1000);
    });


  render() {
    return(
      <AsyncCreatableSelect
       isClearable
       placeholder="Find or Create..."
       defaultOptions={this.initialOptionValues()}
       onChange={this.handleChange}
       loadOptions={this.asyncOptions}
      >

      </AsyncCreatableSelect>
    );
  }
}

export default CustomPackagingsOption;
