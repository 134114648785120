import React, { PureComponent } from 'react';
import { Button } from 'grommet';
import { Trash } from 'grommet-icons';

class ResetRow extends PureComponent {
  constructor(props) {
    super(props)
  }

  onClick = () => {
    let packageData = this.props.rowDetails
    packageData.allowed = false
    packageData.excludedSkus = [];
    this.props.setPackagings(packageData);
  }

  render () {
    return(
      <Button
        name="Remove Row"
        id={this.props.id}
        onClick={() => this.onClick()}
        key={this.props.id}
        size="small"
        icon={<Trash size="small"/>}
      />
    )
  }
}

export default ResetRow;
