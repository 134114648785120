import React, { Component } from 'react';

import CreatableSelect from 'react-select/creatable';
import { colourOptions } from './Data';

class CreateOption extends Component {
  handleChange = (newValue, actionMeta) => {
    if (actionMeta.action == 'create-option' && newValue[newValue.length - 1].value.includes(",")) {
      let pastedSkus = []
      let updatedValues = []
      pastedSkus = newValue[newValue.length - 1].value.split(", ")
      updatedValues = newValue.slice(0, newValue.length - 1)
      pastedSkus.map(sku => {
        updatedValues.push(
          {
            label: sku,
            value: sku
          }
        )
      })
      newValue = updatedValues
    }

    if (this.props.role == "excludedSkus") {
      this.props.setSkus({ excludedSkus: newValue })
    } else if (this.props.role == "exclusiveSkus") {
      this.props.setSkus({ exclusiveSkus: newValue })
    }
  };

  handleInputChange = (value) => {
    console.log(value)
  }

  render() {
    return (
      <CreatableSelect
        className="basic-single p-1 m-1"
        placeholder="Add Or Remove Skus..."
        isMulti
        // onInputChange={this.handleInputChange}
        onChange={this.handleChange}
        options={this.props.options}
        value={this.props.options}
        isDisabled={this.props.activeInput}
      />
    );
  }
}

export default CreateOption;
