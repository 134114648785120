import React, { Component } from 'react';

// Bootstrap start
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Jumbotron from 'react-bootstrap/Jumbotron'
import Table from 'react-bootstrap/Table'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { filterUserRateMarkups } from '../authActions';

class ClientsForm extends Component{
  constructor(props) {
    super(props)

    this.state = {
      id: "",
      user_id: props.userRateMarkupUsers ?  "" : props.userRateMarkupUsers[0][0],
      carrier_code: "",
      carrier_id: "",
      markup_percent: "",
      userRateMarkupCarriers: props.userRateMarkupCarriers || [],
    }
    this.baseState = this.state
  }

  componentDidMount() {
    if (this.state.user_id) {
      this.props.filterUserRateMarkups(this.state.user_id)
    }
  }

  OnTextChange = e => {
    this.setState({
      [e.target.name]: e.target.value
      })
  }

  ResetForm = () => {
    this.setState(this.baseState)
  }

  OnSubmit = e => {
    e.preventDefault()
    
    const state = this.state

    const markupObject = {
      id: state.id,
      user_id: state.user_id,
      carrier_id: state.carrier_id,
      markup_percent: state.markup_percent
    }
    this.props.updateUserRateMarkups(markupObject);
    this.ResetForm()
  }

  EditRule = e => {
    let id = e.target.id
    let row = this.props.rows.find(r => r.id == parseInt(id))
    this.GetUserCarriers(row.user_id)

    this.setState({
      id: row.id,
      user_id: row.user_id,
      carrier_id: row.carrier_id,
      markup_percent: row.markup_percent
    })
  }

  DestroyRule = e => {
    let id = e.target.id

    this.props.destroyUserRateMarkup(id)
    this.props.filterUserRateMarkups(this.state.user_id)
    this.ResetForm()
  }

  OnSelectUser = e => {
    this.OnTextChange(e)
    let user_id = e.target.value
    this.GetUserCarriers(user_id)
    this.props.filterUserRateMarkups(user_id)
  }

  GetUserCarriers = user_id => {
    let id = user_id;
    const token = localStorage.getItem("token");

    fetch(`/api/users/user_rate_markup_carriers/${id}`, {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      Authorization: `Bearer ${token}`
      }
    })
    .then(resp => resp.json())
    .then(data => this.setState({
      userRateMarkupCarriers: data.user_markup_carriers,
    }))
  }

  render() {
    const { columns, rows, userRateMarkupUsers } = this.props;
    const visibleColumns = columns.map(col => col.key);
    const carriers = this.state.userRateMarkupCarriers || [];

    return(
      <div>
        <Jumbotron>
          <h1>Markup Information</h1>
          <p>

          </p>
          <div>
            <Form>

              <Form.Group>
                <Form.Label>Select User</Form.Label>
                <Form.Control as="select" id="user_id" name="user_id" placeholder="Enter user_id" value={this.state.user_id} onChange={this.OnSelectUser} onClick={this.OnSelectUser}>
                  {userRateMarkupUsers.map(u => {
                    return <option key={u[0]} value={u[0]}>{`${u[1]} - ${u[2]}`}</option>
                  })}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Select Carrier</Form.Label>
                <Form.Control as="select" id="carrier_id" name="carrier_id" placeholder="Select Carrier" value={this.state.carrier_id} onChange={this.OnTextChange}>
                  {carriers.map(c => {
                    return <option key={c[2]} value={c[2]}>{`${c[0]}(${c[1]})`}</option>
                  })}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Markup Percent</Form.Label>
                <Form.Control type="text" id="markup_percent" name="markup_percent" placeholder="Enter markup_percent" value={this.state.markup_percent} onChange={this.OnTextChange}/>
              </Form.Group>

              <Button
                name="Add Packaging"
                onClick={(e) => this.OnSubmit(e)}
                className="m-2 p-2"
              >
                Add Client Markup Rule
              </Button>

            </Form>
          </div>
        </Jumbotron>

        <Table>
          <thead>
            <tr>
              {columns.map(col => {
                return (
                  <th key={col.key} scope="col" border="bottom">
                    {col.name}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {rows.map((rowData, i) => {
              return (
                <tr key={i}>
                  {visibleColumns.map(col => {
                    let row = rowData[col];
                    if (row) {
                      return (
                        <td key={col + `-` + i}>
                          {row}
                        </td>
                      )
                    }
                  })}
                  <td size="xsmall" key={'actions' + '-' + i}>
                    <Button
                      id={rowData.id}
                      name="Edit Markup Rule"
                      onClick={(e) => this.EditRule(e)}
                      className="m-1 p-0"
                      variant="link"
                    >
                      Edit
                    </Button>
                    <Button
                      id={rowData.id}
                      name="Delete Markup Rule"
                      onClick={(e) => this.DestroyRule(e)}
                      className="m-1 p-0"
                      variant="link"
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
      )
  }
}

export default ClientsForm;
