import React, { Component } from 'react';

// Bootstrap start
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Jumbotron from 'react-bootstrap/Jumbotron'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner';
import SkuCreateOption from './SkuCreateOption.js';
import Container from 'react-bootstrap/Container';

// Bootstrap end

import MultiSelect from './MultiSelect.js'
import Paginator from './Paginator.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

class SkuPackagingLimitsTable extends Component{
  constructor(props) {
    super(props)

    this.state = {
      id: "",
      sku: "",
      max_qty: "",
      weight: "",
      height: "",
      width: "",
      length: "",
      exact_match: null,
      packagings: [],
      selectedCSV: null,
      showDeleteButton: 'none',
      recordToDeleteId: null
    }
    this.baseState = this.state
  }

  componentDidMount() {
    this.props.getTableData()
    this.props.getSkuPackagingLimitsInitialOptionsData()
  }

  setUpdateAttributes = (sku, id, attributes) => {
    this.setState({
      sku: sku,
      id: id,
      weight: attributes.weight || '',
      height: attributes.height || '',
      width: attributes.width || '',
      length: attributes.length || '',
      exact_match: attributes.exact_match || null
    })
  }

  toggleSkuLimitDelete = (action) => {
    let value = 'none'
    if (action == 'select-option'){
      value = 'block'
    }
    this.setState({showDeleteButton: value})
  }

  setDeleterecordId = (value) => {
    this.setState({
      recordToDeleteId: value
    })
  }


  OnTextChange = e => {
    this.setState({
      [e.target.name]: e.target.value
      })
  }

  OnCheckChange = () => {
    this.setState({ exact_match: !this.state.exact_match})
  }

  ResetForm = () => {
    this.setState(this.baseState)
  }


  OnSubmit = e => {
    e.preventDefault()

    const state = this.state
    const dataObject = {
      id: state.id,
      sku: state.sku,
      exact_match: state.exact_match,
      max_qty: state.max_qty,
      weight: state.weight,
      height: state.height,
      width: state.width,
      length: state.length,
      packagings: state.packagings
    }
    this.props.updateTableData(dataObject);

    // this.props.createToast("", "User Info", "Tarrif Successfully Updated.")
    this.ResetForm()
  }

  EditRule = e => {
    let id = e.target.id
    let row = this.props.rows.find(r => r.id == parseInt(id))

    this.setState({
      id: row.id,
      sku: row.sku,
      exact_match: row.exact_match,
      max_qty: row.max_qty,
      weight: row.weight,
      height: row.height,
      width: row.width,
      length: row.length,
      packagings: row.packagings
    })
  }

  DestroyRule = e => {
    let id = e.target.id

    this.props.destroyTableData(id)
    this.ResetForm()
  }

  setPackagings = (packagings) => {
    this.setState({
      ...state,
      packagings: packagings
    })
  }

  onFileSelect = (event) => {
    this.setState({ selectedCSV: event.target.files[0] });
  }

  removeAlert = (element_id) => {
    let div = document.getElementById(element_id);
    if (div) div.style.display = 'none';
  }

  exportToCSV(){
    this.showCsvAlert("sku-packaging-csv-alert-row")
    this.props.getCSVData()
  }

  showCsvAlert(alert_id){
    let alert = document.getElementById(alert_id);
    if (alert) alert.style.display = null;
  }

  importFromCSV(){
    let filePicker = document.getElementById("import-csv-picker");
    this.props.importCSVData(this.state.selectedCSV)
    filePicker.value = null
  }

  paginatorElm(type) {
    if (this.props.requesting) {
      return <div></div>
    } else {
      return <Paginator active={this.props.catalog[`${type}`].pageNumber} pages={this.props.catalog[`${type}`].pages} getData={this.props.getTableData} orderType={`${type}`}/>
    }
  }

  skuPackagingLimitsDeleteAlert(message){
    if(message.success){
      return(
        <>
          <div className="row ml-auto" id='sku-packaging-limit-delete-alert-row'>
            <Col md={10} className='text-center'>
              <Alert variant="success" className="mt-2 mb-2" onClose={() => this.removeAlert("sku-packaging-limit-delete-alert-row")} dismissible>
                <strong>{this.props.sku_packaging_limit_delete_messages.success}</strong>
              </Alert>
            </Col>
          </div>
        </>
      )
    } else if (message.failure){
      <>
        <div className="row ml-auto" id='sku-packaging-limit-delete-alert-row'>
            <Col md={10} className='text-center'>
              <Alert variant="danger" className="mt-2 mb-2" onClose={() => this.removeAlert("sku-packaging-limit-delete-alert-row")} dismissible>
                <strong>{this.props.sku_packaging_limit_delete_messages.failure}</strong>
              </Alert>
            </Col>
          </div>
        </>
    }else{
      return ''
    }
  }

  skuPackagingLimitDeleteMessages(message){
    if (message.success){
      return { message: message.success, type: 'success' }
    }else if (message.failure){
      return { message: message.failure, type: 'danger' }
    }else{
      return null
    }
  }

  PackagingsSelect = (values, options) => {
    function camelize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const formattedOptions = options.map(o => (
      {
        value: o.code,
        label: camelize(`${o.carrierCode} - ${o.name}`)
      }
    ));

    return(
      <>
        <Form.Label>Packagings:</Form.Label>
        <MultiSelect
          options={formattedOptions}
          values={values}
          setValues={this.setPackagings}
        />
      </>
    )
  }
  render() {
    const { columns, rows } = this.props;
    const visibleColumns = columns.map(col => col.key);

    return(
      <div>
        <Jumbotron>
          <Container>
            <div id='csv-alerts-div'>
              <div className="row ml-auto" id='sku-packaging-csv-alert-row' style={{display: 'none'}}>
                <Col md={10} className='text-center'>
                  <Alert variant="info" className="mt-2 mb-2" onClose={() => this.removeAlert("sku-packaging-csv-alert-row")} dismissible>
                    "It may take a few seconds to begin downloading"
                  </Alert>
                </Col>
              </div>

              <div className="row ml-auto" id='sku-packaging-csv-import-info-alert-row' style={{display: 'none'}}>
                <Col md={10} className='text-center'>
                  <Alert variant="primary" className="mt-2 mb-2" onClose={() => this.removeAlert("sku-packaging-csv-import-info-alert-row")} dismissible>
                    "It may take a few seconds to complete the import"
                  </Alert>
                </Col>
              </div>

              <div>
                { this.props.successfully_imported_records_count ?
                  <div className="row ml-auto" id='sku-packaging-csv-import-alert-row'>
                    <Col md={10} className='text-center'>
                      <Alert variant="success" className="mt-2 mb-2" onClose={() => this.removeAlert("sku-packaging-csv-import-alert-row")} dismissible>
                        Sku packaging limits import completed<br></br>
                        <strong>Successfully imported records:</strong> {this.props.successfully_imported_records_count}<br></br>
                      </Alert>
                    </Col>
                  </div>
                  :
                  ''
                }
              </div>

              <div>
                { this.props.csv_import_errors ?
                  <div className="row ml-auto" id='sku-packaging-csv-import-error-row'>
                    <Col md={10} className='text-center'>
                      <Alert variant="danger" className="mt-2 mb-2" onClose={() => this.removeAlert("sku-packaging-csv-import-error-row")} dismissible>
                      `<strong>Error Message:</strong> ${this.props.csv_import_errors}`
                      </Alert>
                    </Col>
                  </div>
                  :
                  ''
                }
              </div>
            </div>
          <div>
            { this.skuPackagingLimitDeleteMessages(this.props.sku_packaging_limit_delete_messages) ?
              <div className="row ml-auto" id='sku-packaging-limit-delete-alert-row'>
                <Col md={10} className='text-center'>
                  <Alert variant={this.skuPackagingLimitDeleteMessages(this.props.sku_packaging_limit_delete_messages).type} className="mt-2 mb-2" onClose={() => this.removeAlert("sku-packaging-limit-delete-alert-row")} dismissible>
                    <strong>{this.skuPackagingLimitDeleteMessages(this.props.sku_packaging_limit_delete_messages).message}</strong>
                  </Alert>
                </Col>
              </div>
            :
            ''
            }
          </div>
          <h1>Bulk create or update SKU info</h1>
          <p>
            In order to bulk update your SKU's first download the current data and then add or make changes as needed using the downloaded template
          </p>
          <div>
            <Form>

              <Row>
              <Col sm={8}>
                <Form.Group>
                  <Form.Label>SKU</Form.Label>
                  <SkuCreateOption defaultOptions={this.props.skuPackagingLimitsInitialOptionsData} options={this.props.skuPackagingLimitsOptionsData} setSkuAttribute={this.setSkuAttribute} setUpdateAttributes={this.setUpdateAttributes} toggleSkuLimitDelete={this.toggleSkuLimitDelete} setDeleterecordId={this.setDeleterecordId} findSkuPackagingLimit={this.props.findSkuPackagingLimitsOptionsData} />
                </Form.Group>
                </Col>
              </Row>
              <Form.Group>
                <Form.Label>
                  If you leave this box unchecked Shipmatix will apply
                  this rule to any order sku that contains the sku entered above.
                </Form.Label>
                <Form.Check
                  type={'checkbox'}
                  label={'Exact Match?'}
                  id='exact_match'
                  name="exact_match"
                  checked={this.state.exact_match}
                  value={this.state.exact_match}
                  onChange={this.OnCheckChange}
                />
              </Form.Group>

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Item Weight</Form.Label>
                  <Form.Control type="number" id="weight" name="weight" placeholder="Enter Item Weight" value={this.state.weight} onChange={this.OnTextChange}/>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Item Height</Form.Label>
                  <Form.Control type="number" id="height" name="height" placeholder="Enter Item height" value={this.state.height} onChange={this.OnTextChange}/>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Item Width</Form.Label>
                  <Form.Control type="number" id="width" name="width" placeholder="Enter Item width" value={this.state.width} onChange={this.OnTextChange}/>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Item Length</Form.Label>
                  <Form.Control type="number" id="length" name="length" placeholder="Enter Item length" value={this.state.length} onChange={this.OnTextChange}/>
                </Form.Group>
              </Form.Row>
              {/* <Form.Group>
                <Form.Label>Max(Including max) Quantity</Form.Label>
                <Form.Control type="number" id="max_qty" name="max_qty" placeholder="Enter Max(to) QTY" value={this.state.max_qty} onChange={this.OnTextChange}/>
              </Form.Group>
              <Form.Group>
                <Form.Label>Packagings</Form.Label>
                {this.PackagingsSelect(this.state.packagings, this.props.options)}
              </Form.Group> */}

              <Row>
                <Col sm={1}>
                  <Button
                    name="Add/Update Sku Packaging Qty Limit"
                    onClick={(e) => this.OnSubmit(e)}
                    className="m-2 p-2"
                  >
                    Save
                  </Button>
                </Col>
                <Col sm={2}>
                  <Button variant="danger" onClick={(e) => this.DestroyRule(e)} className="m-2 p-2" style={{display: this.state.showDeleteButton}} id={this.state.recordToDeleteId}>
                    Delete
                  </Button>
                </Col>

                <Col sm={2}>
                  <Button
                  onClick={() => this.exportToCSV()}
                  style={{'width': '122px'}}
                  className="m-2 p-2">
                    Export to CSV
                  </Button>
                </Col>

                <Col sm={2}>
                  <Button
                    onClick={() => this.importFromCSV()}
                    disabled={this.props.inProgress}
                    style={{'width': '122px'}}
                    className="m-2 p-2">
                    {this.props.inProgress ? 'Importing...' : 'Import CSV'}
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{display: this.props.inProgress ? 'block' : 'none'}}
                    />
                  </Button>
                </Col>

                <Col md={3}>
                  <Form.Control type="file" name="import-csv" id='import-csv-picker' onChange={(e) => this.onFileSelect(e)} style={{'margin-top': '14px'}} />
                </Col>
              </Row>
            </Form>
          </div>
          </Container>
        </Jumbotron>

      { this.props.inProgress ?  this.showCsvAlert('sku-packaging-csv-import-info-alert-row') : this.removeAlert('sku-packaging-csv-import-info-alert-row')}
      </div>
      )
  }
}

export default SkuPackagingLimitsTable;
