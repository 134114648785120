import React, { PureComponent, useState } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';

// Bootstrap start
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Jumbotron from 'react-bootstrap/Jumbotron'
import CustomPackagingsOption from './CustomPackagingsOption.js';

// Bootstrap end

import CreateOption from './CreateOption.js';

class AddPackagingSelect extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      package_type: null,
      shipmatix_package_type_name: null,
      packaging_code: null,
      custom_packaging_action: null
    }
  }

  PackagingSelector = (props) => {
    function camelize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const options = props.selectOptions.map(o => (
      {
        value: o.id,
        label: camelize(`${o.carrierCode} - ${o.name}`)
      }
    ));

    const handleChange = (newValue, actionMeta) => {
      props.setValue(newValue)
    };

    return (
      <Select
        className="p-1 m-1"
        classNamePrefix="select"
        defaultValue={props.value}
        isClearable={true}
        isSearchable={true}
        name="packagings"
        options={options}
        value={props.value}
        onChange={handleChange}
        placeholder="Select Packaging..."
      />
    )
  }


  AddButton = (props) => {
    const onClick = () => {
      let packageData = props.allPackagings.find(o => o.id === props.value.value)
      if (packageData === 'undefined' || packageData == null) {
        packageData = {}
        packageData.carrierCode = 'all'
        packageData.code = this.state.shipmatix_package_type_name
        packageData.name = this.state.shipmatix_package_type_name
        packageData.user_id = props.user.id
      }
      packageData.allowed = true
      packageData.excludedSkus = props.skus?.map(sku => sku.value) || [];
      packageData.exclusive_skus = props.exclusiveSkus?.map(sku => sku.value) || [];
      packageData.max_qty = props.max_qty;
      packageData.max_weight = props.max_weight;
      packageData.height = props.height;
      packageData.width = props.width;
      packageData.length = props.length;
      packageData.length = props.length;
      packageData.package_type = parseInt(this.state.package_type)

      props.setPackagings(packageData);
      props.setSkus({ excludedSkus: [], exclusiveSkus: [] })
      props.setValue('')
    }

    return (
      <Button
        name="Add Packaging"
        onClick={() => onClick()}
        className="m-2 p-2"
      >
        Add Packaging
      </Button>
    )
  }

  AddCustomPackagingButton = (props) => {
    const onClick = () => {
      if (this.state.custom_packaging_action == 'select-option') {
        return
      }
      const packageData = {
        code: this.state.packaging_code,
        max_qty: props.max_qty,
        max_weight: props.max_weight,
        height: props.height,
        length: props.length,
        width: props.width,
        package_type: parseInt(this.state.package_type)
      }
      props.createCustomPackaging(packageData)
    }

    return (
      <Button
        name="Add Custom Packaging"
        onClick={() => onClick()}
        className="m-2 p-2"
      >
        Add Custom Packaging
      </Button>
    )
  }

  RemoveButton = () => {
    const onClick = () => {
      let packageData = props.selectOptions.find(o => o.id === value)
      packageData.allowed = false
      props.setPackagings(packageData)
    }

    return (
      <Button
        name="Remove Packaging"
        onClick={() => onClick()}
      >Remove Packaging</Button>
    )
  }

  setPackagingTypevalue(value) {
    this.setState({
      package_type: value
    })
    this.handlePackagingSelectiorChange(value)
    this.handleAddPackagingButtonChange(value)
  }

  setCustomUserPackagingAttributes = (code, action) => {
    this.setState({
      packaging_code: code,
      custom_packaging_action: action
    })
  }

  setShipmatixPackagingName = (value) => {
    this.setState({
      shipmatix_package_type_name: value
    })
  };

  handlePackagingSelectiorChange(value) {
    if (value === '0') {
      document.getElementById('shipstation-packaging-selector').style.display = null
      document.getElementById('shipmatix-packaging-selector').style.display = 'none'

    } else {
      document.getElementById('shipstation-packaging-selector').style.display = 'none'
      document.getElementById('shipmatix-packaging-selector').style.display = null
    }
  }

  handleAddPackagingButtonChange(value) {
    if (value === '0') {
      document.getElementById('shipstation-packaging-add-button').style.display = null
      document.getElementById('custom-packaging-add-button').style.display = 'none'

    } else {
      document.getElementById('shipstation-packaging-add-button').style.display = 'none'
      document.getElementById('custom-packaging-add-button').style.display = null
    }
  }


  // EditButton = () => {
  //   const onClick = () => {
  //     let packageData = props.selectOptions.find(o => o.id === value)
  //     packageData.allowed = false
  //     // props.setPackagings(packageData)
  //     this.setState({
  //       skus: packageData.skus.map(sku => ({ label: sku, value: sku }) ),
  //       value: { value: packageData.id, label: camelize(`${packageData.carrierCode} - ${packageData.name}`) }
  //     })
  //   }
  //
  //   return (
  //     <Button
  //       name="Edit Packaging"
  //       onClick={() => onClick()}
  //     >Edit Packaging</Button>
  //   )
  // }

  render() {
    return (
      <Jumbotron className="p-3">
        <Container>
          <h1>Sku And Packaging Rules</h1>

          <Button variant="primary" onClick={this.props.refreshPackagings} disabled={this.props.requesting} className="mb-2">
            {this.props.requesting ? 'Loading…' : 'Refresh Packagings From Shipstation/Shipengine'}
          </Button>

          <p>
            View And Manage Your Packagings.
          </p>
          <ul>
            <li>
              Click the exclusion list to add skus you would like to exclude for this packaging.
            </li>
            <li>
              Click the exclusive list to add skus you would like to make exclusive for this packaging only.
            </li>
          </ul>
          <div>
            <Form >
              <Form.Group >
                <div className='p-1 m-1'>
                  <Form.Label>Select packaging type</Form.Label>
                  <Form.Control as="select" defaultValue={0} name={"packaging_type"} id="packaging-type-field" value={this.state.package_type ? this.state.package_type : ""} onChange={e => { this.setPackagingTypevalue(e.target.value) }} required >
                    {<option disabled selected value> -- select a package type -- </option>}
                    {<option value="0">Shipstation</option>}
                    {<option value="1">Shipmatix</option>}
                  </Form.Control>
                </div>

                <div id='shipstation-packaging-selector' style={{ 'display': 'none' }}>
                  <Form.Label>Select packaging:</Form.Label>
                  {this.PackagingSelector(this.props)}
                </div>

                <div id='shipmatix-packaging-selector' style={{ 'display': 'none' }} className='p-1 m-1'>
                  <Form.Label>Select packaging:</Form.Label>
                  <Form.Group>
                    <CustomPackagingsOption defaultOptions={this.props.initialCustomPackagingsList} options={this.props.CustomPackagingsData} findCustomPackaging={this.props.findCustomUserPackaging} setCustomUserPackagingAttributes={this.setCustomUserPackagingAttributes} />

                    {/* <Form.Control type="text" id="shipmatix-packaging-field" name="packagings" placeholder="Enter packaging name" value={this.state.shipmatix_package_type_name} onChange={ e => {this.setShipmatixPackagingName(e.target.value)}}/>
                  <Form.Text className="text-muted">
                  </Form.Text> */}
                  </Form.Group>
                </div>


                <div onClick={() => this.props.setActiveInput(0)}>
                  <Form.Label>Exclusion list. List of SKU's that will NOT use the above selected packaging:</Form.Label>
                  <CreateOption setSkus={this.props.setSkus} role={"excludedSkus"} options={this.props.skus} activeInput={this.props.activeInput == 1 ? true : false} />
                </div>
                <div onClick={() => this.props.setActiveInput(1)}>
                  <Form.Label>Exclusive list. List of SKU's that only these SKU's will have this packaging available for them:</Form.Label>
                  <CreateOption setSkus={this.props.setSkus} role={"exclusiveSkus"} options={this.props.exclusiveSkus} activeInput={this.props.activeInput == 0 ? true : false} />
                </div>
                <Form.Group>
                  <Form.Label>Max Allowed Qty For This Packaging</Form.Label>
                  <Form.Control type="number" id="max_qty" name="max_qty" placeholder="Enter Max Qty" value={this.props.max_qty} onChange={this.props.OnTextChange} />
                </Form.Group>

                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Max Weight</Form.Label>
                    <Form.Control type="number" id="max_weight" name="max_weight" placeholder="Enter Item Weight" value={this.props.max_weight} onChange={this.props.OnTextChange} />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Item Height</Form.Label>
                    <Form.Control type="number" id="height" name="height" placeholder="Enter Item height" value={this.props.height} onChange={this.props.OnTextChange} />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Item Width</Form.Label>
                    <Form.Control type="number" id="width" name="width" placeholder="Enter Item width" value={this.props.width} onChange={this.props.OnTextChange} />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Item Length</Form.Label>
                    <Form.Control type="number" id="length" name="length" placeholder="Enter Item length" value={this.props.length} onChange={this.props.OnTextChange} />
                  </Form.Group>
                </Form.Row>

                <div id='shipstation-packaging-add-button' className='p-1 m-1'>
                  {this.AddButton(this.props)}
                </div>
                <div id='custom-packaging-add-button' style={{ 'display': 'none' }} className='p-1 m-1'>
                  {this.AddCustomPackagingButton(this.props)}
                </div>

              </Form.Group>
            </Form>
          </div>
        </Container>
      </Jumbotron>
    )
  }
}

export default AddPackagingSelect;
