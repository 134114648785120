import React, { Component } from 'react';

// Bootstrap start
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container';

// Bootstrap end

class UserInfoForm extends Component{
  constructor(props) {
    super(props)

    this.state = {
      name: props.user.name,
      email: props.user.email,
      password: "",
      timeZones: props.timeZones,
      timeZoneSelectedValue: props.currentTimeZone
    }
  }

  OnTextChange = e => {
    this.setState({
      [e.target.name]: e.target.value
      })
  }

  OnSubmit = e => {
    e.preventDefault()

    const state = this.state
    this.props.updateUser(state.name, state.email, state.password, state.timeZoneSelectedValue);
  }

  setTimeZonevalue (value){
    this.setState({
      timeZoneSelectedValue: value
    })
  }

  render() {
    let TimeZones = this.state.timeZones
    return(
      <Jumbotron>
        <Container>
        <h1>User Information</h1>
        <p>
          View And Manage Your Email And Password.
        </p>
        <div>
          <Form onSubmit={this.OnSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" id="name" name="name" placeholder="Enter name" value={this.state.name} onChange={this.OnTextChange}/>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" id="email" name="email" placeholder="Enter email" value={this.state.email} onChange={this.OnTextChange}/>
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" id="password" name="password" placeholder="Password" onChange={this.OnTextChange}/>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Time Zone</Form.Label>
                <Form.Control
                  as="select"
                  name={"time_zone"}
                  value={this.state.timeZoneSelectedValue}
                  onChange={e => {
                  this.setTimeZonevalue(e.target.value);
                  }}
                >
                  { TimeZones.map((zone) => {
                    return <option value={zone}>{zone}</option>;
                  })}
                </Form.Control>
              </Form.Group>
            </Row>
            <Form.Group>
              <Form.Check type="checkbox" label="Get Our Bi-Weekly Newsletter" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Form>
        </div>
        </Container>
      </Jumbotron>

    )
  }
}

export default UserInfoForm;
