import React, { Component } from 'react';

// Bootstrap start
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Jumbotron from 'react-bootstrap/Jumbotron'
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container';

// Bootstrap end

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

class TarrifForm extends Component{
  constructor(props) {
    super(props)

    this.state = {
      id: props.tarrif?.id || "",
      name: props.tarrif?.name || "",
      harmonization_code: props.tarrif?.harmonization_code || "",
      origin_country_code: props.tarrif?.origin_country_code || "",
      match_with_name: props.tarrif?.match_with_name || "",
      value: props.tarrif?.value || 0
    }
    this.baseState = this.state
  }

  componentDidMount() {
      this.props.getTableData();
  }

  OnTextChange = e => {
    this.setState({
      [e.target.name]: e.target.value
      })
  }

  ResetForm = () => {
    this.setState(this.baseState)
  }

  OnSubmit = e => {
    e.preventDefault()

    const state = this.state
    const tarrifObject = {
      id: state.id,
      name: state.name,
      harmonization_code: state.harmonization_code,
      origin_country_code: state.origin_country_code,
      match_with_name: state.match_with_name,
      value: state.value
    }
    this.props.updateUserTarrifs(tarrifObject);
    // this.props.createToast("", "User Info", "Tarrif Successfully Updated.")
    this.ResetForm()
  }

  EditRule = e => {
    let id = e.target.id
    let row = this.props.rows.find(r => r.id == parseInt(id))

    this.setState({
      id: row.id,
      name: row.name,
      harmonization_code: row.harmonization_code,
      origin_country_code: row.origin_country_code,
      match_with_name: row.match_with_name,
      value: row.value
    })
  }

  DestroyRule = e => {
    let id = e.target.id

    this.props.destroyTarrifs(id)
    this.ResetForm()
  }

  render() {
    const { columns, rows } = this.props;
    const visibleColumns = columns.map(col => col.key);

    return(
      <div>
        <Jumbotron>
          <Container>
          <h1>Tarrif Information</h1>
          <p>

          </p>
          <div>
            <Form>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" id="name" name="name" placeholder="Enter name" value={this.state.name} onChange={this.OnTextChange}/>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Harmonization Code</Form.Label>
                  <Form.Control type="text" id="harmonization_code" name="harmonization_code" placeholder="Enter harmonization_code" value={this.state.harmonization_code} onChange={this.OnTextChange}/>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>Origin Country Code</Form.Label>
                  <Form.Control type="text" id="origin_country_code" name="origin_country_code" placeholder="Enter origin_country_code" value={this.state.origin_country_code} onChange={this.OnTextChange}/>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Title Match</Form.Label>
                  <Form.Control type="text" id="match_with_name" name="match_with_name" placeholder="Enter match_with_name" value={this.state.match_with_name} onChange={this.OnTextChange}/>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Declared Value</Form.Label>
                  <Form.Control type="number" id="value" name="value" placeholder="Enter Declared Value" value={this.state.value} onChange={this.OnTextChange}/>
                </Form.Group>
              </Row>
              <Button
                name="Add Packaging"
                onClick={(e) => this.OnSubmit(e)}
                className="m-2 p-2"
              >
                Add Tarrif Rule
              </Button>

            </Form>
          </div>
          </Container>
        </Jumbotron>

        <Container>
        <Table>
          <thead>
            <tr>
              {columns.map(col => {
                return (
                  <th key={col.key} scope="col" border="bottom">
                    {col.name}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {rows.map((rowData, i) => {
              return (
                <tr key={i}>
                  {Object.entries(rowData).map(([objKey, cellValue]) => {
                    if (visibleColumns.includes(objKey)) {
                      return (
                        <td key={objKey + `-` + i}>
                          {objKey == 'value' ? `$ ${parseFloat(cellValue).toFixed(2)}` : cellValue}
                        </td>
                      )
                    }
                  })}
                  <td size="xsmall" key={'actions' + '-' + i}>
                    <Button
                      id={rowData.id}
                      name="Edit Tarrif"
                      onClick={(e) => this.EditRule(e)}
                      className="m-1 p-0"
                      variant="link"
                    >
                      Edit
                    </Button>
                    <Button
                      id={rowData.id}
                      name="Delete Tarrif"
                      onClick={(e) => this.DestroyRule(e)}
                      className="m-1 p-0"
                      variant="link"
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        </Container>
      </div>
      )
  }
}

export default TarrifForm;
