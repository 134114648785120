import React, { PureComponent } from 'react';

import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import CardGroup from 'react-bootstrap/CardGroup';
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Modal from 'react-bootstrap/Modal'
import Overlay from 'react-bootstrap/Overlay'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import Table from 'react-bootstrap/Table'
import Tooltip from 'react-bootstrap/Tooltip'
import Row from 'react-bootstrap/Row'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faHome, faSync, faChevronLeft, faShippingFast, faBoxOpen, faTruck } from '@fortawesome/free-solid-svg-icons';

import { usStates } from '../user/Data.js';

export default class OrderInfoModal extends PureComponent {
  constructor(props) {
    super(props)
    this.orderRef = React.createRef();
    this.state = {
      orderInfo: this.props.orderInfo,
      name: (!props.orderInfo.shipping_address) ? "" : props.orderInfo.shipping_address.name,
      company: (!props.orderInfo.shipping_address) ? "" : props.orderInfo.shipping_address.company,
      street1: (!props.orderInfo.shipping_address) ? "" : props.orderInfo.shipping_address.street1,
      street2: (!props.orderInfo.shipping_address) ? "" : props.orderInfo.shipping_address.street2,
      city: (!props.orderInfo.shipping_address) ? "" : props.orderInfo.shipping_address.city,
      state: (!props.orderInfo.shipping_address) ? "" : props.orderInfo.shipping_address.state,
      postalCode: (!props.orderInfo.shipping_address) ? "" : props.orderInfo.shipping_address.postalCode,
      country: (!props.orderInfo.shipping_address) ? "" : props.orderInfo.shipping_address.country,
      phone: (!props.orderInfo.shipping_address) ? "" : props.orderInfo.shipping_address.phone,
      residential: (!props.orderInfo.shipping_address) ? "" : props.orderInfo.shipping_address.residential
    }
  }

  OnTextChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  OnSubmit = e => {
    e.preventDefault()

    const state = this.state
    const updateShipstationOrder = this.props.updateShipstationOrder

    const ship_address = {
      order_id: state.orderInfo.order_id,
      name: state.name,
      company: state.company,
      street1: state.street1,
      street2: state.street2,
      // street3: state.street3,
      city: state.city,
      state: state.state,
      postalCode: state.postalCode,
      country: state.country,
      residential: state.residential
    }
    // this.props.createToast("", "User Info", "Address Successfully Updated.")
    updateShipstationOrder(ship_address);
  }

  stateField = (state) => {
    if (Object.keys(usStates).includes(state)) {
      return (
        <Form.Control as="select" id="state" name="state" onChange={this.OnTextChange} value={this.state.state}>
          {Object.keys(usStates).map(s => {
            return <option key={s} value={s}>{usStates[s]}</option>
          })}
        </Form.Control>
      )
    } else {
      return <Form.Control id="state" name="state" onChange={this.OnTextChange} value={this.state.state} />
    }
  }

  getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  }

  render() {
    const order = this.props.orderInfo;
    const status = { 0: "Unprocessed", 1: "Processed Rate", 2: "Generated Label", 3: "Printed", 4: "Rate Error", 5: "Label Error", 6: "Print Error", 7: "Other", 8: "Unknown" }
    const varientClasses = { 0: "warning", 1: "primary", 2: "success", 3: "info", 4: "danger", 5: "danger", 6: "danger", 7: "light", 8: "light" }
    const orderAddressStatus = { "Address validated successfully": "🟩", "Address validation warning": "🟥", "Address not yet validated": "🕑" }
    const setChecked = (state, event) => {
      this.setState({
        ...state,
        residential: state
      })
    }

    return (
      <Modal
        style={{ 'fontSize': '14px' }}
        key={'infoModal-' + order.order_number}
        onHide={this.props.hideOrderInfoModal}
        show={this.props.visible}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Order <strong>#{order.order_number}</strong> From <strong>{order.source}</strong>
            <Button
              variant={"link"}
              onClick={() => this.props.getSingleOrder(order.order_id)}
              disabled={(this.props.processing && this.props.processingType == 10) || ["2", "3", "6"].includes(order.order_status)}
            >
              {(this.props.processing && this.props.processingType == 10) ? 'Loading…' : <FontAwesomeIcon icon={faSync} />}
            </Button>
          </Modal.Title>

        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>{this.props.messages()}</Row>
            <Row>
              <Col md={5}>
                <strong>Shipping Address: </strong><br></br>
                <div className="ml-2">
                  <Form onSubmit={this.OnSubmit}>
                    <Form.Group>
                      <Form.Label>Name</Form.Label>
                      <Form.Control placeholder="Enter name" id="name" name="name" onChange={this.OnTextChange} value={this.state.name || this.props.orderInfo.shipping_address.name} />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Company</Form.Label>
                      <Form.Control placeholder="Main St Inc." id="company" name="company" onChange={this.OnTextChange} value={this.state.company || this.props.orderInfo.shipping_address.company} />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Address</Form.Label>
                      <Form.Control placeholder="1234 Main St" id="street1" name="street1" onChange={this.OnTextChange} value={this.state.street1 || this.props.orderInfo.shipping_address.street1} />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Address 2</Form.Label>
                      <Form.Control placeholder="Apartment, studio, or floor" id="street2" name="street2" onChange={this.OnTextChange} value={this.state.street2 || this.props.orderInfo.shipping_address.street2} />
                    </Form.Group>

                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>City</Form.Label>
                        <Form.Control id="city" name="city" onChange={this.OnTextChange} value={this.state.city || this.props.orderInfo.shipping_address.city} />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>State</Form.Label>
                        {this.stateField(this.state.state || this.props.orderInfo.shipping_address.state)}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>Zip</Form.Label>
                        <Form.Control id="postalCode" name="postalCode" onChange={this.OnTextChange} value={this.state.postalCode || this.props.orderInfo.shipping_address.postalCode} />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Country</Form.Label>
                        <Form.Control id="country" name="country" onChange={this.OnTextChange} value={this.state.country || this.props.orderInfo.shipping_address.country} />
                      </Form.Group>
                    </Form.Row>
                    {orderAddressStatus[order.shipping_address.addressVerified]} <strong>Address Validation: </strong>{order.shipping_address.addressVerified}
                    <Form.Group id="formGridCheckResidential">
                      <Form.Check type="checkbox" label="Residential Address" id="residential" name="residential" checked={this.state.residential || this.props.orderInfo.shipping_address.residential} onChange={(event) => this.setChecked(event.target.checked, event)} />
                    </Form.Group>

                    <Button variant="primary" type="submit" disabled={(this.props.processing && this.props.processingType == 10) || ["2", "3", "6"].includes(order.order_status)}>
                      {this.props.processing && this.props.processingType == 10 ? 'Updating Address...' : 'Update Address'}
                    </Button>
                  </Form>

                  {/* <strong>{ order.shipping_address.name }</strong><br></br>
                      { order.shipping_address.street1 }<br></br>
                      { order.shipping_address.street2 || "" }
                      { order.shipping_address.city },<span> </span>
                      { order.shipping_address.state }<span> </span>
                      { order.shipping_address.postalCode}<span> </span>
                      { order.shipping_address.country}<br></br>
                  { order.shipping_address.residential ? "Residential Address" : "Commercial Address" }<br></br> */}
                  {/* <i>{ order.shipping_address.addressVerified }</i><br></br> */}
                </div>
              </Col>
              <Col md={7}>
                {this.props.carrierIcon(order.ship_with_carrier)}<br></br>
                <Badge variant={varientClasses[order.order_status]}>{status[order.order_status]}</Badge>
                <span> </span>
                <Badge variant={"info"}>{order.country == "US" ? "Domestic" : "International"}</Badge>
                <br></br>
                <strong>Order Date: </strong>{order.order_date}<br></br>
                <strong>Carrier: </strong>{order.ship_with_carrier}<br></br>
                <strong>Carrier Service: </strong>{order.ship_with_carrier_code}<br></br>
                <strong>Requested Shipping: </strong>{order.requestedShippingService}<br></br>
                <strong>Weight: </strong>{order.weight} {order.weight_type}<br></br>
                <strong>Dimensions: </strong> Length: {order.dimensions?.length} Width: {order.dimensions?.width} Height: {order.dimensions?.height} Units: {order.dimensions?.units}
                <br></br>
                <strong>Cheapest Rate: </strong><Button className="m-0 p-0" variant="link" ref={this.orderRef} onMouseOver={() => this.setState({ showOrderInfoTooltip: !this.state.showOrderInfoTooltip })} onMouseOut={() => this.setState({ showOrderInfoTooltip: !this.state.showOrderInfoTooltip })}>${parseFloat(order.cheapest_rate).toFixed(2)}</Button>
                {/* <Overlay key={"overlay-" + order.id} target={this.orderRef.current} show={this.state.showOrderInfoTooltip} placement="bottom">
                    {(props) => (
                      this.props.popover(props)
                    )}
                  </Overlay> */}
                <hr></hr>
                <strong>Store ID: </strong> {order.store_id}<br></br>
                <strong>Internal Notes: </strong> {order.internal_notes}<br></br>
                <strong>Custom Field 1: </strong> {order.custom_one}<br></br>
                <strong>Custom Field 2: </strong> {order.custom_two}<br></br>
                <strong>Custom Field 3: </strong> {order.custom_three}<br></br>
                <br></br>
                <strong>Customs Items: </strong>
                <table>
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>QTY</th>
                      <th>Value</th>
                      <th>Total</th>
                      <th>Origin Country</th>
                      <th>Tarrif Code</th>
                    </tr>
                  </thead>
                  <tbody>

                    {order.customs_items?.length > 0 ? order.customs_items.map((item, i) => {
                      return (
                        <tr>
                          <td>{item.description}</td>
                          <td>{item.quantity}</td>
                          <td>${parseFloat(item.value).toFixed(2)}</td>
                          <td>${parseFloat(item.value * item.quantity).toFixed(2)}</td>
                          <td>{item.countryOfOrigin}</td>
                          <td>{item.harmonizedTariffCode}</td>
                        </tr>
                      )
                    })
                      :
                      <tr>
                        No customs items found.
                      </tr>
                    }
                  </tbody>
                </table>
                <hr></hr>
                <strong>Skus In Order:</strong>
                <br></br>
                {order.order_skus.map((sku, i) => {
                  return (
                    <>
                      <Badge pill variant={varientClasses[this.getRandomInt(8)]}>{sku.item} - {sku.qty}</Badge>
                      <span> </span>
                    </>
                  )
                }
                )}

              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.hideOrderInfoModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
