import React, { PureComponent } from 'react';
import NotificationAlert from './NotificationAlert.js';

export default class NotificationContainer extends PureComponent{
  constructor(props) {
    super(props)

    this.state = {
      notification_alerts: props.notification_alerts
    }
  }

  render() {
    let toasts = []

    this.props.notification_alerts.map(alert => {
      toasts.push(<NotificationAlert key={alert.key} title={alert.title} body={alert.body} destroyToast={this.props.destroyToast}/>)
    })

    return(
      <div
        style={{
            position: 'absolute',
            top: '50px',
            right: '20px',
            zIndex: 5000000,
        }}
      >
        {toasts}
      </div>
    )
  }
}
